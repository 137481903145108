export default {
	getSettings ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/admin/app-settings/',
			then   : response => {
				commit('setSettings', response.body)
				commit('settingsLoaded', true)
			}
		})
	},
	saveSettings ({ state }) {
		// First lets make a copy of the state.
		const payload = this._vm.$lo.cloneDeep(state)

		// We don't need the settingsLoaded attribute.
		delete payload.settingsLoaded

		// Now let's reformat the settings.
		Object.keys(payload).forEach(key => {
			const settings = payload[key]
			Object.keys(settings).forEach(k => {
				const _k = this._vm.$lo.snakeCase(k)
				const setting = payload[key][k]

				delete payload[key][k]
				payload[key][_k] = setting
			})
		})

		return this._vm.$api({
			method       : 'post',
			send         : payload,
			url          : '/admin/app-settings/',
			notification : {
				type    : 'success',
				message : 'Settings successfully saved!',
				icon    : 'fas fa-bell'
			}
		})
	}
}