export const CREATE = {
	name        : null,
	description : '',
	required    : 0,
	archived    : 0,
	type        : 'none'
}

export const CURRENT = {
	id : null,
	...CREATE
}