const arraySum = array => array.reduce((a, b) => a + b, 0)

const total = registrations => {
	let total = 0
	registrations.forEach(registration => {
		registration.financial_options.forEach(option => {
			total += option.quantity * option.amount
		})

		registration.discounts.forEach(discount => {
			total += discount.quantity * discount.amount
		})
	})

	return total
}

const paid = registrations => {
	let paid = 0
	registrations.forEach(registration => {
		registration.transactions.forEach(transaction => {
			paid += parseFloat(transaction.registration_amount)

			if (transaction.refunds.length) {
				transaction.refunds.forEach(refund => {
					paid -= refund.amount
				})
			}
		})
	})
	return paid
}

const due = registrations => {
	return total(registrations) - paid(registrations)
}

const overpaid = registrations => {
	return 0 > due(registrations)
}

const discount = (d, amount = 0) => {
	let discountAmount = 0

	amount = parseFloat(amount)

	if (100 < d.percent) {
		d.percent = 100
	}

	if ('subtotal' === d.apply_to) {
		if ('amount' === d.method) {
			discountAmount = -parseFloat(d.amount).toFixed(2)
		}

		if ('percent' === d.method && 0 !== d.percent) {
			discountAmount = -(amount * (parseFloat(d.percent) / 100)).toFixed(2)
		}
	} else if (d.financial_options && d.financial_options.length) {
		d.financial_options.forEach(() => {
			let actualAmount = 0
			if ('amount' === d.method) {
				actualAmount   = amount < parseFloat(d.amount) ? amount : parseFloat(d.amount)
				discountAmount = -parseFloat(actualAmount).toFixed(2)
			}

			if ('percent' === d.method && 0 !== d.percent) {
				discountAmount = -(amount * (parseFloat(d.percent) / 100)).toFixed(2)
			}
		})
	}

	if (discountAmount > amount) {
		discountAmount = amount
	}

	amount += discountAmount

	return {
		discountAmount,
		amount
	}
}

export {
	arraySum,
	discount,
	due,
	overpaid,
	paid,
	total
}