export default {
	getStats ({ commit, rootState }, index) {
		const { stats } = rootState.adminSettings

		return this._vm.$api({
			method : 'get',
			url    : `/admin/dashboard/stats/${stats[index].report}/`,
			then   : response => {
				commit('setStatResults', {
					index,
					count : response.body
				}, { root: true })
			}
		})
	},
	getLargeStats ({ commit, rootState }, index) {
		const largeStats = rootState.adminSettings.large_stats

		return this._vm.$api({
			method : 'get',
			url    : `/admin/dashboard/large-stats/${largeStats[index].report}/`,
			then   : response => {
				commit('setStatResults', {
					index,
					count : response.body,
					large : true
				}, { root: true })
			}
		})
	}
}