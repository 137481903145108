export default {
	hasAttendees : state => state.attendees && state.attendees.length,
	name         : state => id => {
		if (state.attendees[id]) {
			const firstName = state.attendees[id].first_name || ''
			const lastName  = state.attendees[id].last_name || ''
			const name      = firstName + ' ' + lastName
			return name.trim()
		}

		return ''
	},
	getIndex            : state => id => state.attendees.findIndex(attendee => attendee.id === id),
	get                 : state => id => state.attendees.filter(attendee => attendee.id === id)[0],
	getAttendeeType     : state => id => state.types.filter(type => type.id === id)[0],
	attendeeTypes       : state => state.types,
	mappedAttendeeTypes : state => {
		return state.types ? state.types.map(type => ({
			value : type.id,
			label : type.name
		})) : []
	},
	registrations : state => {
		const registrations = []
		state.attendees.forEach(attendee => attendee.registrations.forEach(registration => registrations.push(registration)))
		return registrations.sort((a, b) => {
			if (a.created < b.created) {
				return 1
			} else if (a.created > b.created) {
				return -1
			}
			return 0
		})
	}
}