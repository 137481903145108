import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import paths from './paths'
import store from '@/registration/store'

Vue.use(Router)

const router = new Router({
	mode   : 'history',
	base   : '/', // In case we change sub-directories -> 'dev' === process.env.VUE_APP_ENV ? '/' : '/events/',
	routes : paths,
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		if (to.hash) {
			return { selector: to.hash }
		}
		return { x: 0, y: 0 }
	}
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
const nextFactory = (context, middleware, index) => {
	const subsequentMiddleware = middleware[index]
	// If no subsequent Middleware exists,
	// the default `next()` callback is returned.
	if (!subsequentMiddleware) {
		return context.next
	}

	return (...parameters) => {
		// Run the default Vue Router `next()` callback first.
		context.next(...parameters)
		// Then run the subsequent Middleware with a new
		// `nextMiddleware()` callback.
		const nextMiddleware = nextFactory(context, middleware, index + 1)
		subsequentMiddleware({ ...context, next: nextMiddleware })
	}
}

router.beforeEach((to, from, next) => {
	if (-1 !== window.location.href.indexOf('/admin/')) {
		console.warn('Wrong Router...NEED TO FIX THIS IN THE FUTURE.')
		return router.go(window.location.href)
	}

	// Make sure we load our auth token from the registration side.
	if (router.app && router.app.$auth.get()) {
		router.app.$http.set('Authorization', `Bearer ${router.app.$auth.get()}`)
	}

	// Set loading to true on each route change.
	store.commit('loading', true)

	// If a route with a title was found, set the document (page) title to that value.
	document.title = to.meta.label ? to.meta.label + ' | ' + process.env.VUE_APP_PLATFORM_NAME : process.env.VUE_APP_PLATFORM_NAME

	// Set the background color based on meta.
	const backgroundColor = to.meta.background ? to.meta.background : 'blue'
	document.body.classList.remove('gray', 'red', 'white', 'blue', 'dark-red')
	document.body.classList.add(backgroundColor)

	if (to.meta.middleware) {
		const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [ to.meta.middleware ]

		const context = {
			from,
			next,
			router,
			to
		}
		const nextMiddleware = nextFactory(context, middleware, 1)

		return middleware[0]({ ...context, next: nextMiddleware })
	}

	return next()
})

Vue.use(Meta)

export default router