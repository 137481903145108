import { storage } from './'
export default {
	set : (token, remember = true, admin = false) => {
		const key = admin ? 'ajwt' : 'jwt'
		if (remember) {
			storage.local.set(key, token)
		} else {
			storage.session.set(key, token)
		}
	},
	get : (admin = false) => {
		const key = admin ? 'ajwt' : 'jwt'
		return storage.local.get(key) || storage.session.get(key)
	},
	remove : (admin = false) => {
		const key = admin ? 'ajwt' : 'jwt'
		storage.local.remove(key)
		storage.session.remove(key)
	}
}