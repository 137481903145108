export const CREATE = {
	first_name : null,
	last_name  : null,
	email      : null,
	phone      : null,
	address1   : null,
	address2   : null,
	city       : null,
	state      : null,
	zip        : null,
	country    : 'US',
	fields     : {
		all    : [],
		values : []
	},
	gender    : null,
	birthdate : null
}

export const CURRENT = {
	id            : null,
	...CREATE,
	created       : null,
	updated       : null,
	registrations : [],
	emails        : []
}