export const CREATE = {
	name                      : null,
	email_template_wrapper_id : null,
	message                   : '',
	subject                   : null,
	from_name                 : null,
	from_email                : null,
	attachments               : []
}

export const CURRENT = {
	id : null,
	...CREATE
}