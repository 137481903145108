import router from '@/registration/router'

export default {
	fetch ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/account/',
			then   : response => commit('setCurrent', response.body)
		})
	},
	fetchDonations ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/account/donations/',
			then   : response => commit('setDonations', response.body)
		})
	},
	edit ({ commit, state }, redirect = true) {
		const payload = { ...state.current }

		// We don't need these options.
		delete payload.id
		delete payload.password2
		delete payload.created
		delete payload.updated

		// Reformat the fields being sent.
		payload.fields = { ...payload.fields.values }

		return this._vm.$api({
			method : 'put',
			url    : '/account/edit/',
			send   : payload,
			then   : response => {
				commit('setCurrent', response.body)
				if (redirect) {
					router.push({ name: 'account' })
				}
			}
		})
	},
	create ({ commit, state }) {
		return this._vm.$api({
			method : 'post',
			url    : '/account/',
			send   : state.newAccount,
			then   : response => {
				this._vm.$auth.set(response.body.token, true)
				commit('setCurrent', response.body.account)
			},
			// Otherwise redirect to the admin page.
			routerRedirect : () => ({ name: 'home' })
		})
	},
	updateCreate ({ commit, state }, payload) {
		return new Promise(resolve => {
			commit('setCreate', {
				...state.create,
				...payload
			})

			resolve()
		})
	},
	updateCurrent ({ commit, state }, payload) {
		return new Promise(resolve => {
			commit('setCurrent', {
				...state.current,
				...payload
			})

			resolve()
		})
	},
	updateCurrentAdditional ({ commit, state }, payload) {
		return new Promise(resolve => {
			const { current } = state
			current.fields.values = {
				...current.fields.values,
				...payload
			}
			commit('setCurrent', {
				...current
			})

			resolve()
		})
	},
	getDueRegistrations ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/registration/due/',
			then   : response => commit('setDue', response.body)
		})
	}
}