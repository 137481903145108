import {
	Auth
} from '@/registration/middleware'

import { loadView } from './load'

export default [
	{
		path : '/register',
		name : 'register',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/Register')
	},
	{
		path : '/register/:event_id',
		name : 'register-event',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/Register')
	},
	{
		path : '/register/:key/attendee',
		name : 'register-attendee',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/Attendee')
	},
	{
		path : '/register/:key/attendee/add',
		name : 'register-attendee-add',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/AttendeeAdd')
	},
	{
		path : '/register/:key/attendee/:attendee_id',
		name : 'register-attendee-edit',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/AttendeeEdit')
	},
	{
		path : '/register/:key/events',
		name : 'register-events',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/Events')
	},
	{
		path : '/register/:key/questions',
		name : 'register-questions',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/Questions')
	},
	{
		path : '/register/:key/financial-options',
		name : 'register-financial-options',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/Financial')
	},
	{
		path : '/register/:key/travel-options',
		name : 'register-travel-options',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/Travel')
	},
	{
		path : '/register/:key/waitlist',
		name : 'register-waitlist',
		meta : {
			middleware : [ Auth ],
			background : 'dark-red',
			label      : 'This Event is Full'
		},
		component : loadView('register/Waitlist')
	},
	{
		path : '/register/:key/summary',
		name : 'register-summary',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/Summary')
	},
	{
		path : '/register/:key/payment',
		name : 'register-payment',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/Payment')
	},
	{
		path : '/register/:key/cancel',
		name : 'register-cancel',
		meta : {
			middleware : [ Auth ],
			background : 'dark-red',
			label      : 'Cancel Registration'
		},
		component : loadView('register/Cancel')
	},
	{
		path : '/register/:key/confirmation',
		name : 'register-confirmation',
		meta : {
			middleware : [ Auth ],
			background : 'white',
			label      : 'Confirmation'
		},
		component : loadView('register/Confirmation')
	},
	{
		path : '/register/:key/save',
		name : 'save',
		meta : {
			middleware : [ Auth ],
			background : 'dark-red',
			label      : 'Save and Log Out'
		},
		component : loadView('register/Save')
	},
	{
		path : '/register/:key/group',
		name : 'register-group',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Group Registration'
		},
		component : loadView('register/Group')
	},
	{
		path : '/register/:key/group-map',
		name : 'register-group-map',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Group Registration'
		},
		component : loadView('register/GroupMap')
	},
	{
		path : '/register/:event_id/:attendee_type_id',
		name : 'register-event-attendee-type',
		meta : {
			middleware : [ Auth ],
			background : 'red',
			label      : 'Register'
		},
		component : loadView('register/Register')
	},
	{
		path : '/register/restricted',
		name : 'register-restricted',
		meta : {
			middleware : [ Auth ],
			background : 'dark-red',
			label      : 'Account Restricted'
		},
		component : loadView('register/Restricted')
	}
]