import {
	NoAuth
} from '@/middleware'
import { Auth } from '@/registration/middleware'

import { loadView } from './load'

export default [
	{
		path : '/create-account',
		name : 'create-account',
		meta : {
			middleware : [ NoAuth ],
			label      : 'Create Account'
		},
		component : loadView('account/Create')
	},
	{
		path : '/account',
		name : 'account',
		meta : {
			middleware : [ Auth ],
			label      : 'My Account'
		},
		component : loadView('account/Account')
	},
	{
		path : '/account/donations',
		name : 'account-donations',
		meta : {
			middleware : [ Auth ],
			label      : 'Donations'
		},
		component : loadView('account/Donations')
	},
	{
		path : '/account/edit',
		name : 'account-edit',
		meta : {
			middleware : [ Auth ],
			background : 'gray',
			label      : 'Edit Account Details'
		},
		component : loadView('account/Edit')
	}
]