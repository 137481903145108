export default {
	isFiltered : state => key => {
		const { filters } = state.adminSettings
		let isFiltered = false
		Object.keys(filters[key]).forEach(f => {
			const filter = filters[key][f]

			if (filter || 0 === filter) {
				if (Array.isArray(filter)) {
					isFiltered = isFiltered || !!filter.length
				} else {
					isFiltered = true
				}
			}
		})

		return isFiltered
	}
}