export default {
	setCurrent (state, payload) {
		const { current } = state
		state.current = {
			...current,
			...payload
		}
	},
	setCreate (state, payload) {
		state.create = payload
	},
	resetNewAccount (state) {
		const reset = {}
		const { newAccount } = state
		Object.keys(newAccount).forEach(key => {
			reset[key] = null
		})

		state.newAccount = reset
	},
	setDue (state, payload) {
		state.due = payload
	},
	setDonations (state, payload) {
		state.donations = payload
	},
	cancelSaved (state, key) {
		const { savedRegistrations } = state.current
		savedRegistrations.forEach((registration, i) => {
			if (key === registration.id) {
				this._vm.$delete(state.current.savedRegistrations, i)
			}
		})
	}
}