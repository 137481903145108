<template lang="html">
	<input type="tel"
		:value="formattedValue"
		@change="change"
		v-money="{precision, decimal, thousands, prefix, suffix, min, max, allowBlank}"
		class="v-money"
	/>
</template>

<script>
import money from './directive'
import defaults from './options'
import { format, unformat } from './utils'

export default {
	name  : 'Money',
	props : {
		value : {
			required : true,
			type     : [ Number, String ],
			default  : ''
		},
		masked : {
			type    : Boolean,
			default : false
		},
		precision : {
			type    : Number,
			default : () => defaults.precision
		},
		decimal : {
			type    : String,
			default : () => defaults.decimal
		},
		thousands : {
			type    : String,
			default : () => defaults.thousands
		},
		max : {
			type    : Number,
			default : () => defaults.max
		},
		min : {
			type    : Number,
			default : () => defaults.min
		},
		prefix : {
			type    : String,
			default : () => defaults.prefix
		},
		suffix : {
			type    : String,
			default : () => defaults.suffix
		},
		allowBlank : {
			type    : Boolean,
			default : () => defaults.allowBlank
		}
	},

	directives : { money },

	data () {
		return {
			formattedValue : ''
		}
	},

	watch : {
		value : {
			immediate : true,
			handler (newValue) {
				const formatted = format(newValue, this.$props)
				if (formatted !== this.formattedValue) {
					this.formattedValue = formatted
				}
			}
		}
	},

	methods : {
		change (event) {
			this.$emit('input', this.masked || '' === event.target.value ? event.target.value : unformat(event.target.value, this.precision))
		}
	}
}
</script>