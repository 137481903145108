import {
	allowed
} from '@/admin/utils'

const ccType = type => {
	switch (type) {
		case 'Visa':
			return 'visa'
		case 'Discover':
			return 'discover'
		case 'MasterCard':
			return 'mastercard'
		case 'American Express':
			return 'amex'
	}
}

const capitalizeFirstLetter = string => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

const formatLabel = label => {
	return label.endsWith('?') ? label : label + ':'
}

export const parseLabel = (label, { account = {}, attendee = {}, event = {} }) => {
	const replacements = {
		attendee_first_name : attendee.first_name,
		attendee_last_name  : attendee.last_name,
		attendee_name       : (attendee.first_name + ' ' + attendee.last_name).trim(),
		account_first_name  : account.first_name,
		account_last_name   : account.last_name,
		account_name        : (account.first_name + ' ' + account.last_name).trim(),
		event_name          : event.name
	}

	return label.replace(/{{\w+}}/g, all => {
		all = all.replace('{{', '').replace('}}', '')
		return replacements[all] || all
	})
}

const formatMultipleValues = values => {
	return values.map(v => v.prettyValue).join(', ')
}

const getFinancialOptionGroups = financialOptions => {
	const groups = {}
	financialOptions.forEach(option => {
		if (!option.financial_option_group_name) {
			option.financial_option_group_name = 'Additional Financial Options'
		}
		if (!groups[option.financial_option_group_name]) {
			groups[option.financial_option_group_name] = []
		}

		groups[option.financial_option_group_name].push(option)
	})
	return groups
}

const getEventFinancialDiscounts = (registration, financialOptionId) => {
	return registration.discounts
		.filter(discount => discount.registration_financial_id === financialOptionId)
}

const getEventSubtotalDiscounts = (registration) => {
	return registration.discounts
		.filter(discount => !discount.registration_financial_id)
}

const showTravelOptions = registration => {
	if (!registration.travel_options.length) {
		return false
	}

	const arriving = getArrivingTravelOptions(registration)

	const departing = getDepartingTravelOptions(registration)

	if (!arriving && !departing) {
		return false
	}

	return true
}

const getArrivingTravelOptions = registration => {
	return registration.travel_options.filter(to => 'arriving' === to.type)[0]
}

const getDepartingTravelOptions = registration => {
	return registration.travel_options.filter(to => 'departing' === to.type)[0]
}

const getRoles = currentRole => {
	return [
		{ label: 'Owner', value: 'owner' },
		{ label: 'Admin', value: 'admin' },
		{ label: 'Office Staff', value: 'staff' },
		{ label: 'Key Volunteer', value: 'stateco' },
		{ label: 'Registration', value: 'registration' }
	].filter(role => {
		if ('owner' === role.value && !allowed('account.edit.owner') && 'owner' !== currentRole) {
			return false
		}

		if ('admin' === role.value && !allowed('account.edit.admin') && 'owner' !== currentRole && 'admin' !== currentRole) {
			return false
		}

		return true
	})
}

const numberWithCommas = number => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export {
	capitalizeFirstLetter,
	ccType,
	formatLabel,
	formatMultipleValues,
	getArrivingTravelOptions,
	getDepartingTravelOptions,
	getEventFinancialDiscounts,
	getEventSubtotalDiscounts,
	getFinancialOptionGroups,
	getRoles,
	numberWithCommas,
	showTravelOptions
}