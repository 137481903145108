import { CREATE, CURRENT } from './defaults'
import * as m from '@/admin/store/commonMutations'

const {
	setPayload,
	setPagination,
	setCurrent,
	...mutations
} = m

export default {
	...mutations,
	resetNew (state) {
		setPayload(this._vm, state, 'newModel', { ...CREATE })
	},
	resetCurrent (state) {
		setPayload(this._vm, state, 'current', { ...CURRENT })
	},
	setPagination (state, payload) {
		setPagination(this._vm, state, payload)
	},
	setRegistrationEvents (state, payload) {
		state.registration.events = payload.events
		state.registration.types  = payload.types
	},
	setTypes (state, payload) {
		setPayload(this._vm, state, 'types', payload)
	},
	setCurrent (state, payload) {
		payload.event_types = []
		if (Object.keys(payload.eventTypes).length) {
			Object.keys(payload.eventTypes).forEach(eventTypeId => {
				payload.event_types.push(eventTypeId)
			})
		}

		payload.status                          = payload.status.toLowerCase()
		payload.attendee_types                  = []
		payload.attendee_type_details           = {}
		payload.attendee_type_questions         = {}
		payload.attendee_type_financial_options = {}
		payload.attendee_type_travel_options    = {}
		payload.attendee_type_emails            = {}
		payload.event_capacities                = [ ...payload.capacities ]
		if (Object.keys(payload.attendeeTypes).length) {
			Object.keys(payload.attendeeTypes).forEach(attendeeTypeId => {
				payload.attendee_types.push(attendeeTypeId)

				const attendeeType = payload.attendeeTypes[attendeeTypeId]
				if (attendeeType.questions) {
					payload.attendee_type_questions[attendeeTypeId] = [ ...attendeeType.questions ]
					delete attendeeType.questions
				}

				if (attendeeType.financial_options) {
					payload.attendee_type_financial_options[attendeeTypeId] = [ ...attendeeType.financial_options ]
					delete attendeeType.financial_options
				}

				if (attendeeType.travel_options) {
					payload.attendee_type_travel_options[attendeeTypeId] = [ ...attendeeType.travel_options ]
					delete attendeeType.travel_options
				}

				if (attendeeType.travelOptions) {
					payload.attendee_type_travel_options[attendeeTypeId] = [ ...attendeeType.travelOptions ]
					delete attendeeType.travelOptions
				}

				if (attendeeType.emails) {
					payload.attendee_type_emails[attendeeTypeId] = { ...attendeeType.emails }
					delete attendeeType.emails
				}

				attendeeType.deposit_amount = parseFloat(attendeeType.deposit_amount)
				payload.attendee_type_details[attendeeTypeId] = attendeeType
			})
		}

		if (!payload.description) {
			payload.description = ''
		}

		setCurrent(state, payload)
	},
	setEventSearch (state, payload) {
		state.searchResults = payload
	},
	updateAttendeeTypeDetails (state, payload) {
		this._vm.$set(state.current.attendee_type_details, payload.id, payload.data)
	},
	updateAttendeeTypeQuestions (state, payload) {
		this._vm.$set(state.current.attendee_type_questions, payload.id, payload.data.map(question => question.id))
	},
	copyAttendeeTypeQuestions (state, payload) {
		const currentQuestions = state.current.attendee_type_questions[payload.current]

		if (currentQuestions) {
			this._vm.$set(state.current.attendee_type_questions, payload.current, currentQuestions
				.concat(state.current.attendee_type_questions[payload.copyFrom])
				.filter((questionId, index, self) => self.indexOf(questionId) === index)
			)
		}
	},
	updateAttendeeTypeTravelOptions (state, payload) {
		this._vm.$set(state.current.attendee_type_travel_options, payload.id, payload.data.map(option => option.id))
	},
	copyAttendeeTypeTravelOptions (state, payload) {
		const currentTravelOptions = state.current.attendee_type_travel_options[payload.current]
			.concat(state.current.attendee_type_travel_options[payload.copyFrom])
			.filter((travelOptionTypeId, index, self) => self.indexOf(travelOptionTypeId) === index)
		this._vm.$set(state.current.attendee_type_travel_options, payload.current, currentTravelOptions)
	},
	updateAttendeeTypeEmails (state, payload) {
		this._vm.$set(state.current.attendee_type_emails, payload.id, payload.data)
	},
	copyAttendeeTypeEmails (state, payload) {
		const currentEmails = state.current.attendee_type_emails[payload.current]
			.concat(state.current.attendee_type_emails[payload.copyFrom])
			.filter((emailId, index, self) => self.indexOf(emailId) === index)
		this._vm.$set(state.current.attendee_type_emails, payload.current, currentEmails)
	},
	updateAttendeeTypeFinancialOptions (state, payload) {
		const groups = []
		payload.data.forEach(group => {
			const g = {
				...group,
				options : []
			}
			group.options
				.filter(option => option)
				.forEach(option => {
					g.options.push(option.id)
				})
			groups.push(g)
		})
		this._vm.$set(state.current.attendee_type_financial_options, payload.id, groups)
	},
	copyAttendeeTypeFinancialOptions (state, payload) {
		const options                  = []
		const currentFinancialOptions  = state.current.attendee_type_financial_options[payload.current] || []
		const copyFromFinancialOptions = [ ...state.current.attendee_type_financial_options[payload.copyFrom] ]

		currentFinancialOptions.forEach(group => options.concat(group.options))

		copyFromFinancialOptions.forEach(group => {
			// If the group is not in the current financial options, let's add the group.
			const index = currentFinancialOptions.findIndex(g => g.id === group.id)
			if (-1 === index) {
				const ind = currentFinancialOptions.push({
					...group,
					options : []
				}) - 1

				group.options.forEach(option => {
					const i = options.indexOf(option)
					if (-1 === i) {
						currentFinancialOptions[ind].options.push(option)
					}
				})
			}
		})

		this._vm.$set(state.current.attendee_type_financial_options, payload.current, currentFinancialOptions)
	},
	updateEventCapacities (state, payload) {
		this._vm.$set(state.current, 'event_capacities', payload)
	},
	updatePermissions (state, { type, permissions }) {
		const deleteIndexes = []
		state.current.permissions.forEach((p, i) => {
			if (type === p.type) {
				deleteIndexes.push(i)
			}
		})

		for (var i = deleteIndexes.length - 1; 0 <= i; i--) {
			this._vm.$delete(state.current.permissions, deleteIndexes[i])
		}

		const allPermissions = state.current.permissions.concat(permissions)
		this._vm.$set(state.current, 'permissions', allPermissions)
	}
}