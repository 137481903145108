export default {
	addAll (state, payload) {
		state.questions = payload.questions
		state.event     = payload.event
	},
	setCurrentEvent (state, event) {
		state.currentEvent = event
	},
	setCurrentAttendeeType (state, attendeeType) {
		state.currentAttendeeType = attendeeType
	}
}