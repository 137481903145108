export const CREATE = {
	name    : null,
	type    : 'registration',
	columns : [],
	custom  : [],
	filters : {
		events           : [],
		eventTypes       : [],
		attendeeTypes    : [],
		financialOptions : [],
		discounts        : [],
		travelOptions    : [],
		questions        : [],
		donations        : []
	},
	archived : 0
}

export const CURRENT = {
	id : null,
	...CREATE
}