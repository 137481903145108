import {
	Auth
} from '@/registration/middleware'

import { loadView } from './load'

export default [
	{
		path : '/payment',
		name : 'payment',
		meta : {
			middleware : [ Auth ],
			label      : 'Make A Payment'
		},
		component : loadView('payment/Payment')
	},
	{
		path : '/payment/:id/confirmation',
		name : 'payment-confirmation',
		meta : {
			middleware : [ Auth ],
			label      : 'Confirmation'
		},
		component : loadView('payment/Confirmation')
	}
]