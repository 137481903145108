import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/stats/:id',
		name      : 'admin-dashboard-stat',
		component : loadView('dashboard/Stat'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'View Stats'
		}
	}
]