import Vue from 'vue'

export default {
	addPublishableKey (state, payload) {
		state.publishableKey  = payload
		Vue.prototype.$stripe = window.Stripe(payload)
	},
	addSavedCards (state, payload) {
		state.savedCards = payload
	},
	setCardNumber (state, payload) {
		state.cardNumber = payload
	},
	setCardExpiry (state, payload) {
		state.cardExpiry = payload
	},
	setCardCvc (state, payload) {
		state.cardCvc = payload
	},
	setGateway (state, payload) {
		state.paymentData.gateway = payload
	},
	setPaymentToken (state, payload) {
		state.paymentData.token = payload
	},
	setPaymentMethod (state, payload) {
		state.paymentData.card = payload
	},
	setCheckNumber (state, payload) {
		state.paymentData.checkNumber = payload
	},
	setAmounts (state, payload) {
		state.paymentAmounts = payload
	},
	setDonations (state, payload) {
		state.donations = payload
	},
	setRegistrations (state, { transactionId, registrations }) {
		this._vm.$set(state.registrations, transactionId, registrations)
	}
}