import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/email-templates',
		name      : 'admin-email-templates',
		component : loadView('email-templates/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Email Templates'
		}
	},
	{
		path      : '/email-templates/new',
		name      : 'admin-email-template-new',
		component : loadView('email-templates/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Email Template'
		}
	},
	{
		path      : '/email-templates/archive',
		name      : 'admin-email-templates-archives',
		component : loadView('email-templates/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Email Template Archives'
		}
	},
	{
		path      : '/email-templates/:id',
		name      : 'admin-email-template-edit',
		component : loadView('email-templates/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Email Template'
		}
	},
	{
		path      : '/email-template-wrappers',
		name      : 'admin-email-template-wrappers',
		component : loadView('email-template-wrappers/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Email Template Wrappers'
		}
	},
	{
		path      : '/email-template-wrappers/new',
		name      : 'admin-email-template-wrapper-new',
		component : loadView('email-template-wrappers/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Email Template'
		}
	},
	{
		path      : '/email-template-wrappers/archive',
		name      : 'admin-email-template-wrappers-archives',
		component : loadView('email-template-wrappers/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Email Template Wrappers Archives'
		}
	},
	{
		path      : '/email-template-wrappers/:id',
		name      : 'admin-email-template-wrapper-edit',
		component : loadView('email-template-wrappers/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Email Template Wrapper'
		}
	}
]