export default {
	addAll (state, payload) {
		state.attendees = payload.attendees || []
		state.fields    = payload.fields    || []
		state.types     = payload.types     || []
	},
	add (state, payload) {
		state.attendees.push(payload)
	},
	setCurrent (state, payload) {
		state.current = payload
	},
	setFieldValues (state, payload) {
		state.current.fields.values = payload
	},
	resetCurrent (state) {
		const reset = {}
		const { current } = state
		Object.keys(current).forEach(key => {
			reset[key] = null

			if ('country' === key) {
				reset[key] = 'US'
			}

			if ('fields' === key) {
				reset[key] = {
					all    : [],
					values : {}
				}
			}
		})

		state.current = reset
	},
	updateSavedRegistration (state, { id, registration }) {
		state.attendees.forEach((attendee, attendeeIndex) => {
			attendee.registrations.forEach((r, registrationIndex) => {
				if (r.id === id) {
					this._vm.$set(state.attendees[attendeeIndex].registrations, registrationIndex, registration)
				}
			})
		})
	}
}