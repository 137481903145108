import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/donations',
		name      : 'admin-donations',
		component : loadView('donations/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Donations'
		}
	},
	{
		path      : '/donations/new',
		name      : 'admin-donation-new',
		component : loadView('donations/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Donation'
		}
	},
	{
		path      : '/donations/archives',
		name      : 'admin-donations-archives',
		component : loadView('donations/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Donation Archives'
		}
	},
	{
		path      : '/donations/:id',
		name      : 'admin-donation-edit',
		component : loadView('donations/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Donation'
		}
	}
]