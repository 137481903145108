export default {
	fetchSettings ({ commit, dispatch }) {
		return this._vm.$api({
			method : 'get',
			url    : '/admin/settings/',
			then   : (response, resolve) => {
				if (response.body) {
					commit('setAdminSettings', response.body)
					commit('settingsLoaded', true)
					resolve()
				} else {
					dispatch('saveSettings')
						.then(() => commit('settingsLoaded', true))
						.then(() => resolve())
				}
			}
		})
	},
	saveSettings ({ state }) {
		return this._vm.$api({
			method : 'post',
			url    : '/admin/settings/',
			send   : state.adminSettings
		})
	},
	updateSetting ({ commit, dispatch }, payload) {
		commit('updateSetting', payload)
		return dispatch('saveSettings')
	},
	updatePerPageSetting ({ state, dispatch }, payload) {
		const perPage = { ...state.adminSettings.per_page }
		if (!perPage[payload.key]) {
			throw new Error('Per Page setting is invalid.')
		}

		perPage[payload.key] = payload.value
		return dispatch('updateSetting', {
			key   : 'per_page',
			value : perPage
		})
	},
	updateFilterSetting ({ state, dispatch }, payload) {
		const filters = { ...state.adminSettings.filters }
		if (!filters[payload.key]) {
			throw new Error('Filter setting is invalid.')
		}

		filters[payload.key] = payload.value
		return dispatch('updateSetting', {
			key   : 'filters',
			value : filters
		})
	},
	updateSortSetting ({ state, dispatch }, payload) {
		const sort = { ...state.adminSettings.sort }
		if (!sort[payload.key]) {
			throw new Error('Sort setting is invalid.')
		}

		sort[payload.key] = payload.value
		return dispatch('updateSetting', {
			key   : 'sort',
			value : sort
		})
	},
	getDueRegistrations ({ commit }, eventId) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/billing/event/${eventId}/due/`,
			then   : response => commit('setDueRegistrations', response.body)
		})
	},
	autoBill (context, payload) {
		return this._vm.$api({
			method       : 'post',
			send         : payload,
			url          : '/admin/billing/auto-bill/',
			notification : {
				type    : 'success',
				message : 'Auto Bill completed successfully!',
				icon    : 'fas fa-bell'
			}
		})
	}
}