import adminStore from '@/admin/store'
import paths from '@/admin/router/paths'

const roles = {
	owner : {
		allowAll : true
	},
	admin : {
		allowAll : true,
		deny     : [
			'account.edit.owner'
		]
	},
	staff : {
		allow : [
			...paths
				.filter(path => 'admin-settings' !== path.name)
				.filter(path => 'admin-auto-billing' !== path.name)
				.map(path => `route.${path.name}`),
			'sidebar.dashboard',
			'sidebar.registrations',
			'sidebar.reports',
			'sidebar.reportTypes',
			'sidebar.accounts',
			'sidebar.events',
			'sidebar.formFields',
			'sidebar.questions',
			'sidebar.financialOptions',
			'sidebar.discounts',
			'sidebar.travelOptionTypes',
			'sidebar.emailTemplates',
			'sidebar.calendar',
			'account.view',
			'account.edit.can_edit',
			'account.edit.role',
			'account.tabs.information.view',
			'account.tabs.attendees.view',
			'account.tabs.registrations.view',
			'account.tabs.credit.view',
			'account.tabs.credit-cards.view',
			'account.tabs.emails.view',
			'attendee.view',
			'attendee.edit',
			'registration.view',
			'registration.edit',
			'registration.totals.view',
			'registration.tabs.registration-summary.view',
			'registration.tabs.questions.view',
			'registration.tabs.questions.edit',
			'registration.tabs.financial-options.view',
			'registration.tabs.financial-options.edit',
			'registration.tabs.travel-options.view',
			'registration.tabs.travel-options.edit',
			'registration.tabs.transactions.view',
			'registration.tabs.transactions.edit',
			'registration.tabs.emails.view',
			'report.edit',
			'report.archive',
			'report.delete',
			'report.duplicate',
			'event.edit',
			'event.archive',
			'event.delete',
			'event.duplicate',
			'event-type.edit',
			'event-type.archive',
			'event-type.delete',
			'event-type.duplicate',
			'account-form-field.edit',
			'account-form-field.archive',
			'account-form-field.delete',
			'account-form-field.duplicate',
			'attendee-form-field.edit',
			'attendee-form-field.archive',
			'attendee-form-field.delete',
			'attendee-form-field.duplicate',
			'question.edit',
			'question.archive',
			'question.delete',
			'question.duplicate',
			'financial-option.edit',
			'financial-option.archive',
			'financial-option.delete',
			'financial-option.duplicate',
			'financial-option-group.edit',
			'financial-option-group.archive',
			'financial-option-group.delete',
			'financial-option-group.duplicate',
			'discount.edit',
			'discount.archive',
			'discount.delete',
			'discount.duplicate',
			'travel-option-type.edit',
			'travel-option-type.archive',
			'travel-option-type.delete',
			'travel-option-type.duplicate',
			'travel-option-type-option.edit',
			'travel-option-type-option.archive',
			'travel-option-type-option.delete',
			'travel-option-type-option.duplicate',
			'email-template.edit',
			'email-template.archive',
			'email-template.delete',
			'email-template.duplicate',
			'email-template-wrapper.edit',
			'email-template-wrapper.archive',
			'email-template-wrapper.delete',
			'email-template-wrapper.duplicate'
		]
	},
	stateco : {
		allow : [
			'route.admin-dashboard',
			'route.admin-account-edit',
			'route.admin-registrations',
			'route.admin-registration-edit',
			'route.admin-reports',
			'route.admin-calendar',
			'sidebar.dashboard',
			'sidebar.registrations',
			'sidebar.reports',
			'sidebar.calendar',
			'account.tabs.information.view',
			'account.tabs.attendees.view',
			'account.tabs.registrations.view',
			'registration.view.can_view',
			'registration.tabs.registration-summary.view',
			'registration.tabs.questions.view',
			'registration.tabs.financial-options.view',
			'registration.tabs.travel-options.view'
		]
	},
	registration : {
		denyAll : true
	}
}

export default (permission, category = 'roles', resource) => {
	// Get the current account.
	const account = adminStore.state.adminUser

	if ('roles' === category) {
		// If the account does not have a role, return false.
		if (!(account.role in roles)) {
			return false
		}

		const role = roles[account.role]
		if (!role.deny) {
			role.deny = []
		}

		if (!role.allow) {
			role.allow = []
		}

		if (role.denyAll) {
			return false
		}

		// Quick check to see if this account has all permissions.
		if (role.allowAll && !role.deny.includes(permission)) {
			return true
		}

		// If we don't have a resource, do a quick check for the exact permission.
		if (!resource) {
			return role.allow.includes(permission) && !role.deny.includes(permission)
		}

		// If the exact permission exists, then return true.
		if (role.allow.includes(permission) && !role.deny.includes(permission)) {
			return true
		}

		// Check if the account has a conditional permission.
		const permissions = role.allow.filter(p => p.startsWith(permission))
		const passed      = []
		if (permissions.length) {
			permissions.forEach(p => {
				const attribute = p.replace(permission + '.', '')
				passed.push(resource[attribute])
			})

			return passed.every(p => p)
		}

		// Check if the account is denied a conditional permission.
		const deniedPermissions = role.deny.filter(p => p.startsWith(permission))
		const passedDenied      = []
		if (deniedPermissions.length) {
			deniedPermissions.forEach(p => {
				const attribute = p.replace(permission + '.', '')
				passedDenied.push(resource[attribute])
			})

			return !passedDenied.every(p => p)
		}

		return false
	}

	if (!account || !account[category]) {
		return false
	}

	if (Array.isArray(permission)) {
		const passed = []
		permission.forEach(p => {
			passed.push(account[category].includes(p))
		})

		return passed.some(p => p)
	}

	return account[category].includes(permission)
}