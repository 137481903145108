import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/questions',
		name      : 'admin-questions',
		component : loadView('questions/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Questions'
		}
	},
	{
		path      : '/questions/new',
		name      : 'admin-question-new',
		component : loadView('questions/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Question'
		}
	},
	{
		path      : '/questions/archives',
		name      : 'admin-questions-archives',
		component : loadView('questions/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Question Archives'
		}
	},
	{
		path      : '/questions/:id',
		name      : 'admin-question-edit',
		component : loadView('questions/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Question'
		}
	}
]