export const state = {
	logs          : [],
	models        : [],
	modelArchives : [],
	allModels     : [],
	archives      : false,
	localSearch   : null,
	pagination    : {
		page  : 1,
		total : null
	}
}