export default {
	newAccount : {
		first_name : null,
		last_name  : null,
		email      : null,
		phone      : null,
		password   : null,
		password2  : null
	},
	current : {
		id         : null,
		first_name : null,
		last_name  : null,
		email      : null,
		phone      : null,
		address1   : null,
		address2   : null,
		city       : null,
		state      : null,
		country    : 'US',
		zip        : null,
		fields     : {
			all    : [],
			values : []
		},
		amounts : {
			account_credit : 0,
			balance_due    : 0
		},
		savedRegistrations : [],
		created            : null,
		updated            : null
	},
	due       : [],
	donations : []
}