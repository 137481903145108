export const CREATE = {
	name        : null,
	enabled     : 1,
	archived    : 0,
	description : ''
}

export const CURRENT = {
	id : null,
	...CREATE
}