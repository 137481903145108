import moment from 'moment'

const format = date => {
	return moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')
}

const get = (start, end) => {
	start = format(start)
	end   = format(end)

	if (start === end) {
		return end
	}

	return `${start} - ${end}`
}

const allowDeposit = (allow, start, integer = 2, unit = 'weeks') => {
	if (!allow) {
		return false
	}

	const now      = moment()
	const deadline = moment(start).subtract(integer, unit).startOf('day')

	// If it is before the deadline, allow a deposit.
	return now.isBefore(deadline)
}

export default {
	allowDeposit,
	format,
	get
}