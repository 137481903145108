export default function Auth ({ next, router, to }) {
	const token = router.app.$auth.get()

	if (!token) {
		const params = {}

		if (to.params.event_id) {
			params.event_id = to.params.event_id
			return router.push({ name: 'login-event', params })
		}

		return router.push({ name: 'login' })
	}

	return next()
}