export default {
	addAll (state, payload) {
		state.discounts = payload.discounts || []
	},
	addEnteredCode (state, { discountCode, index }) {
		const discount = state.discounts[index]
		discount.enteredCode = discountCode.toUpperCase()

		this._vm.$set(state.discounts, index, discount)
	}
}