export default {
	setImportStage (state, stage) {
		this._vm.$set(state, 'importStage', stage)
	},
	setImportCount (state, count) {
		this._vm.$set(state, 'importCount', count)
	},
	setImportTotal (state, total) {
		this._vm.$set(state, 'importTotal', total)
	},
	setRegistrationKey (state, registrationKey) {
		this._vm.$set(state, 'registrationKey', registrationKey)
	},
	setAccount (state, accountId) {
		this._vm.$set(state, 'account', accountId)
	},
	resetRegistration (state, payload) {
		Object.keys(payload).forEach(key => {
			// Normalize the data.
			switch (key) {
				case 'attendees':
				case 'events':
				case 'financial':
				case 'questions':
				case 'travel':
				case 'discounts':
					if (Array.isArray(payload[key]) && !payload[key].length) {
						payload[key] = {}
					}
			}
			this._vm.$set(state, key, payload[key])
		})
	},
	addAttendee (state, payload) {
		this._vm.$set(state.attendees, payload.id, {
			events : []
		})

		this._vm.$set(state.current, 'attendee', payload.id)
	},
	removeAttendee (state, attendeeId) {
		this._vm.$delete(state.attendees, attendeeId)
	},
	addEvent (state, payload) {
		const defaults = { ...payload }

		// We don't need the these items in defaults.
		delete defaults.event
		delete defaults.uniqid

		const existingEventData = defaults

		let { uniqid, event } = payload,
			existingEvent     = false

		// First look to see if the currently selected event already exists for this attendee.
		Object.keys(state.events).forEach(_uniqid => {
			const e = state.events[_uniqid]
			if (e.id === event && e.attendee === state.current.attendee) {
				existingEvent = _uniqid
			}
		})
		if (existingEvent) {
			uniqid = existingEvent

			// Delete any existing registration options for this event.
			this._vm.$delete(state.questions, state.events[uniqid].questions)
			this._vm.$delete(state.financial, state.events[uniqid].financial)
			this._vm.$delete(state.travel, state.events[uniqid].travel)

			// Let's check if this uniqid is attached to the attendee.
			const { events } = state.attendees[state.current.attendee]
			const event      = events.find(eventId => eventId === uniqid)
			if (!event) {
				// Add our existing event ID.
				events.push(uniqid)

				this._vm.$set(state.attendees[state.current.attendee], 'events', events)
			}
		} else {
			const { events } = state.attendees[state.current.attendee]

			// Add our new event ID.
			events.push(uniqid)

			this._vm.$set(state.attendees[state.current.attendee], 'events', events)
		}

		this._vm.$set(state.events, uniqid, {
			id        : event,
			attendee  : state.current.attendee,
			questions : null,
			financial : null,
			travel    : null,
			...existingEventData
		})

		payload.event = uniqid

		// We don't need these items in the current payload.
		delete payload.allowDeposit
		delete payload.depositAmount
		delete payload.name
		delete payload.uniqid

		this._vm.$set(state, 'current', {
			...state.current,
			...payload
		})
	},
	removeEvent (state, { attendeeId, eventId }) {
		const { attendees, events } = state
		const attendee = { ...attendees[attendeeId] }

		attendee.events = attendee.events.filter(eId => eId !== eventId)

		attendees[attendeeId] = attendee
		this._vm.$set(state, 'attendees', attendees)

		// First remove all questions, financial options and travel options associated with this event.
		const event = events[eventId]

		if (event) {
			this._vm.$delete(state.questions, event.questions)
			this._vm.$delete(state.financial, event.financial)
			this._vm.$delete(state.travel, event.travel)
		}

		// Now remove the event itself.
		this._vm.$delete(state.events, eventId)
	},
	addQuestions (state, payload) {
		let { uniqid } = payload,
			existingQuestions = false

		delete payload.uniqid

		// First look to see if the currently selected event already has questions.
		Object.keys(state.questions).forEach(_uniqid => {
			const q = state.questions[_uniqid]
			if (q.event === state.current.event && q.attendee === state.current.attendee) {
				existingQuestions = _uniqid
			}
		})

		if (existingQuestions) {
			uniqid = existingQuestions
		}

		this._vm.$set(state.questions, uniqid, {
			questions : { ...payload },
			event     : state.current.event,
			attendee  : state.current.attendee
		})

		this._vm.$set(state.events[state.current.event], 'questions', uniqid)
	},
	addFinancialOptions (state, { selected, uniqid }) {
		let existingFinancialOptions = false

		// First look to see if the currently selected event already has financial options.
		Object.keys(state.financial).forEach(_uniqid => {
			const fo = state.financial[_uniqid]
			if (fo.event === state.current.event && fo.attendee === state.current.attendee) {
				existingFinancialOptions = _uniqid
			}
		})

		if (existingFinancialOptions) {
			uniqid = existingFinancialOptions
		}

		this._vm.$set(state.financial, uniqid, {
			options : {
				...selected
			},
			event    : state.current.event,
			attendee : state.current.attendee
		})

		this._vm.$set(state.events[state.current.event], 'financial', uniqid)
	},
	addTravelOptions (state, payload) {
		let { uniqid } = payload,
			existingTravelOptions = false

		delete payload.uniqid

		// First look to see if the currently selected event already has travel options.
		Object.keys(state.travel).forEach(_uniqid => {
			const to = state.travel[_uniqid]
			if (to.event === state.current.event && to.attendee === state.current.attendee) {
				existingTravelOptions = _uniqid
			}
		})

		if (existingTravelOptions) {
			uniqid = existingTravelOptions
		}

		this._vm.$set(state.travel, uniqid, {
			...payload,
			event    : state.current.event,
			attendee : state.current.attendee
		})

		this._vm.$set(state.events[state.current.event], 'travel', uniqid)
	},
	resetDiscounts (state) {
		this._vm.$set(state, 'discounts', {})
	},
	removeDiscountCode (state, { id, uniqid }) {
		const { discounts } = state
		const index = discounts[uniqid].discounts.findIndex(d => d.id === id)
		if (-1 !== index) {
			this._vm.$delete(state.discounts[uniqid].discounts, index)
		}

		if (!discounts[uniqid].discounts.length) {
			this._vm.$delete(state.events[discounts[uniqid].event], 'discounts')
			this._vm.$delete(state.discounts, uniqid)
		}
	},
	addDiscounts (state, payload) {
		let { uniqid } = payload,
			existingDiscounts = false

		delete payload.uniqid

		// First look to see if the currently selected event already has discounts.
		Object.keys(state.discounts).forEach(_uniqid => {
			const d = state.discounts[_uniqid]
			if (d.event === payload.event && d.attendee === payload.attendee) {
				existingDiscounts = _uniqid
			}
		})

		if (existingDiscounts) {
			// If this discount is already set, don't set it again.
			payload.discounts = payload.discounts.filter(d => -1 === state.discounts[existingDiscounts].discounts.findIndex(dis => dis.id === d.id))

			// If we have no more discounts, return early.
			if (!payload.discounts.length) {
				return
			}

			payload.discounts = payload.discounts.concat(state.discounts[existingDiscounts].discounts)

			uniqid = existingDiscounts
		}

		payload.discounts.forEach(discount => {
			discount.discount = uniqid
		})

		this._vm.$set(state.discounts, uniqid, {
			...payload
		})

		this._vm.$set(state.events[payload.event], 'discounts', uniqid)
	},
	updateDiscountAmount (state, { uniqid, id, amount }) {
		if (state.discounts[uniqid]) {
			const index = state.discounts[uniqid].discounts.findIndex(d => d.id === id)
			if (-1 !== index) {
				this._vm.$set(state.discounts[uniqid].discounts[index], 'discount_amount', amount)
			}
		}
	},
	cancel (state) {
		this._vm.$set(state, 'registrationKey', null)
		this._vm.$set(state, 'account', null)
		this._vm.$set(state, 'attendees', {})
		this._vm.$set(state, 'events', {})
		this._vm.$set(state, 'financial', {})
		this._vm.$set(state, 'questions', {})
		this._vm.$set(state, 'travel', {})
		this._vm.$set(state, 'savedRegistration', [])
		this._vm.$set(state, 'privateEvent', null)
		this._vm.$set(state, 'privateAttendeeType', null)
	},
	resetCurrent (state, payload = {}) {
		const reset = {}
		const { current } = state
		Object.keys(current).forEach(key => {
			reset[key] = payload[key] ? payload[key] : null
		})

		this._vm.$set(state, 'current', reset)
	},
	setGateway (state, payload) {
		state.payment.gateway = payload
	},
	setPaymentToken (state, payload) {
		state.payment.token = payload
	},
	setPaymentMethod (state, payload) {
		state.payment.card = payload
	},
	setCheckNumber (state, payload) {
		state.payment.checkNumber = payload
	},
	setAmounts (state, payload) {
		state.amounts = payload
	},
	setDonations (state, payload) {
		state.donations = payload
	},
	setWaitlistStatus (state, events) {
		Object.keys(events).forEach(eventId => {
			const event = events[eventId]
			this._vm.$set(state.events[eventId], 'waitlist', event.waitlist)
		})
	},
	setSavedRegistration (state, payload) {
		state.savedRegistration = payload.registrations
		state.donations         = payload.donations || []
	},
	updateSavedRegistration (state, { id, registration }) {
		state.savedRegistration.forEach((r, registrationIndex) => {
			if (r.id === id) {
				this._vm.$set(state.savedRegistration, registrationIndex, registration)
			}
		})
	},
	addPrivateEvent (state, eventId) {
		state.privateEvent = eventId
	},
	addPrivateAttendeeType (state, attendeeTypeId) {
		state.privateAttendeeType = attendeeTypeId
	},
	removePrivateEvent (state) {
		state.privateEvent = null
	},
	removePrivateAttendeeType (state) {
		state.privateAttendeeType = null
	},
	overrideWaitlist (state) {
		this._vm.$set(state.events[state.current.event], 'waitlistOverride', true)
	}
}