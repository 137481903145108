import router from '@/registration/router'
import md5 from 'md5'

export default {
	login ({ commit, rootState }, payload) {
		return new Promise((resolve, reject) => {
			this._vm.$request
				.post(`${this._vm.$constants.BASE_API_URL}/login/`)
				.send(payload)
				.then(response => {
					this._vm.$auth.set(response.body.token, payload.remember)

					// Check if the user is an admin.
					if (response.body.admin === md5(response.body.token + process.env.VUE_APP_ADMIN)) {
						this._vm.$auth.set(response.body.token + process.env.VUE_APP_ADMIN, payload.remember, true)
					}

					this._vm.$http.set('Authorization', `Bearer ${response.body.token}`)
					commit('account/setCurrent', response.body.account, { root: true })
					commit('attendees/addAll', response.body.attendees, { root: true })

					let name     = 'home'
					const params = {}
					if (rootState.route.params.event_id) {
						name = 'register-event'
						params.event_id = rootState.route.params.event_id
					}

					// Redirect to the home page or registration.
					router.push({ name, params })
					resolve()
				})
				.catch(error => error.response ? reject(error.response.body.message) : reject(error))
		})
	},
	resetPassword (context, email) {
		return this._vm.$api({
			method : 'post',
			url    : '/account/password-reset/',
			send   : { email }
		})
	},
	actuallyResetPassword (context, payload) {
		return this._vm.$api({
			method : 'post',
			url    : '/account/reset-password/',
			send   : payload,
			then   : response => {
				this._vm.$auth.set(response.body.token, true)

				// Check if the user is an admin.
				if (response.body.admin === md5(response.body.token + process.env.VUE_APP_ADMIN)) {
					this._vm.$auth.set(response.body.token + process.env.VUE_APP_ADMIN, true, true)
				}

				this._vm.$http.set('Authorization', `Bearer ${response.body.token}`)
			},
			routerRedirect : () => ({ name: 'home' })
		})
	}
}