export const Scroll = {
	methods : {
		scrollTo (pos = 0) {
			window.scroll({
				top      : pos,
				behavior : 'smooth'
			})
		},
		scroll (destination, duration = 1000, easing = 'easeInOutQuad', callback) {
			const easings = {
				linear (t) {
					return t
				},
				easeInQuad (t) {
					return t * t
				},
				easeOutQuad (t) {
					return t * (2 - t)
				},
				easeInOutQuad (t) {
					return 0.5 > t ? 2 * t * t : -1 + (4 - 2 * t) * t
				},
				easeInCubic (t) {
					return t * t * t
				},
				easeOutCubic (t) {
					return (--t) * t * t + 1
				},
				easeInOutCubic (t) {
					return 0.5 > t ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
				},
				easeInQuart (t) {
					return t * t * t * t
				},
				easeOutQuart (t) {
					return 1 - (--t) * t * t * t
				},
				easeInOutQuart (t) {
					return 0.5 > t ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t
				},
				easeInQuint (t) {
					return t * t * t * t * t
				},
				easeOutQuint (t) {
					return 1 + (--t) * t * t * t * t
				},
				easeInOutQuint (t) {
					return 0.5 > t ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t
				}
			}

			const start     = window.pageYOffset
			const startTime = 'now' in window.performance ? performance.now() : new Date().getTime()

			const documentHeight            = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight)
			const windowHeight              = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight
			const destinationOffset         = 'number' === typeof destination ? destination : destination.offsetTop
			const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset)

			if (false === 'requestAnimationFrame' in window) {
				window.scroll(0, destinationOffsetToScroll)
				if ('function' === typeof callback) {
					callback()
				}
				return
			}

			function scrollIt () {
				const now = 'now' in window.performance ? performance.now() : new Date().getTime()
				const time = Math.min(1, ((now - startTime) / duration))
				const timeFunction = easings[easing](time)
				window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start))

				if (window.pageYOffset === destinationOffsetToScroll) {
					if ('function' === typeof callback) {
						callback()
					}
					return
				}

				requestAnimationFrame(scrollIt)
			}

			scrollIt()
		}
	}
}