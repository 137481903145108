import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/reports',
		name      : 'admin-reports',
		component : loadView('reports/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Reports'
		}
	},
	{
		path      : '/reports/new',
		name      : 'admin-report-new',
		component : loadView('reports/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Report'
		}
	},
	{
		path      : '/reports/archives',
		name      : 'admin-reports-archives',
		component : loadView('reports/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Report Archives'
		}
	},
	{
		path      : '/reports/:id',
		name      : 'admin-report-edit',
		component : loadView('reports/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Report'
		}
	},
	{
		path      : '/report-types',
		name      : 'admin-report-types',
		component : loadView('report-types/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Report Types'
		}
	},
	{
		path      : '/report-types/new',
		name      : 'admin-report-type-new',
		component : loadView('report-types/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Report Type'
		}
	},
	{
		path      : '/report-types/archives',
		name      : 'admin-report-types-archives',
		component : loadView('report-types/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Report Type Archives'
		}
	},
	{
		path      : '/report-types/:id',
		name      : 'admin-report-type-edit',
		component : loadView('report-types/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Report Type'
		}
	}
]