import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/accounts',
		name      : 'admin-accounts',
		component : loadView('accounts/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Accounts'
		}
	},
	{
		path      : '/accounts/new',
		name      : 'admin-account-new',
		component : loadView('accounts/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Account'
		}
	},
	{
		path      : '/accounts/:id',
		name      : 'admin-account-edit',
		component : loadView('accounts/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Account'
		}
	}
]