import { CREATE, CURRENT } from './defaults'
import * as m from '@/admin/store/commonMutations'

const {
	setPayload,
	setPagination,
	setCurrent,
	...mutations
} = m

export default {
	...mutations,
	resetNew (state) {
		setPayload(this._vm, state, 'newModel', { ...CREATE })
	},
	resetCurrent (state) {
		setPayload(this._vm, state, 'current', { ...CURRENT })
	},
	setPagination (state, payload) {
		setPagination(this._vm, state, payload)
	},
	setCurrent (state, payload) {
		if (payload.amount) {
			payload.amount = this._vm.$options.filters.currency(payload.amount)
		}
		if (!payload.description) {
			payload.description = ''
		}

		setCurrent(state, payload)
	}
}