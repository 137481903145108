import router from '@/admin/router'

export function fastFetchAll (vm, { commit }, data) {
	return vm.$api({
		method : 'get',
		url    : `/admin/${data.routeAll}/all/`,
		then   : response => commit('setAllModels', response.body)
	})
}

export function fetchLogs (vm, { commit }, data, payload) {
	return vm.$api({
		method : 'get',
		url    : `/admin/${data.route}/${payload}/log/`,
		then   : response => commit('setLogs', response.body)
	})
}

export function fetchAll (vm, { commit, rootState, state }, data, payload = {}) {
	let params = []

	if (rootState.route.query.page) {
		params.push(`offset=${(rootState.route.query.page - 1) * rootState.adminSettings.per_page[`${data.slug}s`]}`)
	} else if (payload.currentPage) {
		params.push(`offset=${(payload.currentPage - 1) * rootState.adminSettings.per_page[`${data.slug}s`]}`)
	}

	if (payload.searchTerm || state.localSearch) {
		params.push(`term=${payload.searchTerm || state.localSearch}`)
	}

	params.push(`limit=${rootState.adminSettings.per_page[`${data.slug}s`]}`)
	params.push(`sort=${rootState.adminSettings.sort[`${data.slug}s`]}`)

	if (params.length) {
		params = '?' + params.join('&')
	}

	return vm.$api({
		method : 'get',
		url    : `/admin/${data.routeAll}/${params}`,
		then   : response => {
			commit('setModels', response.body[`${data.slug}s`])
			commit('setPagination', response.body.totals)
			commit('setArchives', !!response.body.totals.archives)
		}
	})
}

export function fetchAllArchives (vm, { commit, rootState, state }, data, payload = {}) {
	let params = []

	if (rootState.route.query.page) {
		params.push(`offset=${(rootState.route.query.page - 1) * rootState.adminSettings.per_page[`${data.slug}s`]}`)
	} else if (payload.currentPage) {
		params.push(`offset=${(payload.currentPage - 1) * rootState.adminSettings.per_page[`${data.slug}s`]}`)
	}

	if (payload.searchTerm || state.localSearch) {
		params.push(`term=${payload.searchTerm || state.localSearch}`)
	}

	params.push(`limit=${rootState.adminSettings.per_page[`${data.slug}s`]}`)
	params.push(`sort=${rootState.adminSettings.sort[`${data.slug}Archives`]}`)

	if (params.length) {
		params = '?' + params.join('&')
	}

	return vm.$api({
		method : 'get',
		url    : `/admin/${data.routeAll}/archives/${params}`,
		then   : response => {
			commit('setModelArchives', response.body[`${data.slug}Archives`])
			commit('setPagination', response.body.totals)
		}
	})
}

export function fetch (vm, { commit }, data, payload) {
	return vm.$api({
		method : 'get',
		url    : `/admin/${data.route}/${payload}/`,
		then   : response => commit('setCurrent', response.body)
	})
}

export function createModel (vm, { state, commit }, data, payload = null) {
	return vm.$api({
		method : 'post',
		send   : payload || state.newModel,
		url    : `/admin/${data.route}/`,
		then   : response => {
			commit('resetNew')
			commit('setCurrent', response.body)
		},
		routerRedirect : response => ({ name: `admin-${data.routeSlug}-edit`, params: { id: response.body.id } }),
		notification   : {
			type    : 'success',
			message : `${data.label} successfully created!`,
			icon    : 'fas fa-bell'
		}
	})
}

export function updateModel (vm, { state, commit, dispatch }, data, payload = null) {
	return vm.$api({
		method : 'put',
		send   : payload || state.current,
		url    : `/admin/${data.route}/${state.current.id}/`,
		then   : response => {
			commit('setCurrent', response.body)
			return dispatch('fetchLogs', state.current.id)
		},
		notification : {
			type    : 'success',
			message : `${data.label} successfully updated!`,
			icon    : 'fas fa-bell'
		}
	})
}

export function duplicateModel (vm, { state, commit, dispatch }, data, payload) {
	return vm.$api({
		method : 'post',
		url    : `/admin/${data.route}/${payload}/duplicate/`,
		then   : response => {
			window.open(window.location.origin + '/' + router.resolve({ name: `admin-${data.routeSlug}-edit`, params: { id: response.body.id } }).href)
			commit('loading', false, { root: true })
			commit('setCurrent', response.body)
			return dispatch('fetchLogs', state.current.id)
		},
		// routerRedirect : response => ({ name: `admin-${data.routeSlug}-edit`, params: { id: response.body.id } }),
		notification : {
			type    : 'success',
			message : `${data.label} successfully duplicated!`,
			icon    : 'fas fa-bell'
		}
	})
}

export function restoreModel (vm, { dispatch, rootState }, data, payload) {
	const currentPage = rootState.route.query.page || 1
	return vm.$api({
		method : 'post',
		url    : `/admin/${data.route}/${payload}/restore/`,
		then   : () => {
			return dispatch('fetchAllArchives', currentPage)
		},
		notification : {
			type    : 'success',
			message : `${data.label} successfully restored!`,
			icon    : 'fas fa-bell'
		}
	})
}

export function archiveModel (vm, { dispatch, rootState }, data, payload) {
	const currentPage = rootState.route.query.page || 1
	return vm.$api({
		method : 'post',
		url    : `/admin/${data.route}/${payload}/archive/`,
		then   : () => {
			return dispatch('fetchAll', currentPage)
		},
		notification : {
			type    : 'success',
			message : `${data.label} successfully archived!`,
			icon    : 'fas fa-bell'
		}
	})
}

export function archiveModels (vm, { dispatch, rootState }, data, payload) {
	const currentPage = rootState.route.query.page || 1
	return vm.$api({
		method : 'post',
		url    : `/admin/${data.routeAll}/archive/`,
		send   : payload,
		then   : () => {
			return dispatch('fetchAll', currentPage)
		},
		notification : {
			type    : 'success',
			message : `${payload.length} ${data.label}s successfully archived!`,
			icon    : 'fas fa-bell'
		}
	})
}

export function restoreModels (vm, { dispatch, rootState }, data, payload) {
	const currentPage = rootState.route.query.page || 1
	return vm.$api({
		method : 'post',
		url    : `/admin/${data.routeAll}/restore/`,
		send   : payload,
		then   : () => {
			return dispatch('fetchAllArchives', currentPage)
		},
		notification : {
			type    : 'success',
			message : `${payload.length} ${data.label}s successfully restored!`,
			icon    : 'fas fa-bell'
		}
	})
}

export function deleteModel (vm, context, data, payload) {
	return vm.$api({
		method       : 'delete',
		url          : `/admin/${data.route}/${payload}/`,
		notification : {
			type    : 'success',
			message : `${data.label} successfully deleted!`,
			icon    : 'fas fa-bell'
		}
	})
}