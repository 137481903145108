export default {
	registrationKey : null,
	current         : {
		attendee     : null,
		attendeeType : null,
		event        : null,
		eventType    : null
	},
	account             : null,
	attendees           : {},
	events              : {},
	financial           : {},
	questions           : {},
	travel              : {},
	discounts           : {},
	savedRegistration   : [],
	donations           : [],
	privateEvent        : null,
	privateAttendeeType : null,
	uploadedData        : {},
	importStage         : 'attendee',
	importCount         : 0,
	importTotal         : 0
}