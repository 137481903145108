import { CURRENT } from './defaults'
export default {
	logs          : [],
	models        : [],
	current       : { ...CURRENT },
	emails        : [],
	travelOptions : [],
	searchResults : [],
	pagination    : {
		page  : 1,
		total : null
	}
}