import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/events',
		name      : 'admin-events',
		component : loadView('events/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Events'
		}
	},
	{
		path      : '/events/new',
		name      : 'admin-event-new',
		component : loadView('events/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Event'
		}
	},
	{
		path      : '/events/archives',
		name      : 'admin-events-archives',
		component : loadView('events/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Event Archives'
		}
	},
	{
		path      : '/events/:id',
		name      : 'admin-event-edit',
		component : loadView('events/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Event'
		}
	},
	{
		path      : '/event-types',
		name      : 'admin-event-types',
		component : loadView('event-types/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Event Types'
		}
	},
	{
		path      : '/event-types/new',
		name      : 'admin-event-type-new',
		component : loadView('event-types/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Event Type'
		}
	},
	{
		path      : '/event-types/archives',
		name      : 'admin-event-types-archives',
		component : loadView('event-types/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Event Type Archives'
		}
	},
	{
		path      : '/event-types/:id',
		name      : 'admin-event-type-edit',
		component : loadView('event-types/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Event Type'
		}
	}
]