import {
	NoAuth
} from '@/middleware'
import { Auth, Logout } from '@/registration/middleware'
import { loadView } from './load'
import account from './account'
import attendees from './attendees'
import register from './register'
import events from './events'
import payments from './payments'

/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
	{
		path      : '/',
		name      : 'home',
		component : loadView('Home'),
		meta      : {
			middleware : [ Auth ]
		}
	},
	{
		path : '/login',
		name : 'login',
		meta : {
			middleware : [ NoAuth ],
			label      : 'Login'
		},
		component : loadView('Login')
	},
	{
		path : '/login/:event_id',
		name : 'login-event',
		meta : {
			middleware : [ NoAuth ],
			label      : 'Login'
		},
		component : loadView('Login')
	},
	{
		path : '/password-reset',
		name : 'password-reset',
		meta : {
			middleware : [ NoAuth ],
			label      : 'Password Reset'
		},
		component : loadView('PasswordReset')
	},
	{
		path : '/events/:id',
		name : 'attendees-events',
		meta : {
			middleware : [ Auth ],
			label      : 'Events'
		},
		component : loadView('events/Attendee')
	},
	{
		path : '/logout',
		name : 'logout',
		meta : {
			middleware : [ Logout ],
			label      : 'Log Out'
		},
		component : loadView('LogOut')
	},
	...account,
	...attendees,
	...register,
	...events,
	...payments,
	{
		path     : '*',
		redirect : '/'
	}
]