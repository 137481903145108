export default {
	notifications : [],
	settings      : {
		overlap         : false,
		verticalAlign   : 'top',
		horizontalAlign : 'right',
		type            : 'info',
		timeout         : 5000,
		closeOnClick    : true,
		showClose       : true
	}
}