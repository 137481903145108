import * as a from '@/admin/store/commonActions'

const data = {
	route     : 'donation',
	routeAll  : 'donations',
	routeSlug : 'donation',
	label     : 'Donation',
	class     : 'Donation',
	slug      : 'donation'
}

export default {
	fastFetchAll (context) {
		return a.fastFetchAll(this._vm, context, data)
	},
	fetchLogs (context, payload) {
		return a.fetchLogs(this._vm, context, data, payload)
	},
	fetchAll (context, payload) {
		return a.fetchAll(this._vm, context, data, payload)
	},
	fetchAllArchives (context, payload) {
		return a.fetchAllArchives(this._vm, context, data, payload)
	},
	fetch (context, payload) {
		return a.fetch(this._vm, context, data, payload)
	},
	createModel (context) {
		return a.createModel(this._vm, context, data)
	},
	updateModel (context) {
		return a.updateModel(this._vm, context, data)
	},
	duplicateModel (context, payload) {
		return a.duplicateModel(this._vm, context, data, payload)
	},
	restoreModel (context, payload) {
		return a.restoreModel(this._vm, context, data, payload)
	},
	archiveModel (context, payload) {
		return a.archiveModel(this._vm, context, data, payload)
	},
	archiveModels (context, payload) {
		return a.archiveModels(this._vm, context, data, payload)
	},
	restoreModels (context, payload) {
		return a.restoreModels(this._vm, context, data, payload)
	},
	deleteModel (context, payload) {
		return a.deleteModel(this._vm, context, data, payload)
	},
	fetchAllDonations ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/donations/?admin=true',
			then   : response => commit('addAll', response.body)
		})
	}
}