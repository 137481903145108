import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/registrations',
		name      : 'admin-registrations',
		component : loadView('registrations/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Registrations'
		}
	},
	{
		path      : '/registrations/new',
		name      : 'admin-registration-new',
		component : loadView('registrations/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Registration'
		}
	},
	{
		path      : '/registrations/:id',
		name      : 'admin-registration-edit',
		component : loadView('registrations/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Registration'
		}
	}
]