import { CREATE, CURRENT } from './defaults'
import * as m from '@/admin/store/commonMutations'

const {
	setPayload,
	setPagination,
	setCurrent,
	...mutations
} = m

export default {
	...mutations,
	resetNew (state) {
		setPayload(this._vm, state, 'newModel', { ...CREATE })
	},
	resetCurrent (state) {
		setPayload(this._vm, state, 'current', { ...CURRENT })
	},
	setPagination (state, payload) {
		setPagination(this._vm, state, payload)
	},
	setCurrent (state, payload) {
		payload.report_types = []
		if (payload.reportTypes.length) {
			payload.reportTypes.forEach(report => {
				payload.report_types.push(report.id)
			})
		}

		if (!payload.columns) {
			payload.columns = []
		}

		if (!payload.custom) {
			payload.custom = []
		}

		setCurrent(state, payload)
	},
	updatePermissions (state, { type, permissions }) {
		const deleteIndexes = []
		state.current.permissions.forEach((p, i) => {
			if (type === p.type) {
				deleteIndexes.push(i)
			}
		})

		for (var i = deleteIndexes.length - 1; 0 <= i; i--) {
			this._vm.$delete(state.current.permissions, deleteIndexes[i])
		}

		const allPermissions = state.current.permissions.concat(permissions)
		this._vm.$set(state.current, 'permissions', allPermissions)
	},
	updateFields (state, payload) {
		this._vm.$set(state.current, 'columns', payload)
	},
	updateFilters (state, { type, data }) {
		this._vm.$set(state.current.filters, type, data)
	},
	updateCustomFilters (state, payload) {
		this._vm.$set(state.current, 'custom', payload)
	},
	toggleReportPane (state) {
		state.showReportPane = !state.showReportPane
	},
	setCurrentResults (state, payload) {
		state.reportResults = payload
	}
}