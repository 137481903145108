export const CURRENT = {
	id         : null,
	account_id : null,
	attendee   : {
		attendee_id : null,
		first_name  : null,
		last_name   : null
	},
	discounts        : [],
	discounts_amount : 0,
	event            : {
		attendee_type_id   : null,
		attendee_type_name : null,
		end_date           : null,
		event_id           : null,
		name               : null,
		start_date         : null
	},
	financial_options : [],
	key               : null,
	notes             : null,
	payments_amount   : 0,
	questions         : [],
	refunds_amount    : 0,
	status            : null,
	subtotal_amount   : 0,
	transactions      : [],
	travel_options    : []
}