export default function Auth ({ next, router }) {
	const token = router.app.$auth.get(true)
	const name  = 'admin-login'

	if (!token) {
		return router.push({ name })
	}

	// Detecting a false admin.
	if (token && -1 === token.indexOf(process.env.VUE_APP_ADMIN)) {
		router.app.$auth.remove()
		router.app.$auth.remove(true)
		window.location = router.push({ name })
	}

	return next()
}