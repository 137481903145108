export default {
	getSettings ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/settings/',
			then   : response => {
				commit('setSettings', response.body)
				commit('settingsLoaded', true)
			}
		})
	}
}