export default {
	login (context, payload) {
		return this._vm.$api({
			method : 'post',
			send   : payload,
			url    : '/admin/login/',
			then   : response => {
				this._vm.$auth.set(response.body.token + process.env.VUE_APP_ADMIN, payload.remember, true)
				this._vm.$auth.set(response.body.token, payload.remember)
				this._vm.$http.set('Authorization', `Bearer ${response.body.token}`)
			},
			routerRedirect : () => ({ name: 'admin-dashboard' }),
			errorHandler   : error => {
				if (error.response && error.response.body.home) {
					window.location = this._vm.$url.redirect('/')
				}
			}
		})
	},
	resetPassword (context, email) {
		return this._vm.$api({
			method : 'post',
			send   : { email },
			url    : '/account/password-reset/admin/'
		})
	},
	actuallyResetPassword (context, payload) {
		return this._vm.$api({
			method : 'post',
			send   : payload,
			url    : '/account/reset-password/admin/',
			then   : response => {
				this._vm.$auth.set(response.body.token + process.env.VUE_APP_ADMIN, true, true)
				this._vm.$auth.set(response.body.token, true)
				this._vm.$http.set('Authorization', `Bearer ${response.body.token}`)
			},
			routerRedirect : () => ({ name: 'admin-dashboard' }),
			errorHandler   : error => {
				if (error.response && error.response.body.home) {
					window.location = this._vm.$url.redirect('/')
				}
			}

		})
	}
}