// https://vuex.vuejs.org/en/state.html

export default {
	loading          : true,
	isAdmin          : true,
	inAdmin          : true,
	settingsLoaded   : false,
	showFiltersModal : false,
	showSortModal    : false,
	adminLoaded      : false,
	adminUser        : {},
	dueRegistrations : [],
	adminSettings    : {
		show_filter_hint : true,
		stats            : [],
		large_stats      : [
			{
				title      : 'State Class by Month',
				type       : 'months',
				eventTypes : [
					'5f12fe9b7fbbc',
					'5f12fe9b80a3d',
					'5f12fe9b807d2',
					'5f12fe9b7e3e0',
					'5f12fe9b7e6ac',
					'5f12fe9b7e159',
					'5f12fe9b7ded5'
				],
				attendeeTypes : [
					'5f12fe9b64ac7',
					'5f12fe9b64eb0'
				],
				count   : 0,
				percent : 0
			},
			{
				title      : 'State Class by Attendee Type',
				type       : 'attendee_type',
				eventTypes : [
					'5f12fe9b7fbbc',
					'5f12fe9b80a3d',
					'5f12fe9b807d2',
					'5f12fe9b7e3e0',
					'5f12fe9b7e6ac',
					'5f12fe9b7e159',
					'5f12fe9b7ded5'
				],
				attendeeTypes : [
					'5f12fe9b64ac7',
					'5f12fe9b64eb0'
				],
				count   : 0,
				percent : 0
			},
			{
				title      : 'Alumni Event by Month',
				type       : 'months',
				eventTypes : [
					'5f12fe9b7fbbc',
					'5f12fe9b80a3d',
					'5f12fe9b807d2',
					'5f12fe9b7e3e0',
					'5f12fe9b7e6ac',
					'5f12fe9b7e159',
					'5f12fe9b7ded5'
				],
				attendeeTypes : [
					'5f12fe9b64ac7',
					'5f12fe9b64eb0'
				],
				count   : 0,
				percent : 0
			},
			{
				title      : 'Alumni Event by Attendee Type',
				type       : 'attendee_type',
				eventTypes : [
					'5f12fe9b7fbbc',
					'5f12fe9b80a3d',
					'5f12fe9b807d2',
					'5f12fe9b7e3e0',
					'5f12fe9b7e6ac',
					'5f12fe9b7e159',
					'5f12fe9b7ded5'
				],
				attendeeTypes : [
					'5f12fe9b64ac7',
					'5f12fe9b64eb0'
				],
				count   : 0,
				percent : 0
			}
		],
		per_page : {
			accountFormFieldArchives       : 20,
			accountFormFields              : 20,
			accounts                       : 20,
			attendeeFormFieldArchives      : 20,
			attendeeFormFields             : 20,
			attendeeTypeArchives           : 20,
			attendeeTypes                  : 20,
			attendees                      : 20,
			calendar                       : 20,
			discountArchives               : 20,
			discounts                      : 20,
			donationArchives               : 20,
			donations                      : 20,
			emailTemplateArchives          : 20,
			emailTemplateWrapperArchives   : 20,
			emailTemplateWrappers          : 20,
			emailTemplates                 : 20,
			eventArchives                  : 20,
			eventTypeArchives              : 20,
			eventTypes                     : 20,
			events                         : 20,
			financialOptionArchives        : 20,
			financialOptionGroupArchives   : 20,
			financialOptionGroups          : 20,
			financialOptions               : 20,
			questionArchives               : 20,
			questions                      : 20,
			registrations                  : 20,
			reportArchives                 : 20,
			reportTypeArchives             : 20,
			reportTypes                    : 20,
			reports                        : 20,
			travelOptionTypeArchives       : 20,
			travelOptionTypeOptionArchives : 20,
			travelOptionTypeOptions        : 20,
			travelOptionTypes              : 20
		},
		filters : {
			accountFormFieldArchives : {
				enabled     : '',
				required    : '',
				conditional : ''
			},
			accountFormFields : {
				enabled     : '',
				required    : '',
				conditional : ''
			},
			accounts : {
				country    : '',
				state      : '',
				role       : '',
				restricted : false
			},
			attendeeFormFieldArchives : {
				enabled     : '',
				required    : '',
				conditional : ''
			},
			attendeeFormFields : {
				enabled     : '',
				required    : '',
				conditional : ''
			},
			attendeeTypeArchives : {
				enabled : ''
			},
			attendeeTypes : {
				enabled : ''
			},
			attendees        : {},
			calendar         : {},
			discountArchives : {
				enabled  : '',
				required : '',
				editable : '',
				type     : []
			},
			discounts : {
				enabled  : '',
				required : '',
				editable : '',
				type     : []
			},
			donationArchives             : {},
			donations                    : {},
			emailTemplateArchives        : {},
			emailTemplateWrapperArchives : {},
			emailTemplateWrappers        : {},
			emailTemplates               : {},
			eventArchives                : {
				eventTypes : []
			},
			eventTypeArchives : {
				enabled : ''
			},
			eventTypes : {
				enabled : ''
			},
			events : {
				eventTypes : []
			},
			financialOptionArchives : {
				enabled : '',
				type    : []
			},
			financialOptionGroupArchives : {
				required : '',
				type     : []
			},
			financialOptionGroups : {
				required : '',
				type     : []
			},
			financialOptions : {
				enabled : '',
				type    : []
			},
			questionArchives : {
				enabled  : '',
				required : '',
				editable : '',
				type     : []
			},
			questions : {
				enabled  : '',
				required : '',
				editable : '',
				type     : []
			},
			registrations : {
				events           : [],
				attendeeTypes    : [],
				questions        : [],
				financialOptions : [],
				travelOptions    : [],
				status           : [],
				hasBalance       : false
			},
			reportArchives : {
				reportTypes : []
			},
			reportTypeArchives : {
				enabled : ''
			},
			reportTypes : {
				enabled : ''
			},
			reports : {
				reportTypes : []
			},
			travelOptionTypeArchives       : {},
			travelOptionTypeOptionArchives : {},
			travelOptionTypeOptions        : {},
			travelOptionTypes              : {}
		},
		sort : {
			accountFormFieldArchives       : 'created-desc',
			accountFormFields              : 'created-desc',
			accounts                       : 'created-desc',
			attendeeFormFieldArchives      : 'created-desc',
			attendeeFormFields             : 'created-desc',
			attendeeTypeArchives           : 'created-desc',
			attendeeTypes                  : 'created-desc',
			attendees                      : 'created-desc',
			calendar                       : 'created-desc',
			discountArchives               : 'created-desc',
			discounts                      : 'created-desc',
			donationArchives               : 'created-desc',
			donations                      : 'created-desc',
			emailTemplateArchives          : 'created-desc',
			emailTemplateWrapperArchives   : 'created-desc',
			emailTemplateWrappers          : 'created-desc',
			emailTemplates                 : 'created-desc',
			eventArchives                  : 'name-asc',
			eventTypeArchives              : 'created-desc',
			eventTypes                     : 'created-desc',
			events                         : 'name-asc',
			financialOptionArchives        : 'created-desc',
			financialOptionGroupArchives   : 'created-desc',
			financialOptionGroups          : 'created-desc',
			financialOptions               : 'created-desc',
			questionArchives               : 'created-desc',
			questions                      : 'created-desc',
			registrations                  : 'created-desc',
			reportArchives                 : 'created-desc',
			reportTypeArchives             : 'created-desc',
			reportTypes                    : 'created-desc',
			reports                        : 'created-desc',
			travelOptionTypeArchives       : 'created-desc',
			travelOptionTypeOptionArchives : 'created-desc',
			travelOptionTypeOptions        : 'created-desc',
			travelOptionTypes              : 'created-desc'
		}
	}
}