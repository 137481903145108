import { CURRENT } from './defaults'
import * as m from '@/admin/store/commonMutations'

const {
	setPayload,
	setPagination,
	...mutations
} = m

export default {
	...mutations,
	resetCurrent (state) {
		setPayload(this._vm, state, 'current', { ...CURRENT })
	},
	setPagination (state, payload) {
		setPagination(this._vm, state, payload)
	},
	setEmails (state, payload) {
		state.emails = payload
	},
	updateAttendeeType (state, { attendeeTypeId, attendeeTypeName }) {
		this._vm.$set(state.current.event, 'attendee_type_id', attendeeTypeId)
		this._vm.$set(state.current.event, 'attendee_type_name', attendeeTypeName)
	},
	setRegistrationSearch (state, payload) {
		state.searchResults = payload
	},
	updateQuestions (state, payload) {
		const questions = []
		Object.keys(payload).forEach(id => {
			const question = payload[id]

			// Map prettyValue to pretty_value
			question.pretty_value = question.prettyValue

			// Map the id to question_id
			question.question_id = id

			// Remove any unnecessary parameters.
			delete question.id
			delete question.prettyValue

			// Add it to the array.
			questions.push(question)
		})

		this._vm.$set(state.current, 'questions', questions)
	},
	updateTransaction (state, transaction) {
		const { current } = state

		const index = current.transactions.findIndex(t => t.transaction_id === transaction.transaction_id)
		if (-1 !== index) {
			this._vm.$set(state.current.transactions, index, transaction)
		}
	},
	toggleDisableBilling (state, payload) {
		state.current.disable_billing = payload
	},
	addAllTravelOptions (state, payload) {
		state.travelOptions = payload.travel
	}
}