import Vue from 'vue'

// Lib imports
import {
	amounts,
	auth,
	constants,
	conditions,
	discounts,
	eventDates,
	helpers,
	storage,
	url,
	uniqid,
	validate
} from '@/utils'

import {
	camelCase,
	cloneDeep,
	mergeWith,
	snakeCase
} from 'lodash'

import moment from 'moment-timezone'
import superagent from 'superagent'
import { v4 as uuidv4 } from 'uuid'
import clonedeep from 'lodash.clonedeep'
import money from './money'
import Percent from './percent'

import adminStore from '@/admin/store'
import registrationStore from '@/registration/store'

import adminRouter from '@/admin/router'
import registrationRouter from '@/registration/router'

import VueClipboard from './vue-clipboard'

Vue.use(VueClipboard)

// Register directive v-money and component <money>.
Vue.use(money, {
	prefix    : '$',
	precision : 2
})

// Register directive v-percent and component <percent>.
Vue.use(Percent)

// Set up a redirect here.
const http = superagent.agent()
	.use(req => {
		req.on('response', response => {
			if (401 === response.status) {
				auth.remove()
				auth.remove(true)
				if (response.body.admin) {
					if ('admin-login' !== adminStore.state.route.name) {
						return adminRouter.push({ name: 'admin-login' })
					}
				} else {
					window.location = url.redirect('/login')
				}
			} else if (403 === response.status) {
				if (response.body.admin) {
					return adminRouter.push({ name: 'admin-dashboard', query: { permission: 'denied', message: response.body.message } })
				}
			}
		})
	})
	.set('Authorization', `Bearer ${auth.get()}`)

const request = ({ url, method, send, attach = {}, then = () => {}, routerRedirect, notification = {}, errorHandler = () => {} }) => new Promise((resolve, reject) => {
	const isAdmin = !!adminRouter.app
	const router  = isAdmin ? adminRouter : registrationRouter
	const store   = isAdmin ? adminStore : registrationStore
	http[method](`${constants.BASE_API_URL}${url}`)
		.send(send)
		.attach(attach.name, attach.file, attach.filename)
		.then(response => {
			then(response, resolve, reject)
			if (routerRedirect) {
				router.push(routerRedirect(response))
			}
			if (notification.message) {
				store.commit('notifications/addNotification', {
					notification,
					settings : store.state.notifications.settings
				})
			}
			return response
		})
		.then(response => resolve(response))
		.catch(error => {
			errorHandler(error, reject)
			throw error
		})
		.catch(error => {
			if (error.response && error.response.body && error.response.body.message) {
				return reject(error.response.body.message)
			}

			reject(error)
		})
})

Vue.prototype.$amounts       = amounts
Vue.prototype.$api           = request
Vue.prototype.$auth          = auth
Vue.prototype.$bus           = new Vue()
Vue.prototype.$conditions    = conditions
Vue.prototype.$constants     = constants
Vue.prototype.$cloneDeep     = clonedeep
Vue.prototype.$discount      = discounts
Vue.prototype.$eventDates    = eventDates
Vue.prototype.$helpers       = helpers
Vue.prototype.$http          = http
Vue.prototype.$request       = superagent.agent()
Vue.prototype.$moment        = moment
Vue.prototype.$storage       = storage
Vue.prototype.$url           = url
Vue.prototype.$uniqid        = uniqid
Vue.prototype.$uuidv4        = uuidv4
Vue.prototype.$validate      = validate.validate
Vue.prototype.$validateAdmin = validate.validateAdmin
Vue.prototype.$lo            = {
	camelCase,
	cloneDeep,
	mergeWith,
	snakeCase
}