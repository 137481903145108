export const CREATE = {
	name               : null,
	description        : '',
	enabled            : 0,
	amount             : 0,
	user_defined_price : 0,
	deposit            : 0,
	shipped            : 0,
	display_quantity   : 0,
	default_quantity   : 1,
	inventory          : 0,
	type               : null
}

export const CURRENT = {
	id : null,
	...CREATE
}