export const accountformFields               = 'fas fa-check-square'
export const accounts                        = 'fas fa-users'
export const attendeeTypeArchives            = 'fas fa-history'
export const attendeeTypes                   = 'fas fa-user'
export const attendeeformFields              = 'fas fa-check-square'
export const attendees                       = 'fas fa-user'
export const autoBilling                     = 'fas fa-file-invoice-dollar'
export const calendar                        = 'fas fa-calendar-alt'
export const dashboard                       = 'fas fa-tachometer-alt'
export const discountArchives                = 'fas fa-history'
export const discounts                       = 'fas fa-tags'
export const donationArchives                = 'fas fa-history'
export const donations                       = 'fas fa-hand-holding-usd'
export const emailTemplateArchives           = 'fas fa-history'
export const emailTemplateWrappers           = 'fas fa-envelope'
export const emailTemplateWrappersArchives   = 'fas fa-history'
export const emailTemplates                  = 'fas fa-envelope'
export const eventArchives                   = 'fas fa-history'
export const eventTypeArchives               = 'fas fa-history'
export const eventTypes                      = 'fas fa-calendar-check'
export const events                          = 'fas fa-calendar-check'
export const financialOptionGroups           = 'fas fa-money-check-alt'
export const financialOptionGroupsArchives   = 'fas fa-history'
export const financialOptions                = 'fas fa-money-check-alt'
export const formFields                      = 'fas fa-check-square'
export const myAccount                       = 'fas fa-user-circle'
export const questionArchives                = 'fas fa-history'
export const questions                       = 'fas fa-question-circle'
export const registrations                   = 'fas fa-clipboard-list'
export const reportArchives                  = 'fas fa-history'
export const reportTypeArchives              = 'fas fa-history'
export const reportTypes                     = 'fas fa-chart-line'
export const reports                         = 'fas fa-chart-line'
export const settings                        = 'fas fa-cogs'
export const travelOptionTypeOptions         = 'fas fa-plane'
export const travelOptionTypeOptionsArchives = 'fas fa-history'
export const travelOptionTypes               = 'fas fa-plane'
export const travelOptionTypesArchives       = 'fas fa-history'