<template>
	<div>
		<component
			:is="'gateway-' + gateway"
		/>
	</div>
</template>

<script>
export default {
	props : {
		gateway : {
			type     : String,
			required : true
		}
	}
}
</script>