import router from '@/registration/router'

export default {
	fetch ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/payment/settings/',
			then   : response => {
				commit('addPublishableKey', response.body.publishableKey)
				commit('addSavedCards', response.body.paymentMethods)
			}
		})
	},
	submit ({ state }, billing) {
		const { paymentData, paymentAmounts, donations } = state

		paymentData.billing = billing

		const payload = {
			payment : paymentData,
			amounts : paymentAmounts,
			donations
		}

		return this._vm.$api({
			method : 'post',
			url    : '/payment/submit/',
			send   : payload,
			then   : response => {
				router.push({ name: 'payment-confirmation', params: { id: response.body.transaction_id } })
			}
		})
	},
	fetchByTransaction ({ commit, dispatch }, transactionId) {
		return this._vm.$api({
			method : 'get',
			url    : `/payment/registrations/${transactionId}/`,
			then   : response => {
				commit('setRegistrations', {
					transactionId,
					registrations : response.body
				})
				dispatch('account/fetch', null, { root: true })
				dispatch('attendees/fetchAll', null, { root: true })
			}
		})
	}
}