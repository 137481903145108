export default (prefix = '', moreEntropy) => {
	let retId,
		uniqidSeed = Math.floor(Math.random() * 0x75bcd15)
	const formatSeed = function (seed, reqWidth) {
		seed = parseInt(seed, 10).toString(16) // to hex str
		if (reqWidth < seed.length) {
			// so long we split
			return seed.slice(seed.length - reqWidth)
		}
		if (reqWidth > seed.length) {
			// so short we pad
			return Array(1 + (reqWidth - seed.length)).join('0') + seed
		}
		return seed
	}

	uniqidSeed++

	// start with prefix, add current milliseconds hex string
	retId = prefix
	retId += formatSeed(parseInt(new Date().getTime() / 1000, 10), 8)

	// add seed hex string
	retId += formatSeed(uniqidSeed, 5)

	if (moreEntropy) {
		// for more entropy we add a float lower to 10
		retId += (Math.random() * 10).toFixed(8).toString()
	}

	return retId
}