import router from '@/admin/router'

export default {
	search ({ commit, rootState }, { searchTerm, conditions }) {
		let conditionsParam = ''
		if (Object.keys(conditions).length) {
			Object.keys(conditions).forEach(key => {
				const condition = conditions[key]
				if (condition.length) {
					condition.forEach(c => {
						conditionsParam += `&${key}[]=${c}`
					})
				}
			})
		}

		return this._vm.$api({
			method : 'get',
			url    : `/admin/search/?term=${searchTerm}${conditionsParam}`,
			then   : response => {
				if (rootState.route.query.search !== searchTerm) {
					router.replace({
						name   : rootState.route.name,
						params : rootState.route.params,
						query  : {
							...rootState.route.query,
							search : searchTerm
						}
					})
				}
				commit('loading', false, { root: true })
				commit('setSearchResults', response.body)
			}
		})
	}
}