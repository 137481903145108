export default {
	settingsLoaded : false,
	account        : {
		accountCreateMessage      : '',
		cancelRegistrationMessage : '',
		welcomePageMessage        : ''
	},
	gateway : {
		checkGatewayConfirmation : ''
	},
	main         : {},
	registration : {
		saveLogoutConfirmationMessage   : '',
		cancelRegistrationMessage       : '',
		waitlistConfirmationMessage     : '',
		waitlistSummaryMessage          : '',
		accountRestrictionMessage       : '',
		attendeePageMessage             : '',
		eventPageMessage                : '',
		questionsPageMessage            : '',
		financialOptionsPageMessage     : '',
		travelOptionsPageMessage        : '',
		summaryPageMessage              : '',
		paymentPageMessage              : '',
		confirmationPageMessage         : '',
		depositDeadlineInteger          : 2,
		depositDeadlineUnit             : 'weeks',
		groupRegistrationUploadMessage  : '',
		groupRegistrationMappingMessage : ''
	},
	events : {
		eventTypes           : [],
		donationsEnabled     : true,
		donationsDescription : '',
		donations            : []
	}
}