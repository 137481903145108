export const CREATE = {
	label           : null,
	code            : null,
	enabled         : 1,
	stackable       : 1,
	auto_apply      : 0,
	apply_to        : 'individual_options',
	min_redemptions : null,
	max_redemptions : null,
	method          : 'amount',
	amount          : 0,
	percent         : 0,
	effective_dates : 'none',
	from_integer    : 0,
	from_unit       : 'days',
	to_integer      : 0,
	to_unit         : 'days',
	from_date       : null,
	to_date         : null,
	group           : 0,
	group_name      : null,
	group_contact   : null,
	group_phone     : null,
	group_notes     : null
}

export const CURRENT = {
	id : null,
	...CREATE
}