export default {
	toggleMinimize (state) {
		state.minimize = !state.minimize
	},
	minimize (state, payload) {
		state.minimize = payload
	},
	setHover (state, payload) {
		state.hovered = payload
	},
	hideSidebar (state) {
		state.show = false
	},
	showSidebar (state) {
		state.show = true
	}
}