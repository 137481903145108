import * as a from '@/admin/store/commonActions'

const data = {
	route     : 'event',
	routeAll  : 'events',
	routeSlug : 'event',
	label     : 'Event',
	class     : 'Event',
	slug      : 'event'
}

export default {
	fastFetchAll (context) {
		return a.fastFetchAll(this._vm, context, data)
	},
	fetchLogs (context, payload) {
		return a.fetchLogs(this._vm, context, data, payload)
	},
	fetchAll (context, payload) {
		return a.fetchAll(this._vm, context, data, payload)
	},
	fetchAllArchives (context, payload) {
		return a.fetchAllArchives(this._vm, context, data, payload)
	},
	fetch (context, payload) {
		return a.fetch(this._vm, context, data, payload)
	},
	createModel (context) {
		return a.createModel(this._vm, context, data)
	},
	updateModel (context) {
		const payload = { ...context.state.current }

		// Delete unnecessary items for the update.
		delete payload.eventTypes
		delete payload.attendeeTypes
		delete payload.counts
		delete payload.questions
		delete payload.financialOptions
		delete payload.financialOptionGroups
		delete payload.capacities

		return a.updateModel(this._vm, context, data, payload)
	},
	duplicateModel (context, payload) {
		return a.duplicateModel(this._vm, context, data, payload)
	},
	restoreModel (context, payload) {
		return a.restoreModel(this._vm, context, data, payload)
	},
	archiveModel (context, payload) {
		return a.archiveModel(this._vm, context, data, payload)
	},
	archiveModels (context, payload) {
		return a.archiveModels(this._vm, context, data, payload)
	},
	restoreModels (context, payload) {
		return a.restoreModels(this._vm, context, data, payload)
	},
	deleteModel (context, payload) {
		return a.deleteModel(this._vm, context, data, payload)
	},
	fetchTypes ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/admin/event/types/',
			then   : response => commit('setTypes', response.body)
		})
	},
	fetchRegistrationEvents ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/events/',
			then   : response => commit('setRegistrationEvents', response.body)
		})
	},
	search ({ commit }, searchTerm) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/event/search/?term=${searchTerm}`,
			then   : response => commit('setEventSearch', response.body)
		})
	}
}