/**
 * Simple throttle function that executes a passed function only once in the specified timeout.
 *
 * @since 1.0.0
 *
 * @param   {Function} handlerFunc The function that is called.
 * @param   {number}   timeout      The throttle interval.
 * @returns {void}
 */
export default (handlerFunc, timeout = 66) => {
	let resizeTimeout
	if (!resizeTimeout) {
		resizeTimeout = setTimeout(() => {
			resizeTimeout = null
			handlerFunc()
			// The actualResizeHandler will execute at a rate of 15fps
		}, timeout)
	}
}