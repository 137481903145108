export default {
	addAll (state, payload) {
		state.financial   = payload.financial   || []
		state.event       = payload.event       || []
		state.eventGroups = payload.eventGroups || []
		state.groups      = payload.groups      || []
	},
	setCurrentEvent (state, event) {
		state.currentEvent = event
	},
	setCurrentAttendeeType (state, attendeeType) {
		state.currentAttendeeType = attendeeType
	}
}