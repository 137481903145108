import { CREATE, CURRENT } from './defaults'
import * as m from '@/admin/store/commonMutations'

const {
	setPayload,
	setPagination,
	...mutations
} = m

export default {
	...mutations,
	resetNew (state) {
		setPayload(this._vm, state, 'newModel', { ...CREATE })
	},
	resetCurrent (state) {
		setPayload(this._vm, state, 'current', { ...CURRENT })
	},
	setPagination (state, payload) {
		setPagination(this._vm, state, payload)
	},
	setCurrentRegistrations (state, payload) {
		this._vm.$set(state.current, 'registrations', payload)
	},
	setCurrentEmails (state, payload) {
		this._vm.$set(state.current, 'emails', payload)
	},
	setFormFields (state, payload) {
		state.newModel.fields.all = payload
		state.allFormFields       = payload
	},
	setTypes (state, payload) {
		state.types = payload
	},
	setEmails (state, payload) {
		state.emails = payload
	}
}