import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/attendees',
		name      : 'admin-attendees',
		component : loadView('attendees/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Attendees'
		}
	},
	{
		path      : '/attendees/new',
		name      : 'admin-attendee-new',
		component : loadView('attendees/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Attendee'
		}
	},
	{
		path      : '/attendees/:id',
		name      : 'admin-attendee-edit',
		component : loadView('attendees/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Attendee'
		}
	},
	{
		path      : '/attendee-types',
		name      : 'admin-attendee-types',
		component : loadView('attendee-types/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Attendee Types'
		}
	},
	{
		path      : '/attendee-types/new',
		name      : 'admin-attendee-type-new',
		component : loadView('attendee-types/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Attendee Types'
		}
	},
	{
		path      : '/attendee-types/archives',
		name      : 'admin-attendee-types-archives',
		component : loadView('attendee-types/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Attendee Type Archives'
		}
	},
	{
		path      : '/attendee-types/:id',
		name      : 'admin-attendee-type-edit',
		component : loadView('attendee-types/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Attendee Type'
		}
	}
]