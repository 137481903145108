import * as a from '@/admin/store/commonActions'

const data = {
	route     : 'attendee',
	routeAll  : 'attendees',
	routeSlug : 'attendee',
	label     : 'Attendee',
	class     : 'Attendee',
	slug      : 'attendee'
}

export default {
	fetchLogs (context, payload) {
		return a.fetchLogs(this._vm, context, data, payload)
	},
	fetchAll (context, payload) {
		return a.fetchAll(this._vm, context, data, payload)
	},
	fetch (context, payload) {
		return a.fetch(this._vm, context, data, payload)
	},
	createModel (context, accountId) {
		const payload = {
			account_id : accountId,
			...context.state.newModel
		}
		return a.createModel(this._vm, context, data, payload)
	},
	updateModel (context) {
		const payload = { ...context.state.current }

		// We don't need these options.
		delete payload.id
		delete payload.account_id
		delete payload.created
		delete payload.updated

		return a.updateModel(this._vm, context, data, payload)
	},
	fetchEmails ({ commit }, attendeeId) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/attendee/${attendeeId}/emails/`,
			then   : response => commit('setEmails', response.body)
		})
	},
	fetchFormFields ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/admin/attendee/form-fields/',
			then   : response => commit('setFormFields', response.body)
		})
	},
	fetchTypes ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/admin/attendee/types/',
			then   : response => commit('setTypes', response.body)
		})
	},
	fetchRegistrations ({ commit }, attendeeId) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/attendee/${attendeeId}/registrations/`,
			then   : response => commit('setCurrentRegistrations', response.body)
		})
	},
	updateCurrentAdditional ({ commit, state }, payload) {
		return new Promise(resolve => {
			const { current } = state
			current.fields.values = {
				...current.fields.values,
				...payload
			}
			commit('setCurrent', {
				...current
			})

			resolve()
		})
	},
	updateNewAttendeeAdditional ({ commit, state }, payload) {
		return new Promise(resolve => {
			const { newModel } = state
			newModel.fields.values = {
				...newModel.fields.values,
				...payload
			}
			commit('setNewModel', {
				...newModel
			})

			resolve()
		})
	}
}