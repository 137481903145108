export default (fn, delay) => {
	var timeoutID = null
	return function () {
		clearTimeout(timeoutID)
		var args = arguments,
			that = this
		timeoutID = setTimeout(function () {
			fn.apply(that, args)
		}, delay)
	}
}