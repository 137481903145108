export default {
	fetchAll ({ commit }, { event, attendeeType }) {
		return this._vm.$api({
			method : 'get',
			url    : `/questions/?event=${event}&attendeeType=${attendeeType}`,
			then   : response => {
				commit('addAll', response.body)
				commit('setCurrentEvent', event)
				commit('setCurrentAttendeeType', attendeeType)
			}
		})
	}
}