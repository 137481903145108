import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/form-fields/account',
		name      : 'admin-account-form-fields',
		component : loadView('account-form-fields/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Account Form Fields'
		}
	},
	{
		path      : '/form-fields/account/new',
		name      : 'admin-account-form-field-new',
		component : loadView('account-form-fields/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Account Form Field'
		}
	},
	{
		path      : '/form-fields/account/archives',
		name      : 'admin-account-form-fields-archives',
		component : loadView('account-form-fields/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Account Form Field Archives'
		}
	},
	{
		path      : '/form-fields/account/:id',
		name      : 'admin-account-form-field-edit',
		component : loadView('account-form-fields/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Account Form Field'
		}
	},
	{
		path      : '/form-fields/attendee',
		name      : 'admin-attendee-form-fields',
		component : loadView('attendee-form-fields/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Attendee Form Fields'
		}
	},
	{
		path      : '/form-fields/attendee/new',
		name      : 'admin-attendee-form-field-new',
		component : loadView('attendee-form-fields/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Attendee Form Field'
		}
	},
	{
		path      : '/form-fields/attendee/archives',
		name      : 'admin-attendee-form-fields-archives',
		component : loadView('attendee-form-fields/Archives'),
		meta      : {
			middleware : [ Auth ],
			label      : 'Attendee Form Field Archives'
		}
	},
	{
		path      : '/form-fields/attendee/:id',
		name      : 'admin-attendee-form-field-edit',
		component : loadView('attendee-form-fields/Edit'),
		meta      : {
			middleware : [ Auth ],
			label      : 'Edit Attendee Form Field'
		}
	}
]