export default {
	addAll (state, payload) {
		state.travel = payload.travel || []
	},
	setCurrentEvent (state, event) {
		state.currentEvent = event
	},
	setCurrentAttendeeType (state, attendeeType) {
		state.currentAttendeeType = attendeeType
	}
}