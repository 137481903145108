export default {
	loading (state, payload) {
		state.loading = payload
	},
	setAdminSettings (state, payload) {
		state.adminSettings = this._vm.$lo.mergeWith(state.adminSettings, payload, (a, b) => null === b || (Array.isArray(b) && !b.length) ? a : undefined)
	},
	setAdminUser (state, payload) {
		state.adminLoaded = true
		state.adminUser   = payload
	},
	updateSetting (state, { key, value }) {
		this._vm.$set(state.adminSettings, key, value)
	},
	settingsLoaded (state, payload) {
		state.settingsLoaded = payload
	},
	toggleFiltersModal (state, payload) {
		state.showFiltersModal = payload
	},
	toggleSortModal (state, payload) {
		state.showSortModal = payload
	},
	setDueRegistrations (state, payload) {
		state.dueRegistrations = payload
	},
	setStatResults (state, { index, count, large }) {
		const whichStats = large ? 'large_stats' : 'stats'
		const stats      = state.adminSettings[whichStats]
		this._vm.$set(state.adminSettings[whichStats], index, {
			...stats[index],
			count
		})
	}
}