<template>
	<div class="gateway stripe">
		<br v-if="!admin" />
		<div
			v-if="savedCards.length && showSavedCards"
			class="saved-credit-cards"
		>
			<h4>Saved Credit Cards</h4>

			<div class="row radio-group">
				<div class="col-12">
					<label
						v-for="card in savedCards"
						:key="card.id"
					>
						<base-radio
							v-if="admin"
							class="regular-radio mr-1"
							v-model="savedCard"
							:name="card.id"
							inline
						/>
						<input
							v-if="!admin"
							type="radio"
							class="regular-radio"
							v-model="savedCard"
							:value="card.id"
						/>
						<component
							:is="admin ? 'i' : 'font-awesome-icon'"
							:icon="[ 'fab', `cc-${$helpers.ccType(card.brand)}` ]"
							:class="`credit-card-logo fa-2x fab fa-cc-${$helpers.ccType(card.brand)}`"
						/>
						<span class="d-md-inline d-none">
							&#9679; &#9679; &#9679; &#9679; {{ card.last4 }}
							<span class="spacer"></span>
							{{ card.name }}
							<span class="spacer"></span>
							(exp {{ card.exp_month }}/{{ card.exp_year }})
						</span>
						<span class="d-md-none text-small">
							{{ card.last4 }} - {{ card.name }} ({{ card.exp_month }}/{{ card.exp_year }})
						</span>
					</label>
				</div>
			</div>

			<br />
			<component
				:is="admin ? 'base-button' : 'input-button'"
				:type="admin ? 'link' : 'dark'"
				@click="showSavedCards=!showSavedCards"
			>Add New Credit Card</component>
		</div>
		<div
			v-if="!showSavedCards"
			class="row account"
		>
			<div class="col-md-12 text-center card-logos">
				<component
					v-for="(card, index) in cards"
					:key="index"
					:is="admin ? 'i' : 'font-awesome-icon'"
					:ref="card"
					:icon="[ 'fab', `cc-${card}` ]"
					:class="`credit-card-logo fa-2x fab fa-cc-${card}`"
				/>
			</div>

			<br><br>
			<div class="col-md-12">
				<div class="row account">
					<div
						:class="{
							'col-md-8' : !admin,
							'col-12'   : admin
						}"
					>
						<label for="credit_card_number" class="form-control-label">Credit Card Number</label>
						<div ref="cardNumber" class="form-control" />
					</div>
					<div
						:class="{
							'col-md-2' : !admin,
							'col-6'   : admin
						}"
					>
						<label for="credit_card_month" class="form-control-label">Expiration</label>
						<div ref="cardExpiry" class="form-control" />
					</div>
					<div
						:class="{
							'col-md-2' : !admin,
							'col-6'   : admin
						}"
					>
						<label for="credit_card_verification_value" class="form-control-label">CVC Code</label>
						<div ref="cardCvc" class="form-control" />
					</div>
				</div>
			</div>

			<component
				:is="admin ? 'base-button' : 'input-button'"
				:type="admin ? 'link' : 'dark'"
				v-if="savedCards.length"
				@click="showSavedCards=!showSavedCards"
			>Use Saved Credit Card</component>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
	data () {
		return {
			showSavedCards : false,
			savedCard      : null,
			cards          : [
				'amex',
				'discover',
				'mastercard',
				'visa'
			]
		}
	},
	computed : {
		...mapGetters('accounts', [ 'currentCreditCards' ]),
		...mapState('accounts', {
			currentAccount : 'current'
		}),
		...mapState('payment', [
			'cardNumber',
			'cardExpiry',
			'cardCvc',
			'savedCards',
			'publishableKey'
		]),
		admin () {
			return this.$store.state.isAdmin && this.$store.state.inAdmin
		}
	},
	methods : {
		...mapActions('accounts', {
			fetchCreditCards : 'fetchCreditCards'
		}),
		...mapMutations('payment', [
			'setCardNumber',
			'setCardExpiry',
			'setCardCvc',
			'setPaymentToken',
			'setPaymentMethod',
			'addSavedCards'
		]),
		initStripe () {
			const elements = this.$stripe.elements()
			const style = this.admin ? {} : {
				base : {
					color           : '#32325d',
					fontFamily      : 'crimson',
					fontSize        : '16px',
					'::placeholder' : {
						color         : '#aab7c4',
						textTransform : 'uppercase'
					}
				}
			}
			this.setCardNumber(elements.create('cardNumber', { style }))
			this.cardNumber.mount(this.$refs.cardNumber)

			this.cardNumber.on('change', event => {
				// Switch brand logo
				const logos = [
					'amex',
					'visa',
					'mastercard',
					'discover'
				]
				if (event.brand) {
					logos.forEach(logo => {
						if (this.$refs[logo][0].classList) {
							this.$refs[logo][0].classList.remove('not-selected')
						}
					})

					if (-1 !== logos.indexOf(event.brand)) {
						logos.forEach(logo => {
							if (this.$refs[logo][0].classList) {
								this.$refs[logo][0].classList.add('not-selected')
							}
						})

						if (this.$refs[event.brand][0].classList) {
							this.$refs[event.brand][0].classList.remove('not-selected')
						}
					}
				}
			})

			this.setCardExpiry(elements.create('cardExpiry', { style }))
			this.cardExpiry.mount(this.$refs.cardExpiry)

			this.setCardCvc(elements.create('cardCvc', { style }))
			this.cardCvc.mount(this.$refs.cardCvc)
		},
		destroyStripe () {
			if (this.cardNumber) {
				this.cardNumber.destroy()
				this.setCardNumber(null)
			}

			if (this.cardExpiry) {
				this.cardExpiry.destroy()
				this.setCardExpiry(null)
			}

			if (this.cardCvc) {
				this.cardCvc.destroy()
				this.setCardCvc(null)
			}
		},
		selectDefaultCard () {
			this.savedCards.forEach(card => {
				if (card.default) {
					this.savedCard = card.id
				}
			})
		},
		submitPayment (billing, resolve, reject) {
			if (this.showSavedCards && this.savedCard) {
				this.setPaymentMethod(this.savedCard)
				return resolve()
			}

			this.$stripe.createToken(this.cardNumber, {
				name            : billing.first_name + ' ' + billing.last_name,
				address_line1   : billing.address1 || '',
				address_line2   : billing.address2 || '',
				address_city    : billing.city || '',
				address_state   : billing.state || '',
				address_zip     : billing.zip || '',
				address_country : billing.country || ''
			})
				.then(result => {
					if (result.error) {
						throw new Error(result.error.message)
					}
					this.setPaymentToken(result.token)
					resolve()
				})
				.catch(error => reject(error))
		}
	},
	watch : {
		showSavedCards (newValue) {
			if (true === newValue) {
				return this.destroyStripe()
			}

			// Use a timeout to ensure state has been set.
			setTimeout(() => this.initStripe(), 0)
		},
		publishableKey () {
			this.initStripe()
		},
		savedCards (cards) {
			if (cards.length) {
				this.showSavedCards = true

				this.selectDefaultCard()
			}
		}
	},
	mounted () {
		if (this.admin) {
			this.fetchCreditCards(this.currentAccount.id)
				.then(() => {
					if (this.currentCreditCards.length) {
						this.addSavedCards(this.currentCreditCards)
						this.showSavedCards = true
						this.selectDefaultCard()
					}
				})
		} else {
			if (this.savedCards.length) {
				this.showSavedCards = true
				this.selectDefaultCard()
			}
		}

		this.$bus.$on('submit-payment-stripe', this.submitPayment)
		if (this.publishableKey) {
			this.initStripe()
		}
	},
	beforeDestroy () {
		this.destroyStripe()
		this.$bus.$off('submit-payment-stripe', this.submitPayment)
	}
}
</script>

<style lang="scss">
.gateway {
	.fa-2x {
		position: relative;
		top: .15em;
		margin-right: .4em;
		margin-left: .2em;
	}
}
</style>