export const CREATE = {
	label       : null,
	placeholder : null,
	enabled     : 0,
	required    : 0,
	type        : 'text',
	options     : [],
	conditional : 0,
	conditions  : {
		logic      : 'or',
		conditions : []
	},
	classes : null
}

export const CURRENT = {
	id : null,
	...CREATE
}