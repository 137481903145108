import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/travel-option-types',
		name      : 'admin-travel-option-types',
		component : loadView('travel-option-types/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Travel Option Types'
		}
	},
	{
		path      : '/travel-option-types/new',
		name      : 'admin-travel-option-type-new',
		component : loadView('travel-option-types/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Travel Option Type'
		}
	},
	{
		path      : '/travel-option-types/archives',
		name      : 'admin-travel-option-types-archives',
		component : loadView('travel-option-types/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Travel Option Types Archives'
		}
	},
	{
		path      : '/travel-option-types/:id',
		name      : 'admin-travel-option-type-edit',
		component : loadView('travel-option-types/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Travel Option Type'
		}
	},
	{
		path      : '/travel-option-type-options',
		name      : 'admin-travel-option-type-options',
		component : loadView('travel-option-type-options/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Travel Options'
		}
	},
	{
		path      : '/travel-option-type-options/new',
		name      : 'admin-travel-option-type-option-new',
		component : loadView('travel-option-type-options/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Travel Option'
		}
	},
	{
		path      : '/travel-option-type-options/archives',
		name      : 'admin-travel-option-type-options-archives',
		component : loadView('travel-option-type-options/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Travel Option Archives'
		}
	},
	{
		path      : '/travel-option-type-options/:id',
		name      : 'admin-travel-option-type-option-edit',
		component : loadView('travel-option-type-options/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Travel Option'
		}
	}
]