<template>
	<form
		@submit.prevent="nextPage"
		novalidate
	>
		<div
			v-if="showQuestions.length"
			class="questions"
		>
			<template
				v-for="(question, index) in showQuestions"
				class="row"
			>
				<hr
					v-if="index > 0"
					:key="`hr-${index}`"
					class="my-4 mx-0"
				>
				<div
					:key="question.id"
					class="row"
				>
					<div
						class="question"
						:class="getClassByType(question.type)[0]"
					>
						<p v-if="!question.hide_label">{{ getLabel(question.label) }} <span v-if="question.required" class="required">*</span></p>
						<div
							v-if="question.description"
							v-html="question.description"
						>
							{{ question.description }}
						</div>
					</div>
					<div
						class="question"
						:class="getClassByType(question.type)[1]"
					>
						<div
							v-if="'agreement' === question.type || 'radio' === question.type || 'checkbox' === question.type"
							class="row radio-group"
						>
							<div class="col-12">
								<label
									v-for="(option, i) in question.options"
									:key="i"
									:style="!admin && 'vertical' === question.alignment ? 'display: block;' : 'margin-left: 10px;'"
								>
									<input
										:class="[
											{ ['regular-' + question.type]: true },
											{ [question.type]: true }
										]"
										:type="'agreement' === question.type ? 'checkbox' : question.type"
										:name="'radio' === question.type ? question.id : question.id + '_' + option.value"
										:value="option.value"
										:checked="checkedSelected(question, option)"
										@input="setSelected(question, $event.target.value, $event.target.checked)"
									/>&nbsp;{{ getLabel(option.label) }}
								</label>
							</div>
						</div>
						<base-input
							v-if="'select' === question.type && admin"
						>
							<base-select
								:name="question.id"
								:options="question.options"
								:value="selected[question.id] ? selected[question.id].value : null"

								:placeholder="question.placeholder"
								@input="value => adminSelectChange(question, value)"
							/>
						</base-input>
						<input-select
							v-if="'select' === question.type && !admin"
							:name="question.id"
							:options="question.options"
							:selected="selected[question.id] ? selected[question.id].value : null"
							:placeholder="question.placeholder"
							@change="selectChange"
						/>
						<component
							:class="{ 'form-control': !admin }"
							v-if="'text' === question.type"
							:is="admin ? 'base-input' : 'input'"
							:type="question.type"
							:name="question.id"
							:placeholder="question.placeholder"
							:value="selected[question.id] ? selected[question.id].value : ''"
							@input="setSelected(question, $event.target.value)"
						/>
						<textarea
							v-if="'textarea' === question.type"
							class="form-control"
							:name="question.id"
							:placeholder="question.placeholder"
							:value="selected[question.id] ? selected[question.id].value : ''"
							@input="setSelected(question, $event.target.value)"
						/>
					</div>
				</div>
			</template>
		</div>

		<register-buttons
			v-if="registrationKey"
			:registrationKey="registrationKey"
			:buttons="buttons"
			:nextButtonText="$route.query.edit ? 'Update' : (buttons && -1 !== buttons.indexOf('update') ? 'Update' : 'Next Page')"
		/>

	</form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	props : {
		buttons : {
			type     : [ Array, Boolean ],
			required : true
		},
		defaults        : Object,
		registrationKey : {
			type     : [ String, Boolean ],
			required : true
		},
		event : {
			type     : Object,
			required : true
		},
		attendee     : Object,
		account      : Object,
		attendeeType : {
			type     : String,
			required : true
		},
		admin        : Boolean,
		submit       : Boolean,
		onlyEditable : Boolean
	},
	data () {
		return {
			errors          : [],
			mappedQuestions : [],
			selected        : {}
		}
	},
	computed : {
		...mapGetters('questions', [ 'allQuestions', 'eventQuestions' ]),
		...mapGetters('attendees', {
			currentAttendee : 'get'
		}),
		showQuestions () {
			return this.mappedQuestions
				.filter(question => question)
				.filter(question => this.$conditions(question || {}, this.selected))
				.filter(question => !this.onlyEditable || question.editable)
		}
	},
	watch : {
		allQuestions : 'filterQuestions',
		selected     : 'filterQuestions',
		submit (newValue) {
			if (newValue) {
				this.nextPage()
			}
		}
	},
	methods : {
		nextPage () {
			this.errors = []
			this.$emit('errors', [])
			const questions = this.mappedQuestions
				.filter(question => this.$conditions(question, this.selected))
				.map(question => {
					if (question.required) {
						question.validation = [
							v => {
								if (!v || (!v.value && !v.prettyValue && (!v.values || !v.values.length))) {
									return `The "${this.getLabel(question.label)}" field is required`
								}
								return true
							}
						]
					}
					return question
				})

			// Let go of any selected values that are conditional, but not visible.
			Object.keys(this.selected)
				.forEach(questionId => {
					if (-1 === questions.findIndex(question => question.id === questionId)) {
						this.$delete(this.selected, questionId)
					}
				})

			const validate = this.$store.state.isAdmin ? '$validateAdmin' : '$validate'
			if (this[validate](this.errors, questions, this.selected)) {
				this.$emit('update', this.selected)
			} else {
				this.$emit('errors', this.errors)
			}
		},
		filterQuestions () {
			this.mappedQuestions = this.eventQuestions
				.filter(eq => eq.event === (this.event.event_id || this.event.id) && eq.attendee_type === this.attendeeType)
				.map(eq => this.allQuestions.find(q => q.id === eq.question))
		},
		checkedSelected (question, option) {
			if (!this.selected[question.id]) {
				return false
			}

			if ('radio' === question.type && option.value === this.selected[question.id].value) {
				return true
			}

			if (!this.selected[question.id].values && -1 === this.selected[question.id].value.indexOf(option.value)) {
				return false
			}

			if (this.selected[question.id].values && -1 === this.selected[question.id].values.findIndex(sv => sv.value === option.value)) {
				return false
			}

			return true
		},
		getClassByType (type) {
			switch (type) {
				case 'agreement':
					return [ 'col-md-9', 'col-md-3 col-md-text-right text-md-right' ]
				case 'textarea':
				case 'text':
				case 'date':
				case 'header':
				case 'phone':
				case 'select':
				case 'password':
				case 'description':
					return [ 'col-md-12', 'col-md-12' ]
				case 'radio':
				case 'checkbox':
					return [ 'col-md-8', 'col-md-4 col-md-text-right text-md-right' ]
			}
		},
		adminSelectChange (question, value) {
			this.selected[question.id] = {
				id          : question.id,
				value       : value,
				prettyValue : question.options.filter(option => option.value === value)[0].label,
				label       : question.label
			}

			// We need to filter questions after a select change is made.
			this.filterQuestions()
		},
		selectChange (payload) {
			Object.keys(payload).forEach(key => {
				if (!payload[key] && this.selected[key]) {
					return this.$delete(this.selected, key)
				}

				const question     = this.allQuestions.filter(question => question.id === key)[0]
				this.selected[key] = {
					id          : question.id,
					value       : payload[key],
					prettyValue : question.options.filter(option => option.value === payload[key])[0].label,
					label       : question.label
				}
			})
			// We need to filter questions after a select change is made.
			this.filterQuestions()
		},
		setSelected (question, value, checked) {
			// We need to manually filter each question after a change
			// is made to allow the conditions to work properly. We
			// have to use a method here with @input instead of v-model
			// so that this works.
			if ('checkbox' !== question.type && 'agreement' !== question.type) {
				this.selected[question.id] = {
					id          : question.id,
					value,
					prettyValue : question.options && question.options.length ? question.options.filter(option => option.value === value)[0].label : null,
					label       : question.label
				}
				return this.filterQuestions()
			}

			if (checked) {
				if (!this.selected[question.id]) {
					this.selected[question.id] = {
						id     : question.id,
						label  : question.label,
						values : []
					}
				}

				const selectedValue = this.selected[question.id].values.filter(sv => sv.value === value)
				if (selectedValue.length) {
					return this.filterQuestions()
				}
				this.$set(this.selected[question.id].values, this.selected[question.id].values.length, {
					value,
					prettyValue : question.options && question.options.length ? question.options.filter(option => option.value === value)[0].label : null
				})
			} else {
				if (!this.selected[question.id]) {
					return this.filterQuestions()
				}

				const index = this.selected[question.id].values.findIndex(sv => sv.value === value)
				if (-1 === index) {
					return this.filterQuestions()
				}

				this.$delete(this.selected[question.id].values, index)

				if (!this.selected[question.id].values.length) {
					this.$delete(this.selected, question.id)
				}
			}

			this.filterQuestions()
		},
		getLabel (label) {
			return this.$helpers.parseLabel(label, {
				attendee : this.attendee,
				account  : this.account,
				event    : this.event
			})
		}
	},
	mounted () {
		this.mappedQuestions = this.allQuestions
		this.filterQuestions()
		setTimeout(() => {
			this.selected = this.defaults
		}, 50)
	}
}
</script>

<style lang="scss">
.questions {
	textarea {
		resize: none;
		height: 100px;
		line-height: 1.5;
	}
}
</style>