export default (field, values, additionalValues, skipValues = false) => {
	if (!field.conditional || !field.conditions || !field.conditions.conditions.length) {
		return true
	}

	const newValues = {
		...values,
		...additionalValues
	}

	// If we have a "fields" parameter, lets remove it.
	delete newValues.fields

	const logic    = field.logic || field.conditions.logic || 'or'
	const computed = field.conditions.conditions.map(condition => {
		const operator = condition.operator || 'is'
		const id       = condition.id
		const value    = condition.value
		const valued   = newValues[id] && newValues[id].value ? newValues[id].value : (newValues[id] && newValues[id].values ? newValues[id].values : newValues[id])

		if (skipValues) {
			return !!valued
		}

		if ('is' === operator && (!valued || (valued !== value && -1 === valued.indexOf(value)))) {
			return false
		} else if ('is-not' === operator && valued && (valued === value || -1 !== valued.indexOf(value))) {
			return false
		}

		return true
	})

	if ('or' === logic) {
		return computed.some(c => c)
	}

	return computed.every(c => c)
}