export const CREATE = {
	label       : null,
	hide_label  : 0,
	placeholder : null,
	enabled     : 1,
	editable    : 1,
	required    : 0,
	type        : 'text',
	alignment   : 'vertical',
	options     : [],
	conditional : 0,
	conditions  : {
		logic      : 'or',
		conditions : []
	},
	description : '',
	classes     : null
}

export const CURRENT = {
	id : null,
	...CREATE
}