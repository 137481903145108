export default {
	removeNotification (state, timestamp) {
		const index = state.notifications.findIndex(n => n.timestamp === timestamp)
		if (-1 !== index) {
			this._vm.$delete(state.notifications, index)
		}
	},
	addNotification (state, { notification, settings }) {
		const { notifications } = state
		if ('string' === typeof notification || notification instanceof String) {
			notification = { message: notification }
		}
		notification.timestamp = new Date()
		notification.timestamp.setMilliseconds(
			notification.timestamp.getMilliseconds() + notifications.length
		)

		notification = Object.assign({}, settings, notification)

		notifications.push(notification)
		this._vm.$set(state, 'notifications', notifications)
	}
}