import {
	Auth
} from '@/registration/middleware'

import { loadView } from './load'

export default [
	{
		path : '/events',
		name : 'events',
		meta : {
			middleware : [ Auth ],
			label      : 'Events'
		},
		component : loadView('events/Events')
	},
	{
		path : '/events/registration/:key/edit/:id',
		name : 'events-registration-edit',
		meta : {
			middleware : [ Auth ],
			background : 'gray',
			label      : 'Registration Details'
		},
		component : loadView('events/EditRegistration')
	},
	{
		path : '/events/registration/:key/edit/:id/questions',
		name : 'events-registration-edit-questions',
		meta : {
			middleware : [ Auth ],
			background : 'blue',
			label      : 'Edit Registration Questions'
		},
		component : loadView('events/EditQuestions')
	},
	{
		path : '/events/registration/:key/edit/:id/travel',
		name : 'events-registration-edit-travel',
		meta : {
			middleware : [ Auth ],
			background : 'blue',
			label      : 'Edit Travel Options'
		},
		component : loadView('events/EditTravel')
	},
	{
		path : '/events/cancel',
		name : 'events-cancel',
		meta : {
			middleware : [ Auth ],
			background : 'dark-red',
			label      : 'Cancel Registration'
		},
		component : loadView('events/Cancel')
	}
]