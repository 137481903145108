export default {
	publishableKey : null,
	cardNumber     : null,
	cardExpiry     : null,
	cardCvc        : null,
	savedCards     : [],
	paymentAmounts : {},
	donations      : [],
	paymentData    : {
		gateway     : 'stripe',
		token       : {},
		card        : null,
		checkNumber : null
	},
	registrations : {}
}