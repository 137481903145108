export function resetLogs (state) {
	state.logs = []
}

export function setLogs (state, logs) {
	state.logs = logs
}

export function setCurrent (state, payload) {
	const { current } = state
	state.current = {
		...current,
		...payload
	}
}

export function setPayload (vm, state, key, payload) {
	vm.$set(state, key, payload)
}

export function setPagination (vm, state, payload) {
	const { pagination } = state
	pagination.page      = payload.page
	pagination.total     = payload.total
	setPayload(vm, state, 'pagination', pagination)
}

export function setNewModel (state, payload) {
	const { newModel } = state
	state.newModel = {
		...newModel,
		...payload
	}
}

export function setArchives (state, payload) {
	state.archives = payload
}

export function setModels (state, payload) {
	state.models = payload
}

export function setModelArchives (state, payload) {
	state.modelArchives = payload
}

export function setAllModels (state, payload) {
	state.allModels = payload
}

export function setLocalSearch (state, payload) {
	state.localSearch = payload
}