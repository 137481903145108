import {
	Auth
} from '@/registration/middleware'

import { loadView } from './load'

export default [
	{
		path : '/attendees',
		name : 'attendees',
		meta : {
			middleware : [ Auth ],
			label      : 'Attendees'
		},
		component : loadView('attendees/Attendees')
	},
	{
		path : '/attendees/add',
		name : 'attendees-add',
		meta : {
			middleware : [ Auth ],
			background : 'gray',
			label      : 'Add Attendee'
		},
		component : loadView('attendees/Add')
	},
	{
		path : '/attendees/edit/:id',
		name : 'attendees-edit',
		meta : {
			middleware : [ Auth ],
			background : 'gray',
			label      : 'Edit Attendee'
		},
		component : loadView('attendees/Edit')
	}
]