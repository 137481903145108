import { conditions } from '@/utils'

let admin = false

const validateAdmin = (errors, validation, fields, obj = false) => {
	admin = true
	return validate(errors, validation, fields, obj)
}

const validate = (errors, validation, fields, obj = false) => {
	// Convert arrays into objects.
	if (Array.isArray(validation)) {
		const val = array => array.reduce((obj, field) => {
			if (field.validation) {
				obj[field.name || field.id] = field.validation
			}
			return obj
		}, {})

		validation = val(validation)
	}

	// Loop through the objects and validate.
	Object.keys(validation).forEach(ind => {
		const v = validation[ind]
		const s = fields[ind]

		// As an admin we only want to validate email addresses.
		if (admin && 'email' !== ind) {
			return
		}

		for (let i = 0; i < v.length; i++) {
			const error = v[i](s, fields)
			if (true !== error) {
				if (obj) {
					errors[ind] = error
				} else {
					errors.push(error)
				}
				break
			}
		}
	})

	// If we are in the admin, no other validation is needed.
	if (admin) {
		return obj ? !Object.keys(errors).length : !errors.length
	}

	// Validate additional fields.
	if (fields.fields && fields.fields.all && fields.fields.all.length) {
		fields.fields.all
			.filter(field => conditions(field, fields.fields.values))
			.filter(field => field.required)
			.forEach(field => {
				if (!fields.fields.values[field.id]) {
					if (obj) {
						errors[field.id] = `The "${field.label.replace(':', '')}" field is required`
						return
					}

					return errors.push(`The "${field.label.replace(':', '')}" field is required`)
				}

				if ('email' === field.type && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(fields.fields.values[field.id])) {
					if (obj) {
						errors[field.id] = `The "${field.label.replace(':', '')}" requires a valid email address`
						return
					}

					return errors.push(`The "${field.label.replace(':', '')}" requires a valid email address`)
				}
			})
	}

	return obj ? !Object.keys(errors).length : !errors.length
}

export {
	validate,
	validateAdmin
}