import { CREATE, CURRENT } from './defaults'
import { state } from '@/admin/store/commonState'

export default {
	...state,
	newModel      : { ...CREATE },
	current       : { ...CURRENT },
	allFormFields : [],
	types         : [],
	emails        : []
}