import { CREATE, CURRENT } from './defaults'
import * as m from '@/admin/store/commonMutations'

const {
	setPayload,
	setPagination,
	...mutations
} = m

export default {
	...mutations,
	resetNew (state) {
		setPayload(this._vm, state, 'newModel', { ...CREATE })
	},
	resetCurrent (state) {
		setPayload(this._vm, state, 'current', { ...CURRENT })
	},
	setPagination (state, payload) {
		setPagination(this._vm, state, payload)
	},
	updateAttendeeFormFields (state, payload) {
		this._vm.$set(state, 'allModels', payload)
	}
}