export default {
	fetch ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/payment/settings/',
			then   : response => {
				commit('addPublishableKey', response.body.publishableKey)
				commit('addSavedCards', response.body.paymentMethods)
			}
		})
	},
	submit ({ state }, { billing, notes }) {
		const { paymentData, paymentAmounts, donations } = state

		paymentData.billing = billing
		paymentData.notes   = notes

		const payload = {
			payment : paymentData,
			amounts : paymentAmounts,
			donations
		}

		return this._vm.$api({
			method : 'post',
			send   : payload,
			url    : '/admin/payment/submit/'
		})
	}
}