export default {
	settingsLoaded : false,
	formFields     : {
		accounts  : [],
		attendees : []
	},
	events : {
		eventTypes           : [],
		donationsEnabled     : true,
		donationsDescription : '',
		donations            : []
	},
	account : {
		accountCreateMessage      : '',
		welcomePageMessage        : '',
		cancelRegistrationMessage : ''
	},
	emails : {
		automaticBillingReminderInteger : 3,
		automaticBillingReminderUnit    : 'weeks',
		passwordResetEmail              : null,
		createPasswordEmail             : null,
		paymentReceiptEmail             : null,
		registrationReceiptEmail        : null,
		balanceDueEmail                 : null,
		balancePaidEmail                : null
	},
	gateway : {
		paymentGateways          : '',
		termsAndConditions       : '',
		checkGatewayConfirmation : '',
		currency                 : 'usd',
		stripePublishableKeyLive : null,
		stripeSecretKeyLive      : null,
		stripePublishableKeyTest : null,
		stripeSecretKeyTest      : null,
		testMode                 : true
	},
	main : {
		companyName        : null,
		companyUrl         : null,
		companyContactName : null,
		companyTitle       : null,
		companyAddress     : null,
		companyPhone       : null,
		companyEmail       : null,
		googleAnalyticsId  : null
	},
	registration : {
		saveLogoutConfirmationMessage   : '',
		cancelRegistrationMessage       : '',
		waitlistConfirmationMessage     : '',
		waitlistSummaryMessage          : '',
		accountRestrictionMessage       : '',
		attendeePageMessage             : '',
		eventPageMessage                : '',
		questionsPageMessage            : '',
		financialOptionsPageMessage     : '',
		travelOptionsPageMessage        : '',
		summaryPageMessage              : '',
		paymentPageMessage              : '',
		confirmationPageMessage         : '',
		depositDeadlineInteger          : 2,
		depositDeadlineUnit             : 'weeks',
		groupRegistrationUploadMessage  : '',
		groupRegistrationMappingMessage : ''
	}
}