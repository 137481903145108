export const CREATE = {
	first_name : null,
	last_name  : null,
	email      : null,
	phone      : null,
	address1   : null,
	address2   : null,
	city       : null,
	state      : null,
	zip        : null,
	country    : 'US',
	fields     : {
		all    : [],
		values : []
	},
	notes      : null,
	createPass : true,
	role       : 'registration'
}

export const CURRENT = {
	id         : null,
	first_name : null,
	last_name  : null,
	email      : null,
	phone      : null,
	address1   : null,
	address2   : null,
	city       : null,
	state      : null,
	country    : 'US',
	zip        : null,
	fields     : {
		all    : [],
		values : []
	},
	amounts : {
		account_credit : 0,
		balance_due    : 0
	},
	savedRegistrations : [],
	created            : null,
	updated            : null,
	attendees          : [],
	registrations      : [],
	credit             : [],
	creditCards        : [],
	emails             : [],
	role               : 'registration'
}