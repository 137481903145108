import defaults from './options'

const format = (input, opt = defaults) => {
	if (opt.allowBlank && '' === input) {
		return ''
	}

	if ('number' === typeof input) {
		input = input.toFixed(fixed(opt.precision))
	}

	const negative = 0 <= input.indexOf('-') ? '-' : ''
	const numbers  = onlyNumbers(input)
	const currency = numbersToCurrency(numbers, opt.precision)
	const parts    = toStr(currency).split('.')
	const decimal  = parts[1]
	const integer  = addThousandSeparator(parts[0], opt.thousands)

	return negative + opt.prefix + joinIntegerAndDecimal(integer, decimal, opt.decimal) + opt.suffix
}

const unformat = (input, precision) => {
	const negative = 0 <= input.indexOf('-') ? -1 : 1
	const numbers  = onlyNumbers(input)
	const currency = numbersToCurrency(numbers, precision)

	return parseFloat(currency) * negative
}

const onlyNumbers = input => {
	return toStr(input).replace(/\D+/g, '') || '0'
}

const setMin = (input, opt = defaults) => {
	if (input < opt.min) {
		input = opt.min
	}

	return input
}

const setMax = (input, opt = defaults) => {
	if (input > opt.max) {
		input = opt.max
	}
	return input
}

// Uncaught RangeError: toFixed() digits argument must be between 0 and 20 at Number.toFixed
const fixed = precision => {
	return between(0, precision, 20)
}

const between = (min, n, max) => {
	return Math.max(min, Math.min(n, max))
}

const numbersToCurrency = (numbers, precision) => {
	const exp   = Math.pow(10, precision)
	const float = parseFloat(numbers) / exp
	return float.toFixed(fixed(precision))
}

const addThousandSeparator = (integer, separator) => {
	return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`)
}

const joinIntegerAndDecimal = (integer, decimal, separator) => {
	return decimal ? integer + separator + decimal : integer
}

const toStr = value => {
	return value ? value.toString() : ''
}

const setCursor = (el, position) => {
	const setSelectionRange = () => { el.setSelectionRange(position, position) }
	if (el === document.activeElement) {
		setSelectionRange()
		setTimeout(setSelectionRange, 1) // Android Fix
	}
}

// https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events#The_old-fashioned_way
const event = name => {
	const evt = document.createEvent('Event')
	evt.initEvent(name, true, true)
	return evt
}

export {
	format,
	unformat,
	setCursor,
	setMin,
	setMax,
	event
}