export const BASE_API_URL = process.env.VUE_APP_PUBLIC_URL + '/v1'

export const COUNTRIES = [
	{ value: 'US', label: 'United States' },
	{ value: 'CA', label: 'Canada' }
]

export const US_STATES = [
	{ value: '', label: '' },
	{ value: 'AL', label: 'Alabama' },
	{ value: 'AK', label: 'Alaska' },
	{ value: 'AS', label: 'American Samoa' },
	{ value: 'AZ', label: 'Arizona' },
	{ value: 'AR', label: 'Arkansas' },
	{ value: 'CA', label: 'California' },
	{ value: 'CO', label: 'Colorado' },
	{ value: 'CT', label: 'Connecticut' },
	{ value: 'DE', label: 'Deleware' },
	{ value: 'DC', label: 'District of Columbia' },
	{ value: 'FL', label: 'Florida' },
	{ value: 'GA', label: 'Georgia' },
	{ value: 'GU', label: 'Guam' },
	{ value: 'HI', label: 'Hawaii' },
	{ value: 'ID', label: 'Idaho' },
	{ value: 'IL', label: 'Illinois' },
	{ value: 'IN', label: 'Indiana' },
	{ value: 'IA', label: 'Iowa' },
	{ value: 'KS', label: 'Kansas' },
	{ value: 'KY', label: 'Kentucky' },
	{ value: 'LA', label: 'Louisiana' },
	{ value: 'ME', label: 'Maine' },
	{ value: 'MD', label: 'Maryland' },
	{ value: 'MA', label: 'Massachusetts' },
	{ value: 'MI', label: 'Michigan' },
	{ value: 'MN', label: 'Minnesota' },
	{ value: 'MS', label: 'Mississippi' },
	{ value: 'MO', label: 'Missouri' },
	{ value: 'MT', label: 'Montana' },
	{ value: 'NE', label: 'Nebraska' },
	{ value: 'NV', label: 'Nevada' },
	{ value: 'NH', label: 'New Hampshire' },
	{ value: 'NJ', label: 'New Jersey' },
	{ value: 'NM', label: 'New Mexico' },
	{ value: 'NY', label: 'New York' },
	{ value: 'NC', label: 'North Carolina' },
	{ value: 'ND', label: 'North Dakota' },
	{ value: 'MP', label: 'Northern Mariana Islands' },
	{ value: 'OH', label: 'Ohio' },
	{ value: 'OK', label: 'Oklahoma' },
	{ value: 'OR', label: 'Oregon' },
	{ value: 'PA', label: 'Pennsylvania' },
	{ value: 'PR', label: 'Puerto Rico' },
	{ value: 'RI', label: 'Rhode Island' },
	{ value: 'SC', label: 'South Carolina' },
	{ value: 'SD', label: 'South Dakota' },
	{ value: 'TN', label: 'Tennessee' },
	{ value: 'TX', label: 'Texas' },
	{ value: 'UT', label: 'Utah' },
	{ value: 'VT', label: 'Vermont' },
	{ value: 'VI', label: 'Virgin Islands' },
	{ value: 'VA', label: 'Virginia' },
	{ value: 'WA', label: 'Washington' },
	{ value: 'WV', label: 'West Virginia' },
	{ value: 'WI', label: 'Wisconsin' },
	{ value: 'WY', label: 'Wyoming' }
]
export const CA_STATES = [
	{ value: '', label: '' },
	{ value: 'AB', label: 'Alberta' },
	{ value: 'BC', label: 'British Columbia' },
	{ value: 'MB', label: 'Manitoba' },
	{ value: 'NB', label: 'New Brunswick' },
	{ value: 'NL', label: 'Newfoundland' },
	{ value: 'NT', label: 'Northwest Territories' },
	{ value: 'NS', label: 'Nova Scotia' },
	{ value: 'NU', label: 'Nunavut' },
	{ value: 'ON', label: 'Ontario' },
	{ value: 'PE', label: 'Prince Edward Island' },
	{ value: 'QC', label: 'Quebec' },
	{ value: 'SK', label: 'Saskatchewan' },
	{ value: 'YT', label: 'Yukon Territory' }
]

export const TIME_ZONES = [
	{ label: 'Visitor\'s Local Time', value: null },
	{ label: 'Eastern Time', value: 'America/New_York' },
	{ label: 'Central Time', value: 'America/Chicago' },
	{ label: 'Mountain Time', value: 'America/Denver' },
	{ label: 'Pacific Time', value: 'America/Los_Angeles' },
	{ label: 'Alaska Time', value: 'America/Anchorage' },
	{ label: 'Hawaii Time', value: 'Pacific/Honolulu' }
]

export const REGISTRATION_STATUSES = [
	{ label: 'Complete', value: 'complete' },
	{ label: 'Pending', value: 'pending' },
	{ label: 'Partial', value: 'partial' },
	{ label: 'Waitlist', value: 'waitlist' },
	{ label: 'Cancelled', value: 'cancelled' }
]

export const REGISTRATION_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Notes',
		name           : 'notes',
		placeholder    : 'Registration Notes',
		required       : false,
		type           : 'textarea',
		minHeight      : 103
	}
]

export const ATTENDEE_FORM_FIELDS = [
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'Attendee First Name',
		name           : 'first_name',
		placeholder    : 'Attendee First Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Attendee First Name field is required'
		]
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'Attendee Last Name',
		name           : 'last_name',
		placeholder    : 'Attendee Last Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Attendee Last Name field is required'
		]
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'Email Address',
		name           : 'email',
		placeholder    : 'email@domain.com',
		required       : true,
		type           : 'email',
		validation     : [
			v => !!v || 'An Email Address is required',
			v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please enter a valid email address'
		]
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'Attendee Phone',
		name           : 'phone',
		placeholder    : '555-123-4567',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'A Phone Number is required',
			v => !v || /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) || 'Please enter a valid phone number'
		]
	},
	{
		classSize      : 'col-md-12',
		adminClassSize : 'col-lg-12',
		label          : 'Address',
		name           : 'address1',
		placeholder    : 'Address',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Address field is required'
		]
	},
	{
		classSize      : 'col-md-12',
		adminClassSize : 'col-lg-12',
		label          : 'Address 2',
		name           : 'address2',
		placeholder    : 'Address 2',
		required       : false,
		type           : 'text'
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'City',
		name           : 'city',
		placeholder    : 'City',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The City field is required'
		]
	},
	{
		classSize      : 'col-md-4 col-sm-6',
		adminClassSize : 'col-lg-4',
		label          : null,
		name           : 'state',
		options        : 'states',
		required       : true,
		type           : 'select',
		validation     : [
			v => !!v || 'The State field is required'
		]
	},
	{
		classSize      : 'col-md-2 col-sm-6',
		adminClassSize : 'col-lg-2',
		label          : 'Zip Code',
		name           : 'zip',
		placeholder    : 'Zip Code',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Zip Code field is required'
		]
	},
	{
		classSize      : 'col-md-12',
		adminClassSize : 'col-lg-12',
		label          : 'Country',
		name           : 'country',
		options        : 'countries',
		required       : true,
		type           : 'select',
		validation     : [
			v => !!v || 'The Country field is required'
		]
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'Gender',
		name           : 'gender',
		options        : [
			{ value: 'male', label: 'Male' },
			{ value: 'female', label: 'Female' }
		],
		required   : true,
		type       : 'radio',
		validation : [
			v => !!v || 'The Gender field is required'
		]
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'Date of Birth',
		name           : 'birthdate',
		required       : true,
		type           : 'birthdate',
		validation     : [
			v => !!v || 'The Date of Birth field is required'
		]
	}
]

export const ATTENDEE_TYPE_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'Attendee Type Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Enabled',
		name           : 'enabled',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Private',
		name           : 'private',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Event Types',
		name           : 'event_types',
		options        : 'mappedEventTypes',
		placeholder    : 'Choose one or more Event Types',
		required       : true,
		type           : 'select',
		multiple       : true,
		validation     : [
			v => !!v.length || 'The Event Types field is required'
		]
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Description',
		name           : 'description',
		placeholder    : 'Select One',
		required       : false,
		type           : 'editor'
	}
]

export const ACCOUNT_FORM_FIELDS = [
	{
		classSize      : 'd-none',
		adminClassSize : 'd-none',
		label          : 'Age',
		name           : 'age',
		required       : false,
		type           : 'text',
		validation     : [
			v => v ? 'An error occurred, please try again later' : true
		]
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'First Name',
		name           : 'first_name',
		placeholder    : 'First Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The First Name field is required'
		]
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'Last Name',
		name           : 'last_name',
		placeholder    : 'Last Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Last Name field is required'
		]
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'Email Address',
		name           : 'email',
		placeholder    : 'email@domain.com',
		required       : true,
		type           : 'email',
		validation     : [
			v => !!v || 'An Email Address is required',
			v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please enter a valid email address'
		]
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'Phone Number',
		name           : 'phone',
		placeholder    : '555-123-4567',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Phone Number field is required',
			v => /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) || 'Please enter a valid phone number'
		]
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'Password',
		name           : 'password',
		required       : true,
		type           : 'password',
		validation     : [
			v => !!v || 'A password is required'
		]
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'Verify Password',
		name           : 'password2',
		required       : true,
		type           : 'password',
		validation     : [
			(v, fields) => v === fields.password || 'The Password fields must match'
		]
	}
]

export const ACCOUNT_ADDRESS_FIELDS = [
	{
		classSize      : 'col-md-12',
		adminClassSize : 'col-md-12',
		label          : 'Address',
		name           : 'address1',
		placeholder    : 'Address',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Address field is required'
		]
	},
	{
		classSize      : 'col-md-12',
		adminClassSize : 'col-md-12',
		label          : 'Address 2',
		name           : 'address2',
		placeholder    : 'Address 2',
		required       : false,
		type           : 'text'
	},
	{
		classSize      : 'col-md-6',
		adminClassSize : 'col-lg-6',
		label          : 'City',
		name           : 'city',
		placeholder    : 'City',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The City field is required'
		]
	},
	{
		classSize      : 'col-md-4 col-sm-6',
		adminClassSize : 'col-lg-4',
		label          : null,
		name           : 'state',
		options        : 'states',
		placeholder    : 'State',
		required       : true,
		type           : 'select',
		validation     : [
			v => !!v || 'The State field is required'
		]
	},
	{
		classSize      : 'col-md-2 col-sm-6',
		adminClassSize : 'col-lg-2',
		label          : 'Zip Code',
		name           : 'zip',
		placeholder    : 'Zip Code',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Zip Code field is required'
		]
	},
	{
		classSize      : 'col-md-12',
		adminClassSize : 'col-md-12',
		label          : 'Country',
		name           : 'country',
		options        : 'countries',
		placeholder    : 'Country',
		required       : true,
		type           : 'select',
		validation     : [
			v => !!v || 'The Country field is required'
		]
	}
]

export const EMAIL_TEMPLATE_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-6',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'Email Template Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Template Wrapper',
		name           : 'email_template_wrapper_id',
		options        : 'emailTemplateWrappers',
		placeholder    : 'Select One',
		required       : false,
		type           : 'select'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'From Name',
		name           : 'from_name',
		placeholder    : 'John Doe',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The From Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'From Email Address',
		name           : 'from_email',
		placeholder    : 'john@doe.com',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The From Email Address field is required',
			v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please enter a valid From email address'
		]
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Subject',
		name           : 'subject',
		placeholder    : 'Email Subject',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Subject field is required'
		]
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Message',
		name           : 'message',
		placeholder    : 'Email Message',
		required       : true,
		type           : 'editor',
		validation     : [
			v => !!v || 'The Message field is required'
		]
	}
]

export const EMAIL_TEMPLATE_WRAPPER_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'Email Template Wrapper Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Header',
		name           : 'header',
		placeholder    : 'Header',
		required       : false,
		type           : 'textarea',
		minHeight      : 200,
		maxHeight      : 500
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Footer',
		name           : 'footer',
		placeholder    : 'Footer',
		required       : false,
		type           : 'textarea',
		minHeight      : 200,
		maxHeight      : 500
	}
]

export const FINANCIAL_OPTION_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'Financial Option Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Enabled',
		name           : 'enabled',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Type',
		name           : 'type',
		options        : [
			{ label: 'None', value: 'none' },
			{ label: 'Radio', value: 'radio' },
			{ label: 'Checkbox', value: 'checkbox' }
		],
		placeholder : 'Select One',
		required    : true,
		type        : 'select'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'User Defined Price',
		name           : 'user_defined_price',
		required       : false,
		type           : 'switch',
		onText         : 'On',
		offText        : 'Off'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Amount',
		name           : 'amount',
		placeholder    : '$100.00',
		required       : false,
		type           : 'money'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Display Quantity',
		name           : 'display_quantity',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Default Quantity',
		name           : 'default_quantity',
		placeholder    : '1',
		required       : true,
		type           : 'number',
		validation     : [
			v => !!v || 'The Default Quantity field is required'
		]
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Description',
		name           : 'description',
		placeholder    : 'Select One',
		required       : false,
		type           : 'editor'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Conditional',
		name           : 'conditional',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize  : 'col-md-12',
		label           : 'Conditions',
		name            : 'conditions',
		required        : false,
		type            : 'draggable',
		hideValue       : true,
		operatorOptions : [
			{ label: 'Is Selected', value: 'is' },
			{ label: 'Is Not Selected', value: 'is-not' }
		],
		display : {
			conditional : true
		}
	}
]

export const FINANCIAL_OPTION_GROUP_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'Financial Option Group Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Required',
		name           : 'required',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Type',
		name           : 'type',
		options        : [
			{ label: 'None', value: 'none' },
			{ label: 'Radio', value: 'radio' },
			{ label: 'Checkbox', value: 'checkbox' }
		],
		placeholder : 'Select One',
		required    : true,
		type        : 'select'
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Description',
		name           : 'description',
		placeholder    : 'Select One',
		required       : false,
		type           : 'editor'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Conditional',
		name           : 'conditional',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize  : 'col-md-12',
		label           : 'Conditions',
		name            : 'conditions',
		required        : false,
		type            : 'draggable',
		options         : 'mappedFinancialOptions',
		hideValue       : true,
		operatorOptions : [
			{ label: 'Is Selected', value: 'is' },
			{ label: 'Is Not Selected', value: 'is-not' }
		],
		display : {
			conditional : true
		}
	}
]

export const QUESTION_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Label',
		name           : 'label',
		placeholder    : 'Question Label',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Label field is required'
		]
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Enabled',
		name           : 'enabled',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Required',
		name           : 'required',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Editable',
		name           : 'editable',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Hide Label',
		name           : 'hide_label',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Type',
		name           : 'type',
		options        : [
			{ label: 'Agreement Form', value: 'agreement' },
			{ label: 'Checkbox (select multiple values)', value: 'checkbox' },
			{ label: 'Date', value: 'date' },
			{ label: 'Description (displays text only)', value: 'description' },
			{ label: 'Dropdown (select one value)', value: 'select' },
			{ label: 'Email Address', value: 'email' },
			{ label: 'Header', value: 'header' },
			{ label: 'Multi Line Text Box', value: 'textarea' },
			{ label: 'Password', value: 'password' },
			{ label: 'Phone Number', value: 'phone' },
			{ label: 'Radio Buttons (select one value)', value: 'radio' },
			{ label: 'Single Line Text Box', value: 'text' }
		],
		placeholder : 'Select One',
		required    : true,
		type        : 'select'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Question Size',
		name           : 'classes',
		placeholder    : 'Select One',
		required       : true,
		type           : 'select',
		options        : [
			{ label: 'Full Width', value: 'col-12' },
			{ label: 'Half Width', value: 'col-md-6' }
		]
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Alignment',
		name           : 'alignment',
		options        : [
			{ label: 'Horizontal', value: 'horizontal' },
			{ label: 'Vertical', value: 'vertical' }
		],
		placeholder : 'Select One',
		required    : true,
		type        : 'select',
		display     : {
			type : [
				'radio',
				'checkbox'
			]
		}
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Placeholder',
		name           : 'placeholder',
		placeholder    : 'Form Field Placeholder',
		required       : false,
		type           : 'text',
		display        : {
			type : [
				'date',
				'email',
				'password',
				'phone',
				'text',
				'textarea'
			]
		}
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Options',
		name           : 'options',
		required       : false,
		type           : 'draggable',
		display        : {
			type : [
				'agreement',
				'checkbox',
				'radio',
				'select'
			]
		}
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Description',
		name           : 'description',
		placeholder    : 'Select One',
		required       : false,
		type           : 'editor'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Conditional',
		name           : 'conditional',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Conditions',
		name           : 'conditions',
		required       : false,
		type           : 'draggable',
		display        : {
			conditional : true
		}
	}
]

export const DISCOUNT_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-9',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'Discount Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-3',
		label          : 'Code',
		name           : 'code',
		placeholder    : 'Discount Code',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Code field is required'
		]
	},
	{
		adminClassSize : 'col-md-4',
		label          : 'Enabled',
		name           : 'enabled',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-4',
		label          : 'Stackable',
		name           : 'stackable',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-4',
		label          : 'Auto Apply',
		name           : 'auto_apply',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Apply To',
		name           : 'apply_to',
		options        : [
			{ label: 'Individual Options', value: 'individual_options' },
			{ label: 'Subtotal', value: 'subtotal' }
		],
		placeholder : 'Select One',
		required    : true,
		type        : 'select'
	},
	{
		adminClassSize : 'col-md-3',
		label          : 'Minimum Redemptions',
		name           : 'min_redemptions',
		placeholder    : 'Enter a number',
		required       : false,
		type           : 'number'
	},
	{
		adminClassSize : 'col-md-3',
		label          : 'Maximum Redemptions',
		name           : 'max_redemptions',
		placeholder    : 'Enter a number',
		required       : false,
		type           : 'number'
	},
	{
		adminClassSize : 'col-md-9',
		label          : 'Method',
		name           : 'method',
		options        : [
			{ label: 'Fixed Amount', value: 'amount' },
			{ label: 'Percentage', value: 'percent' }
		],
		placeholder : 'Select One',
		required    : true,
		type        : 'select'
	},
	{
		adminClassSize : 'col-md-3',
		label          : 'Amount',
		name           : 'amount',
		placeholder    : '$100.00',
		required       : true,
		type           : 'money',
		display        : {
			method : [
				'amount'
			]
		}
	},
	{
		adminClassSize : 'col-md-3',
		inputClasses   : 'text-right',
		label          : 'Percent',
		name           : 'percent',
		placeholder    : '20.00%',
		required       : true,
		type           : 'percent',
		display        : {
			method : [
				'percent'
			]
		}
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Effective Dates',
		name           : 'effective_dates',
		options        : [
			{ label: 'Event Dates', value: 'event' },
			{ label: 'Exact Dates', value: 'exact' },
			{ label: 'None', value: 'none' }
		],
		placeholder : 'Select One',
		required    : true,
		type        : 'select'
	},
	{
		adminClassSize : 'col-md-6',
		type           : 'spacer',
		display        : {
			effective_dates : [
				'none',
				'event'
			]
		}
	},
	{
		adminClassSize : 'col-md-3',
		label          : 'Effective From',
		name           : 'from_integer',
		placeholder    : 'Enter a number',
		required       : false,
		type           : 'number',
		display        : {
			effective_dates : [
				'event'
			]
		}
	},
	{
		adminClassSize    : 'col-md-3 d-flex align-items-end',
		inputOuterClasses : 'w-100',
		label             : '',
		name              : 'from_unit',
		options           : [
			{ label: 'Days', value: 'days' },
			{ label: 'Weeks', value: 'weeks' },
			{ label: 'Months', value: 'months' }
		],
		placeholder : 'Select One',
		required    : true,
		type        : 'select',
		display     : {
			effective_dates : [
				'event'
			]
		}
	},
	{
		adminClassSize : 'col-md-3',
		label          : 'Effective To',
		name           : 'to_integer',
		placeholder    : 'Enter a number',
		required       : false,
		type           : 'number',
		display        : {
			effective_dates : [
				'event'
			]
		}
	},
	{
		adminClassSize    : 'col-md-3 d-flex align-items-end',
		inputOuterClasses : 'w-100',
		label             : '',
		name              : 'to_unit',
		options           : [
			{ label: 'Days', value: 'days' },
			{ label: 'Weeks', value: 'weeks' },
			{ label: 'Months', value: 'months' }
		],
		placeholder : 'Select One',
		required    : true,
		type        : 'select',
		display     : {
			effective_dates : [
				'event'
			]
		}
	},
	{
		adminClassSize   : 'col-md-6',
		label            : 'Effective Dates',
		name             : 'from_date',
		required         : true,
		type             : 'datepicker',
		dateType         : 'daterange',
		startPlaceholder : 'Start Date',
		endPlaceholder   : 'End Date',
		value            : (current, moment) => {
			return [
				current.from_date ? moment(current.from_date, 'YYYY-MM-DD HH:mm:ss').toDate() : '',
				current.to_date ? moment(current.to_date, 'YYYY-MM-DD HH:mm:ss').toDate() : ''
			]
		},
		input : (current, moment, dates) => {
			if (!dates) {
				current.from_date = null
				current.to_date   = null
				return
			}
			if (dates[0]) {
				current.from_date = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss')
			}
			if (dates[1]) {
				current.to_date = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss')
			}
		},
		validation : [
			(v, fields) => !!v || 'exact' !== fields.effective_dates || 'The Effective Date fields are required'
		],
		display : {
			effective_dates : [
				'exact'
			]
		}
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Referral Discount',
		name           : 'referral',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-9',
		label          : 'Referral Registration',
		name           : 'referral_registration_id',
		placeholder    : 'Referral Registration (Type to search...)',
		type           : 'ajax',
		options        : 'mappedRegistrations',
		display        : {
			referral : true
		}
	},
	{
		adminClassSize : 'col-md-3',
		label          : 'Referral Amount',
		name           : 'referral_amount',
		placeholder    : '$10.00',
		type           : 'money',
		display        : {
			referral : true
		}
	},
	// {
	//  adminClassSize : 'col-md-3',
	//  label          : 'Max Referral Amount',
	//  name           : 'referral_max_amount',
	//  placeholder    : '$100.00',
	//  type           : 'money',
	//  display        : {
	//      referral : true
	//  }
	// },
	{
		adminClassSize : 'col-md-12',
		label          : 'Group Discount',
		name           : 'group',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-4',
		label          : 'Group Name',
		name           : 'group_name',
		placeholder    : 'Group Name',
		required       : true,
		type           : 'text',
		validation     : [
			(v, fields) => !!v || !fields.group || 'The Group Name field is required'
		],
		display : {
			group : true
		}
	},
	{
		adminClassSize : 'col-md-4',
		label          : 'Group Contact',
		name           : 'group_contact',
		placeholder    : 'Group Contact',
		required       : true,
		type           : 'text',
		validation     : [
			(v, fields) => !!v || !fields.group || 'The Group Contact field is required'
		],
		display : {
			group : true
		}
	},
	{
		adminClassSize : 'col-md-4',
		label          : 'Group Phone',
		name           : 'group_phone',
		placeholder    : 'Group Phone',
		required       : true,
		type           : 'phone',
		validation     : [
			(v, fields) => !!v || !fields.group || 'The Group Phone field is required'
		],
		display : {
			group : true
		}
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Group Notes',
		name           : 'group_notes',
		placeholder    : 'Group Notes',
		required       : true,
		type           : 'textarea',
		minHeight      : 103,
		display        : {
			group : true
		}
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Attendee Types',
		name           : 'attendee_types',
		options        : 'mappedAttendeeTypes',
		placeholder    : 'Choose one or more Attendee Types',
		required       : false,
		type           : 'select',
		multiple       : true
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Events',
		name           : 'events',
		options        : 'mappedEvents',
		placeholder    : 'Choose one or more Events',
		required       : false,
		type           : 'select',
		multiple       : true
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Financial Options',
		name           : 'financial_options',
		options        : 'mappedFinancialOptions',
		required       : false,
		type           : 'draggable'
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Notification Emails',
		name           : 'notification_emails',
		options        : 'mappedEmailTemplates',
		required       : false,
		type           : 'draggable'
	}
]

export const DONATION_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-9',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'Donation Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-3',
		label          : 'Enabled',
		name           : 'enabled',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	}
]

export const FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Label',
		name           : 'label',
		placeholder    : 'Form Field Label',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Enabled',
		name           : 'enabled',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Required',
		name           : 'required',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Type',
		name           : 'type',
		options        : [
			{ label: 'Birthdate', value: 'birthdate' },
			{ label: 'Checkbox (select multiple values)', value: 'checkbox' },
			{ label: 'Date', value: 'date' },
			{ label: 'Dropdown (select one value)', value: 'select' },
			{ label: 'Email Address', value: 'email' },
			{ label: 'Multi Line Text Box', value: 'textarea' },
			{ label: 'Phone Number', value: 'phone' },
			{ label: 'Radio Buttons (select one value)', value: 'radio' },
			{ label: 'Single Line Text Box', value: 'text' }
		],
		placeholder : 'Select One',
		required    : true,
		type        : 'select'
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Placeholder',
		name           : 'placeholder',
		placeholder    : 'Form Field Placeholder',
		required       : false,
		type           : 'text',
		display        : {
			type : [
				'birthdate',
				'date',
				'email',
				'phone',
				'text',
				'textarea'
			]
		}
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Options',
		name           : 'options',
		required       : false,
		type           : 'draggable',
		display        : {
			type : [
				'checkbox',
				'radio',
				'select'
			]
		}
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Conditional',
		name           : 'conditional',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Classes',
		name           : 'classes',
		placeholder    : 'col-md-6',
		required       : false,
		type           : 'text'
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Conditions',
		name           : 'conditions',
		required       : false,
		type           : 'draggable',
		display        : {
			conditional : true
		}
	}
]

export const CREATE_EVENT_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-6',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'My Event Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize   : 'col-md-6',
		label            : 'Event Dates',
		name             : 'start_date',
		required         : true,
		type             : 'datepicker',
		dateType         : 'daterange',
		startPlaceholder : 'Start Date',
		endPlaceholder   : 'End Date',
		value            : (current, moment) => {
			return [
				current.start_date ? moment(current.start_date, 'YYYY-MM-DD HH:mm:ss').toDate() : '',
				current.end_date ? moment(current.end_date, 'YYYY-MM-DD HH:mm:ss').toDate() : ''
			]
		},
		input : (current, moment, dates) => {
			if (!dates) {
				current.start_date = null
				current.end_date   = null
				return
			}
			if (dates[0]) {
				current.start_date = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss')
			}
			if (dates[1]) {
				current.end_date = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss')
			}
		},
		validation : [
			v => !!v || 'The Event Dates fields are required'
		]
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Event Types',
		name           : 'event_types',
		options        : 'mappedEventTypes',
		placeholder    : 'Choose one or more Event Types',
		required       : true,
		type           : 'select',
		multiple       : true,
		validation     : [
			v => !!v.length || 'The Event Types field is required'
		]
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Attendee Types',
		name           : 'attendee_types',
		options        : 'mappedAttendeeTypes',
		placeholder    : 'Choose one or more Attendee Types',
		required       : true,
		type           : 'select',
		multiple       : true,
		validation     : [
			v => !!v.length || 'The Attendee Types field is required'
		]
	},
	{
		adminClassSize : 'col-md-3',
		label          : 'Event Status',
		name           : 'status',
		placeholder    : 'Choose a status',
		required       : true,
		type           : 'select',
		options        : [
			{ label: 'Open', value: 'open' },
			{ label: 'Closed', value: 'closed' },
			{ label: 'Waitlist', value: 'waitlist' }
		],
		validation : [
			v => !!v || 'The Status field is required'
		]
	},
	{
		adminClassSize   : 'col-md-6',
		label            : 'Registration Dates',
		name             : 'open_date',
		required         : false,
		type             : 'datepicker',
		dateType         : 'datetimerange',
		startPlaceholder : 'Open Date',
		endPlaceholder   : 'Close Date',
		value            : (current, moment) => {
			return [
				current.open_date ? moment(current.open_date, 'YYYY-MM-DD HH:mm:ss').toDate() : '',
				current.close_date ? moment(current.close_date, 'YYYY-MM-DD HH:mm:ss').toDate() : ''
			]
		},
		input : (current, moment, dates) => {
			if (!dates) {
				current.open_date  = null
				current.close_date = null
				return
			}
			if (dates[0]) {
				current.open_date = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss')
			}
			if (dates[1]) {
				current.close_date = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss')
			}
		}
	},
	{
		adminClassSize : 'col-md-3',
		label          : 'Registration Time Zone',
		name           : 'time_zone',
		options        : TIME_ZONES,
		placeholder    : 'Choose a Time Zone',
		required       : false,
		type           : 'select'
	},
	{
		adminClassSize : 'col-md-8',
		label          : 'Details URL',
		name           : 'url',
		placeholder    : 'https://domain.com/event-details',
		required       : false,
		type           : 'text',
		validation     : [
			v => !v || /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(v) || 'A valid URL is required'
		]
	},
	{
		adminClassSize : 'col-md-4',
		label          : 'Enable Travel Options',
		name           : 'travel_options',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-8',
		label          : 'Event Registration Link',
		name           : 'eventRegistrationLink',
		context        : 'edit',
		required       : false,
		type           : 'readonly',
		value          : current => {
			return process.env.VUE_APP_PUBLIC_URL + '/register/' + current.id
		}
	},
	{
		adminClassSize : 'col-md-4',
		label          : 'Private Event',
		name           : 'private',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Description',
		name           : 'description',
		placeholder    : 'Enter a description',
		required       : false,
		type           : 'editor'
	}
]

export const EVENT_TYPE_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'Event Type Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Enabled',
		name           : 'enabled',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Attendee Types',
		name           : 'attendee_types',
		options        : 'mappedAttendeeTypes',
		placeholder    : 'Choose one or more Attendee Types',
		required       : false,
		type           : 'select',
		multiple       : true
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Description',
		name           : 'description',
		placeholder    : 'Select One',
		required       : false,
		type           : 'editor'
	}
]

export const EVENT_CONTACT_FORM_FIELDS = [
	{
		adminClassSize : 'col-lg-6',
		label          : 'Contact 1 First Name',
		name           : 'contact_first_name1',
		placeholder    : 'John',
		required       : false
	},
	{
		adminClassSize : 'col-lg-6',
		label          : 'Contact 1 Last Name',
		name           : 'contact_last_name1',
		placeholder    : 'Doe',
		required       : false
	},
	{
		adminClassSize : 'col-lg-12',
		label          : 'Contact 1 Email Address',
		name           : 'contact_email1',
		placeholder    : 'john@domain.com',
		required       : false,
		type           : 'email',
		validation     : [
			v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please enter a valid email address'
		]
	},
	{
		adminClassSize : 'col-lg-6',
		label          : 'Contact 2 First Name',
		name           : 'contact_first_name2',
		placeholder    : 'Jane',
		required       : false
	},
	{
		adminClassSize : 'col-lg-6',
		label          : 'Contact 2 Last Name',
		name           : 'contact_last_name2',
		placeholder    : 'Doe',
		required       : false
	},
	{
		adminClassSize : 'col-lg-12',
		label          : 'Contact 2 Email Address',
		name           : 'contact_email2',
		placeholder    : 'jane@domain.com',
		required       : false,
		type           : 'email',
		validation     : [
			v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please enter a valid email address'
		]
	}
]

export const EVENT_ATTENDEE_TYPES_DETAILS_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-8',
		label          : 'Attendee Type Registration Link',
		name           : 'attendeeTypeRegistrationLink',
		required       : false,
		type           : 'readonly',
		value          : (event, current) => {
			return process.env.VUE_APP_PUBLIC_URL + '/register/' + event.id + '/' + current.id
		}
	},
	{
		adminClassSize   : 'col-md-6',
		label            : 'Event Dates for this Attendee Type',
		name             : 'event_start_date',
		required         : false,
		type             : 'datepicker',
		dateType         : 'daterange',
		startPlaceholder : 'Start Date',
		endPlaceholder   : 'End Date',
		value            : (current, moment) => {
			return [
				current.event_start_date ? moment(current.event_start_date, 'YYYY-MM-DD HH:mm:ss').toDate() : '',
				current.event_end_date ? moment(current.event_end_date, 'YYYY-MM-DD HH:mm:ss').toDate() : ''
			]
		},
		input : (current, moment, dates) => {
			if (!dates) {
				current.event_start_date = null
				current.event_end_date   = null
				return
			}
			if (dates[0]) {
				current.event_start_date = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss')
			}
			if (dates[1]) {
				current.event_end_date = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss')
			}
		}
	},
	{
		adminClassSize : 'col-md-2',
		label          : 'Allow Deposit',
		name           : 'allow_deposit',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	},
	{
		adminClassSize : 'col-md-4',
		label          : 'Deposit Amount',
		name           : 'deposit_amount',
		placeholder    : '$100.00',
		required       : false,
		type           : 'money'
	}
]

export const TRAVEL_OPTION_TYPES_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'Travel Option Type Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	}
]

export const TRAVEL_OPTION_TYPE_OPTIONS_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Label',
		name           : 'label',
		placeholder    : 'Travel Option Label',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Label field is required'
		]
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Travel Option Type',
		name           : 'travel_option_type_id',
		options        : 'mappedTravelOptionTypes',
		placeholder    : 'Select One',
		required       : false,
		type           : 'select'
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Type',
		name           : 'type',
		options        : [
			{ label: 'Date', value: 'date' },
			{ label: 'Multi Line Text Box', value: 'textarea' },
			{ label: 'Phone Number', value: 'phone' },
			{ label: 'Single Line Text Box', value: 'text' },
			{ label: 'Time', value: 'time' }
		],
		placeholder : 'Select One',
		required    : true,
		type        : 'select'
	}
]

export const CREATE_REPORT_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'My Report Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Report Format',
		name           : 'type',
		placeholder    : 'Choose a type',
		required       : true,
		type           : 'select',
		options        : [
			{ label: 'Registration', value: 'registration' },
			{ label: 'Transaction', value: 'transaction' },
			{ label: 'Donation', value: 'financial' }
		],
		validation : [
			v => !!v || 'The Type field is required'
		]
	},
	{
		adminClassSize : 'col-md-12',
		label          : 'Report Types',
		name           : 'report_types',
		options        : 'mappedReportTypes',
		placeholder    : 'Choose one or more Report Types',
		required       : true,
		type           : 'select',
		multiple       : true,
		validation     : [
			v => !!v.length || 'The Report Types field is required'
		]
	}
]

export const REPORT_TYPE_FORM_FIELDS = [
	{
		adminClassSize : 'col-md-12',
		label          : 'Name',
		name           : 'name',
		placeholder    : 'Report Type Name',
		required       : true,
		type           : 'text',
		validation     : [
			v => !!v || 'The Name field is required'
		]
	},
	{
		adminClassSize : 'col-md-6',
		label          : 'Enabled',
		name           : 'enabled',
		required       : false,
		type           : 'switch',
		onText         : 'Yes',
		offText        : 'No'
	}
]

export const DEFAULT_REPORT_COLUMNS = {
	registration : {
		name    : 'Registration Data',
		columns : [
			{ name: 'Amount Due', value: 'amount_due' },
			{ name: 'Attendee Type', value: 'attendee_type' },
			{ name: 'Confirmation Number', value: 'confirmation_number' },
			{ name: 'Event Start Date', value: 'event_start_date' },
			{ name: 'Event End Date', value: 'event_end_date' },
			{ name: 'Event Name', value: 'event_name' },
			{ name: 'Registration Date', value: 'registration_date' },
			{ name: 'Confirmation Date', value: 'confirmation_date' },
			{ name: 'Cancellation Date', value: 'cancellation_date' },
			{ name: 'Transfer Date', value: 'transfer_date' },
			{ name: 'Registration Notes', value: 'registration_notes' },
			{ name: 'Registration Status', value: 'registration_status' }
		]
	},
	account : {
		name    : 'Account',
		columns : [
			{ name: 'Account First Name', value: 'account_first_name' },
			{ name: 'Account Last Name', value: 'account_last_name' },
			{ name: 'Account Email Address', value: 'account_email', fieldType: 'email' },
			{ name: 'Account Phone', value: 'account_phone' },
			{ name: 'Account Address Line 1', value: 'account_address1' },
			{ name: 'Account Address Line 2', value: 'account_address2' },
			{ name: 'Account City', value: 'account_city' },
			{ name: 'Account State', value: 'account_state' },
			{ name: 'Account Zip', value: 'account_zip' },
			{ name: 'Account Country', value: 'account_country' },
			{ name: 'Account Notes', value: 'account_notes' },
			{ name: 'Account Restricted', value: 'account_restricted' }
		]
	},
	attendee : {
		name    : 'Attendee',
		columns : [
			{ name: 'Attendee First Name', value: 'attendee_first_name' },
			{ name: 'Attendee Last Name', value: 'attendee_last_name' },
			{ name: 'Attendee Email Address', value: 'attendee_email', fieldType: 'email' },
			{ name: 'Attendee Phone', value: 'attendee_phone' },
			{ name: 'Attendee Address Line 1', value: 'attendee_address1' },
			{ name: 'Attendee Address Line 2', value: 'attendee_address2' },
			{ name: 'Attendee City', value: 'attendee_city' },
			{ name: 'Attendee State', value: 'attendee_state' },
			{ name: 'Attendee Zip', value: 'attendee_zip' },
			{ name: 'Attendee Country', value: 'attendee_country' },
			{ name: 'Attendee Gender', value: 'attendee_gender' },
			{ name: 'Attendee Birthdate', value: 'attendee_birthdate' },
			{ name: 'Attendee Notes', value: 'attendee_notes' }
		]
	},
	financialOptions : {
		name    : 'Financial Options',
		columns : [
			{ name: 'Item Amount', value: 'financial_item_amount' },
			{ name: 'Item Total', value: 'financial_item_total' },
			{ name: 'Quantity', value: 'financial_item_quantity' }
		]
	},
	discounts : {
		name    : 'Discounts',
		columns : []
	},
	travelOptions : {
		name    : 'Travel Options',
		columns : []
	},
	questions : {
		name    : 'Questions',
		columns : []
	},
	donations : { // @TODO: Flesh this out in the PHP side and in ReportsPane.vue --- should only show up if the type is 'financial'
		name    : 'Donations',
		columns : [
			{ name: 'Donation Name', value: 'donation_name' },
			{ name: 'Donation Amount', value: 'donation_amount' }
		]
	},
	transactions : {
		name    : 'Transaction Data',
		columns : [
			{ name: 'Payment Gateway', value: 'transactions_gateway' },
			{ name: 'Payment Amount', value: 'transactions_amount' },
			{ name: 'Refund Amounts', value: 'transactions_refund_amounts' },
			{ name: 'Registration Amounts', value: 'transactions_registration_amounts' },
			{ name: 'Transaction ID', value: 'transactions_id' },
			{ name: 'Check Number', value: 'transactions_check_number' },
			{ name: 'Credit Card Type', value: 'transactions_credit_card_type' },
			{ name: 'Last Four Digits', value: 'transactions_last_four_digits' },
			{ name: 'Transaction Date', value: 'transactions_date' },
			{ name: 'Notes', value: 'transactions_notes' }
		]
	}
}