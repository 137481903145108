import router from '@/registration/router'

export default {
	fetchAll ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/attendees/',
			then   : response => commit('addAll', response.body)
		})
	},
	add ({ commit, state }, redirect) {
		const payload = { ...state.current }
		//  // We don't need these options.
		delete payload.id
		delete payload.account_id
		delete payload.created
		delete payload.updated

		// Reformat the fields being sent.
		payload.fields = { ...payload.fields.values }

		return this._vm.$api({
			method : 'post',
			url    : '/attendee/',
			send   : payload,
			then   : (response, resolve) => {
				commit('add', response.body)

				if (redirect) {
					router.push({ name: 'attendees', query: { aa: response.body.id } })
				}

				resolve(response.body)
			}
		})
	},
	edit ({ state, dispatch }, redirect) {
		const payload = { ...state.current }

		// We don't need these options.
		delete payload.id
		delete payload.account_id
		delete payload.created
		delete payload.updated

		// Reformat the fields being sent.
		payload.fields = { ...payload.fields.values }
		return this._vm.$api({
			method : 'put',
			url    : `/attendee/${state.current.id}/`,
			send   : payload,
			then   : (response, resolve) => {
				// The format of the phone number may have changed, let's update it in state now.
				dispatch('update', { phone: response.body.phone || '' })
				dispatch('update', { ...response.body.fields.values, _fields: true })

				if (redirect) {
					router.push({ name: 'attendees', query: { au: state.current.id } })
				}

				resolve(response.body)
			}
		})
	},
	update ({ commit, state }, payload) {
		return new Promise(resolve => {
			if (payload._fields) {
				delete payload._fields
				const { fields } = state.current
				Object.keys(payload).forEach(key => {
					fields.values[key] = payload[key]
				})
				commit('setFieldValues', fields.values)

				commit('setCurrent', {
					...state.current
				})

				return resolve()
			}

			commit('setCurrent', {
				...state.current,
				...payload
			})

			resolve()
		})
	},
	setCurrent ({ commit, getters }, id) {
		return new Promise(resolve => {
			const attendee = getters.get(id)
			commit('setCurrent', attendee)

			resolve()
		})
	}
}