<template>
	<div class="gateway check">
		<br>
		<aside
			v-if="!$store.state.inAdmin"
		>
			<div v-html="gateway.checkGatewayConfirmation" />
		</aside>
		<div class="row account">
			<div class="col-md-4">
				<label>Check Number</label>
				<input
					class="form-control"
					type="text"
					:value="checkNumber"
					@input="setCheckNumber($event.target.value)"
					placeholder="1234"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
	computed : {
		...mapGetters('payment', [ 'checkNumber' ]),
		...mapState('settings', [ 'gateway' ])
	},
	methods : {
		...mapMutations('payment', [ 'setCheckNumber' ]),
		submitPayment (billing, resolve, reject) {
			if (!this.checkNumber) {
				return reject(new Error('A check number is required.'))
			}

			resolve()
		}
	},
	mounted () {
		this.$bus.$on('submit-payment-check', this.submitPayment)
	},
	beforeDestroy () {
		this.$bus.$off('submit-payment-check', this.submitPayment)
	}
}
</script>