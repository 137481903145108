import * as a from '@/admin/store/commonActions'

const data = {
	route     : 'registration',
	routeAll  : 'registrations',
	routeSlug : 'registration',
	label     : 'Registration',
	class     : 'Registration',
	slug      : 'registration'
}

export default {
	fetchLogs (context, payload) {
		return a.fetchLogs(this._vm, context, data, payload)
	},
	fetchAll (context, payload) {
		return a.fetchAll(this._vm, context, data, payload)
	},
	fetchAllArchives (context, payload) {
		return a.fetchAllArchives(this._vm, context, data, payload)
	},
	fetch (context, payload) {
		return a.fetch(this._vm, context, data, payload)
	},
	fetchEmails ({ commit }, registrationId) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/registration/${registrationId}/emails/`,
			then   : response => commit('setEmails', response.body)
		})
	},
	search ({ commit }, searchTerm) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/registration/search/?term=${searchTerm}`,
			then   : response => commit('setRegistrationSearch', response.body)
		})
	},
	update ({ commit, state, dispatch }, payload) {
		let registrationId = state.current.key
		if (!registrationId) {
			registrationId = payload.id
		}

		return this._vm.$api({
			method : 'put',
			send   : payload,
			url    : `/admin/registration/${registrationId}/`,
			then   : response => {
				commit('setCurrent', response.body)
				dispatch('fetchLogs', state.current.id || registrationId)
			}
		})
	},
	refundTransaction ({ commit, state }, { amount, transaction, reason, accountCredit, expires, expiresType }) {
		return this._vm.$api({
			method : 'post',
			send   : { amount, reason, accountCredit, expires: { expires, expiresType }, registration_id: state.current.id },
			url    : `/admin/payment/${transaction.transaction_id}/refund/`,
			then   : response => commit('updateTransaction', response.body)
		})
	},
	editTransaction ({ commit, state }, {
		// amount, // Maybe add this back in one day when we want to allow editing check amounts.
		transaction,
		checkNumber,
		notes
	}) {
		return this._vm.$api({
			method : 'put',
			send   : {
				// amount, Maybe add this back in one day when we want to allow editing check amounts.
				checkNumber,
				notes,
				registration_id : state.current.id
			},
			url  : `/admin/payment/${transaction.transaction_id}/`,
			then : response => commit('updateTransaction', response.body)
		})
	},
	resendTransactionReceipt ({ state }, transaction) {
		return this._vm.$api({
			method       : 'post',
			url          : `/admin/registration/${state.current.key}/email-transaction-receipt/${transaction.transaction_id}/`,
			notification : {
				type    : 'success',
				message : 'Transaction receipt succesfully sent!',
				icon    : 'fas fa-bell'
			}
		})
	},
	resendEmailReceipt ({ state }) {
		return this._vm.$api({
			method       : 'post',
			url          : `/admin/registration/${state.current.id}/email-receipt/`,
			notification : {
				type    : 'success',
				message : 'Email receipt succesfully sent!',
				icon    : 'fas fa-bell'
			}
		})
	},
	resendConfirmationEmail ({ state }) {
		return this._vm.$api({
			method       : 'post',
			url          : `/admin/registration/${state.current.id}/email-confirmation/`,
			notification : {
				type    : 'success',
				message : 'Email confirmation succesfully sent!',
				icon    : 'fas fa-bell'
			}
		})
	},
	sendBalanceDueEmail ({ state }) {
		return this._vm.$api({
			method       : 'post',
			url          : `/admin/registration/${state.current.id}/email-balance-due/`,
			notification : {
				type    : 'success',
				message : 'Balance Due Email succesfully sent!',
				icon    : 'fas fa-bell'
			}
		})
	},
	transferRegistration ({ state, commit, dispatch }, payload) {
		return this._vm.$api({
			method : 'post',
			url    : `/admin/registration/${state.current.id}/transfer/`,
			send   : payload,
			then   : response => {
				commit('setCurrent', response.body)
				dispatch('fetchLogs', state.current.id)
			}
		})
	},
	disableBilling ({ dispatch, commit }, registrationId) {
		commit('toggleDisableBilling', true)
		return dispatch('update', {
			id              : registrationId,
			disable_billing : true
		})
	},
	enableBilling ({ dispatch, commit }, registrationId) {
		commit('toggleDisableBilling', false)
		return dispatch('update', {
			id              : registrationId,
			disable_billing : false
		})
	},
	fetchAllTravelOptions ({ commit }, { event, attendeeType }) {
		return this._vm.$api({
			method : 'get',
			url    : `/travel-options/?event=${event}&attendeeType=${attendeeType}`,
			then   : response => {
				commit('addAllTravelOptions', response.body)
			}
		})
	}
}