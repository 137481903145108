import {
	NoAuth
} from '@/middleware'
import {
	Logout,
	Auth,
	Allowed
} from '@/admin/middleware'
import { loadView } from './load'

import accounts from './accounts'
import attendees from './attendees'
import calendar from './calendar'
import discounts from './discounts'
import donations from './donations'
import emailTemplates from './email-templates'
import events from './events'
import financialOptions from './financial-options'
import formFields from './form-fields'
import questions from './questions'
import registrations from './registrations'
import reports from './reports'
import travelOptions from './travel-options'
import stats from './stats'

/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
	{
		path      : '/',
		name      : 'admin-dashboard',
		component : loadView('Dashboard'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Dashboard'
		}
	},
	{
		path      : '/settings',
		name      : 'admin-settings',
		component : loadView('settings/Settings'),
		meta      : {
			middleware : [ Auth, Allowed ]
		}
	},
	{
		path      : '/auto-billing',
		name      : 'admin-auto-billing',
		component : loadView('auto-billing/AutoBilling'),
		meta      : {
			middleware : [ Auth, Allowed ]
		}
	},
	...stats,
	...accounts,
	...attendees,
	...calendar,
	...discounts,
	...donations,
	...emailTemplates,
	...events,
	...financialOptions,
	...formFields,
	...questions,
	...registrations,
	...reports,
	...travelOptions,
	{
		path      : '/login',
		name      : 'admin-login',
		component : loadView('login/Login'),
		meta      : {
			middleware : [ NoAuth ],
			label      : 'Login'
		}
	},
	{
		path : '/logout',
		name : 'admin-logout',
		meta : {
			middleware : [ Logout ],
			label      : 'Log Out'
		},
		component : loadView('LogOut')
	},
	{
		path : '/password-reset',
		name : 'admin-password-reset',
		meta : {
			middleware : [ NoAuth ],
			label      : 'Password Reset'
		},
		component : loadView('login/PasswordReset')
	}
]