export const CREATE = {
	event_types         : [],
	attendee_types      : [],
	event_season_id     : null,
	name                : null,
	description         : '',
	private             : 0,
	url                 : null,
	status              : null,
	open_date           : null,
	close_date          : null,
	time_zone           : null,
	start_date          : null,
	end_date            : null,
	contact_first_name1 : null,
	contact_last_name1  : null,
	contact_email1      : null,
	contact_first_name2 : null,
	contact_last_name2  : null,
	travel_options      : false,
	archived            : false
}

export const CURRENT = {
	id                              : null,
	...CREATE,
	attendee_type_details           : {},
	attendee_type_questions         : {},
	attendee_type_financial_options : {},
	attendee_type_travel_options    : {},
	attendee_type_emails            : {},
	event_capacities                : []
}