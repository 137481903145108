<template>
	<form
		@submit.prevent="nextPage"
		novalidate
	>

		<div
			:class="section.slug"
			v-for="section in sections"
			:key="section.slug"
		>
			<h4>{{ section.name }} Travel Options</h4>

			<div class="row radio-group">
				<div
					v-if="!admin"
					class="col-12"
				>
					<label
						class="radio-label"
						v-for="travelOption in allTravelOptions"
						:key="section.slug + travelOption.id"
					>
						<input
							type="radio"
							:name="section.slug"
							:value="travelOption.id"
							v-model="$data[section.slug]"
							class="regular-radio"
						/>
						{{ travelOption.name }}
					</label>
				</div>

				<div
					v-if="admin"
					class="col-12"
				>
					<base-input
						label=""
						type="radio"
					>
						<base-radio
							v-for="travelOption in adminTravelOptions"
							:key="section.slug + travelOption.value"
							:name="travelOption.value"
							v-model="$data[section.slug]"
							inline
						>
							{{ travelOption.label }}
						</base-radio>
					</base-input>
				</div>
			</div>

			<div
				v-for="travelOption in allTravelOptions"
				:key="travelOption.id"
			>
				<div
					v-if="!admin && showSection(section.slug, travelOption.id)"
					class="row account"
				>
					<div
						:class="{
							'col-md-6': 'textarea' !== option.type,
							'col-md-12': 'textarea' === option.type
						}"
						v-for="option in travelOption.options"
						:key="option.id"
					>
						<label
							:for="section.slug + '-' + travelOption.id + '-' + option.id"
						>{{ option.label }}</label>
						<input
							v-if="'text' === option.type || 'phone' === option.type"
							class="form-control"
							type="text"
							v-model="$data[section.slug + 'Options'][option.id]"
							:name="option.id"
							:id="section.slug + '-' + travelOption.id + '-' + option.id"
						/>
						<textarea
							v-if="'textarea' === option.type"
							class="form-control"
							v-model="$data[section.slug + 'Options'][option.id]"
							:name="option.id"
							:id="section.slug + '-' + travelOption.id + '-' + option.id"
						/>
						<input-date
							v-if="'date' === option.type"
							:parent="section.slug"
							:date="$data[section.slug + 'Options'][option.id]"
							:name="option.id"
							@change="onChange($event, section)"
						/>
						<input-time
							v-if="'time' === option.type"
							:parent="section.slug"
							:time="$data[section.slug + 'Options'][option.id]"
							:name="option.id"
							@change="onChange($event, section)"
						/>
					</div>
				</div>

				<div
					v-if="admin && showSection(section.slug, travelOption.id)"
					class="row account"
				>
					<div
						:class="{
							'col-md-6': 'textarea' !== option.type,
							'col-md-12': 'textarea' === option.type
						}"
						v-for="option in travelOption.options"
						:key="option.id"
					>
						<base-input
							v-if="'text' !== option.type && 'phone' !== option.type"
							:label="option.label"
						>
							<textarea-autosize
								v-if="'textarea' === option.type"
								v-model="$data[section.slug + 'Options'][option.id]"
								class="form-control"
								:min-height="63"
							/>
							<base-time-picker
								v-if="'time' === option.type"
								:value="$data[section.slug + 'Options'][option.id]"
								@input="setData(section.slug, option.id, $event)"
							/>
							<base-date-picker
								v-if="'date' === option.type"
								:value="$data[section.slug + 'Options'][option.id]"
								@input="setData(section.slug, option.id, $event)"
							/>
						</base-input>

						<base-input
							v-if="'text' === option.type || 'phone' === option.type"
							:label="option.label"
							v-model="$data[section.slug + 'Options'][option.id]"
						/>
					</div>
				</div>
			</div>
		</div>

		<register-buttons
			v-if="registrationKey"
			:registrationKey="registrationKey"
			:buttons="buttons"
			:nextButtonText="$route.query.edit ? 'Update' : (-1 !== buttons.indexOf('update') ? 'Update' : 'Next Page')"
		/>
	</form>
</template>

<script>
import { mapInstanceGetters } from '@/admin/utils/vuex-helpers'
export default {
	props : {
		buttons : {
			type     : [ Array, Boolean ],
			required : true
		},
		defaults        : Object,
		registrationKey : {
			type     : [ String, Boolean ],
			required : true
		},
		admin  : Boolean,
		submit : Boolean
	},
	data () {
		return {
			useArrivingDefaults  : true,
			useDepartingDefaults : true,
			arriving             : null,
			arrivingOptions      : {},
			departing            : null,
			departingOptions     : {},
			sections             : [
				{
					name : 'Arrival',
					slug : 'arriving'
				},
				{
					name : 'Departure',
					slug : 'departing'
				}
			]
		}
	},
	computed : {
		...mapInstanceGetters(computed => computed.admin ? 'registrations' : 'travel', [ 'allTravelOptions' ]),
		adminTravelOptions () {
			return this.allTravelOptions.map(to => ({ label: to.name, value: to.id }))
		}
	},
	methods : {
		setData (sectionSlug, optionId, value) {
			this.$set(this[sectionSlug + 'Options'], optionId, value)
		},
		showSection (sectionSlug, travelOptionId) {
			return travelOptionId === this[sectionSlug]
		},
		onChange (event, section) {
			this.$set(this[section.slug + 'Options'], event.key, event.value)
		},
		nextPage () {
			const arrivingOptions  = []
			Object.keys(this.arrivingOptions).forEach(k => {
				const options = this.allTravelOptions
					.filter(travelOption => travelOption.id === this.arriving)[0].options
					.filter(option => option.id === k)
				if (options.length) {
					const value = !this.admin || ('date' !== options[0].type && 'time' !== options[0].type)
						? this.arrivingOptions[k]
						: (
							'date' === options[0].type
								? this.$moment(this.arrivingOptions[k]).format('MM/DD/YYYY')
								: this.$moment(this.arrivingOptions[k]).format('hh:mm A')
						)
					arrivingOptions.push({
						id    : k,
						label : options[0].label,
						value
					})
				}
			})

			const departingOptions = []
			Object.keys(this.departingOptions).forEach(k => {
				const options = this.allTravelOptions
					.filter(travelOption => travelOption.id === this.departing)[0].options
					.filter(option => option.id === k)
				if (options.length) {
					const value = !this.admin || ('date' !== options[0].type && 'time' !== options[0].type)
						? this.departingOptions[k]
						: (
							'date' === options[0].type
								? this.$moment(this.departingOptions[k]).format('MM/DD/YYYY')
								: this.$moment(this.departingOptions[k]).format('hh:mm A')
						)
					departingOptions.push({
						id    : k,
						label : options[0].label,
						value
					})
				}
			})

			const arriving  = this.allTravelOptions.filter(travelOption => travelOption.id === this.arriving)
			const departing = this.allTravelOptions.filter(travelOption => travelOption.id === this.departing)
			this.$emit('update', {
				arriving : {
					id      : this.arriving,
					name    : arriving && arriving.length ? arriving[0].name : null,
					options : arrivingOptions
				},
				departing : {
					id      : this.departing,
					name    : departing && departing.length ? departing[0].name : null,
					options : departingOptions
				}
			})
		}
	},
	watch : {
		arriving () {
			if (!this.useArrivingDefaults) {
				this.arrivingOptions = {}
			}
			this.useArrivingDefaults = false
		},
		departing () {
			if (!this.useDepartingDefaults) {
				this.departingOptions = {}
			}
			this.useDepartingDefaults = false
		},
		submit (newValue) {
			if (newValue) {
				this.nextPage()
			}
		}
	},
	mounted () {
		this.$nextTick(() => {
			Object.keys(this.defaults).forEach(key => {
				this.$set(this, key, this.defaults[key] || null)
			})
		})
	}
}
</script>

<style lang="scss">
.arriving,
.departing {
	margin-bottom: 40px;
}
</style>