import Percent from './component.vue'

export {
	Percent
}

function install (Vue) {
	Vue.component('percent', Percent)
}

export default install

// Install by default if included from script tag
if ('undefined' !== typeof window && window.Vue) {
	window.Vue.use(install)
}