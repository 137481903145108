import { CREATE, CURRENT } from './defaults'
import * as m from '@/admin/store/commonMutations'

const {
	setPayload,
	setPagination,
	...mutations
} = m

export default {
	...mutations,
	resetNew (state) {
		setPayload(this._vm, state, 'newModel', { ...CREATE })
	},
	resetCurrent (state) {
		setPayload(this._vm, state, 'current', { ...CURRENT })
	},
	setPagination (state, payload) {
		setPagination(this._vm, state, payload)
	},
	setAccountSearch (state, payload) {
		state.searchResults = payload
	},
	setCurrentAttendees (state, payload) {
		this._vm.$set(state.current, 'attendees', payload)
	},
	setCurrentRegistrations (state, payload) {
		this._vm.$set(state.current, 'registrations', payload)
	},
	setCurrentCredit (state, payload) {
		this._vm.$set(state.current, 'credit', payload)
	},
	addCurrentCredit (state, payload) {
		const credit = state.current.credit

		credit.push(payload)

		this._vm.$set(state.current, 'credit', credit)
	},
	editCurrentCredit (state, payload) {
		const credit = state.current.credit
		const index  = credit.findIndex(c => c.id === payload.id)

		if (-1 !== index) {
			this._vm.$set(state.current.credit, index, payload)
		}
	},
	removeCurrentCredit (state, payload) {
		const credit = state.current.credit
		const index  = credit.findIndex(c => c.id === payload.id)

		if (-1 !== index) {
			this._vm.$delete(state.current.credit, index)
		}
	},
	setCurrentCreditCards (state, payload) {
		this._vm.$set(state.current, 'creditCards', payload)
	},
	removeCreditCard (state, creditCardId) {
		const cardIndex = state.current.creditCards.findIndex(c => c.id === creditCardId)
		if (-1 !== cardIndex) {
			this._vm.$delete(state.current.creditCards, cardIndex)
		}
	},
	setCurrentEmails (state, payload) {
		this._vm.$set(state.current, 'emails', payload)
	},
	setFormFields (state, payload) {
		state.newModel.fields.all = payload
	},
	setAllStateCoordinators (state, allStateCoordinators) {
		state.allStateCoordinators = allStateCoordinators
	},
	setDonations (state, payload) {
		state.donations = payload
	},
	setEmails (state, payload) {
		state.emails = payload
	},
	updatePermissions (state, { type, permissions }) {
		const deleteIndexes = []
		state.current.permissions.forEach((p, i) => {
			if (type === p.type) {
				deleteIndexes.push(i)
			}
		})

		for (var i = deleteIndexes.length - 1; 0 <= i; i--) {
			this._vm.$delete(state.current.permissions, deleteIndexes[i])
		}

		const allPermissions = state.current.permissions.concat(permissions)
		this._vm.$set(state.current, 'permissions', allPermissions)
	},
	toggleRestricted (state, payload) {
		state.current.restricted = payload
	}
}