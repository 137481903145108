import router from '@/registration/router'
import sha1 from 'js-sha1'

const parseTruthyValue = value => {
	if (!value) {
		return false
	}

	switch (value.toLowerCase()) {
		case 'yes':
		case 'y':
		case '1':
		case 'true':
			return true
		default:
			return false
	}
}

export default {
	async import ({ rootState, dispatch, commit, state, rootGetters }, registrations) {
		const attendees             = [ ...rootState.attendees.attendees ]
		const registrationAttendees = []

		commit('setImportTotal', registrations.length)
		commit('setImportStage', 'attendee')
		for (let index = 0; index < registrations.length; index++) {
			commit('setImportCount', index + 1)
			const registration = registrations[index]
			const firstName = await dispatch('getImportRow', 'Attendee First Name')
			const lastName  = await dispatch('getImportRow', 'Attendee Last Name')
			let attendee    = attendees.find(a => a.first_name === registration[firstName] && a.last_name === registration[lastName])
			if (attendee) {
				attendee.importData = registration
				attendee            = await dispatch('updateImportedAttendee', attendee)
				const attendeeId    = attendee.id

				// We don't need these fields.
				delete attendee.id
				delete attendee.account_id
				delete attendee.created
				delete attendee.updated

				// Reformat the fields being sent.
				attendee.fields = { ...attendee.fields.values }
				attendee        = await this._vm.$api({
					method : 'put',
					url    : `/attendee/${attendeeId}/`,
					send   : attendee,
					then   : (response, resolve) => {
						resolve(response.body)
					}
				})

				attendee.importData = registration

				registrationAttendees.push(attendee)
				continue
			}

			attendee = {
				first_name : registration[firstName],
				last_name  : registration[lastName],
				fields     : {
					all    : [ ...rootState.attendees.fields ],
					values : {}
				}
			}

			attendee.importData = registration
			attendee            = await dispatch('updateImportedAttendee', attendee)

			// Refactor the fields to create the attendee.
			attendee.fields     = { ...attendee.fields.values }
			attendee            = await this._vm.$api({
				method : 'post',
				url    : '/attendee/',
				send   : attendee,
				then   : (response, resolve) => {
					resolve(response.body)
				}
			})

			attendee.importData = registration

			registrationAttendees.push(attendee)
		}

		commit('setImportStage', 'registration')
		commit('setImportCount', 0)
		for (let index = 0; index < registrationAttendees.length; index++) {
			commit('setImportCount', index + 1)
			const attendee = registrationAttendees[index]

			// Add the attendee to the registration.
			await commit('addAttendee', attendee)

			// Get the attendee type.
			const firstTime    = parseTruthyValue(attendee.importData[await dispatch('getImportRow', 'First Time Student?')])
			const oneDayClass  = parseTruthyValue(attendee.importData[await dispatch('getImportRow', 'One Day Class?')])
			const fourDayClass = parseTruthyValue(attendee.importData[await dispatch('getImportRow', 'Four Day Class?')])
			const chaperone    = parseTruthyValue(attendee.importData[await dispatch('getImportRow', 'Chaperone?')])

			// Add the event to the registration.
			await commit('setImportStage', 'registration-event')
			const event        = rootGetters['events/get'](state.uploadedData.event.id)
			let attendeeType   = null,
				eventType      = null
			Object.keys(event.attendeeTypes).forEach(key => {
				if (attendeeType) {
					return
				}

				const at = event.attendeeTypes[key]
				let firstTimeCheck = false,
					classType      = 'four day'
				if (firstTime) {
					firstTimeCheck = at.name.toLowerCase().includes('first time')
				} else {
					firstTimeCheck = !at.name.toLowerCase().includes('first time')
				}

				if (oneDayClass) {
					classType = 'one day'
				}

				if (fourDayClass) {
					classType = 'four day'
				}

				if (chaperone) {
					classType = 'chaperone'
				}

				if (at.name.toLowerCase().includes(classType) && firstTimeCheck) {
					attendeeType = at.id
				}
			})

			Object.keys(event.eventTypes).forEach(key => {
				if (eventType) {
					return
				}

				eventType = key
			})

			await commit('addEvent', {
				uniqid        : this._vm.$uniqid(),
				name          : event.name,
				attendeeType  : attendeeType,
				eventType     : eventType,
				event         : state.uploadedData.event.id,
				startDate     : event.attendeeTypes[attendeeType].event_start_date || event.start_date,
				endDate       : event.attendeeTypes[attendeeType].event_end_date || event.end_date,
				allowDeposit  : event.attendeeTypes[attendeeType].allow_deposit,
				depositAmount : parseFloat(event.attendeeTypes[attendeeType].deposit_amount)
			})

			// Add the questions to the registration.
			await commit('setImportStage', 'registration-questions')
			await dispatch('questions/fetchAll', {
				event        : event.id,
				attendeeType : attendeeType
			}, { root: true })

			const questions = await dispatch('getAttendeeQuestions', attendee.importData)
			await commit('addQuestions', questions)

			// Add the financial options to the registration.
			await commit('setImportStage', 'registration-financial')
			await dispatch('financial/fetchAll', {
				event        : event.id,
				attendeeType : attendeeType
			}, { root: true })

			const financialOptions = await dispatch('getAttendeeFinancialOptions', {
				attendee : attendee.importData,
				attendeeType
			})
			await commit('addFinancialOptions', financialOptions)

			commit('setImportStage', 'registration')
		}

		const url = { name: 'register-summary', params: { key: state.registrationKey } }

		dispatch('save', { url: router.resolve(url).href })
			.then(() => {
				const promises = [
					dispatch('get', state.registrationKey),
					dispatch('attendees/fetchAll', null, { root: true })
				]

				Promise.all(promises)
					.then(() => router.push(url))
			})
	},
	// This is where we get the payload for the financial options attatched to the attendee.
	async getAttendeeFinancialOptions ({ rootGetters, dispatch }, { attendee, attendeeType }) {
		const financialOptions = {}
		const getGroupOptions  = groupId => {
			const options = rootGetters['financial/eventFinancialOptions']
				.filter(efo => efo.financial_option_group === groupId)
				.filter(efo => attendeeType === efo.attendee_type)
				.filter(efo => rootGetters['financial/financialOption'](efo.financial_option))
				.filter(efo => this._vm.$conditions(rootGetters['financial/financialOption'](efo.financial_option) || {}, financialOptions, [], true))
				.map(efo => rootGetters['financial/financialOption'](efo.financial_option)) || []
			return options
		}
		const getPrice = (option, price) => {
			return price || parseFloat(option.amount).toFixed(2)
		}
		rootGetters['financial/financialOptionGroups']
			.filter(group => 'none' === group.type)
			.forEach(group => {
				getGroupOptions(group.id).forEach(option => {
					// We only want to skip items with a checkbox here, if they are not selected.
					if ('checkbox' === option.type && !financialOptions[option.id]) {
						return
					}

					financialOptions[option.id] = {
						value    : option.id,
						group    : group.id,
						amount   : getPrice(option, option.amount),
						subtotal : getPrice(option, option.amount),
						price    : option.price ? getPrice(option, option.amount) : null,
						quantity : 1,
						label    : option.name
					}
				})
			})

		// Now that we've added the required options, let's add the ones that are optional. 🎉
		const oneDayClass  = parseTruthyValue(attendee[await dispatch('getImportRow', 'One Day Class?')])
		const fourDayClass = parseTruthyValue(attendee[await dispatch('getImportRow', 'Four Day Class?')])
		const chaperone    = parseTruthyValue(attendee[await dispatch('getImportRow', 'Chaperone?')])
		if (fourDayClass || chaperone || !oneDayClass) {
			// Camp and PCW only applies to 4 day students.
			const campValue = parseTruthyValue(attendee[await dispatch('getImportRow', 'Camp?')])
			const pcwValue  = chaperone ? false : parseTruthyValue(attendee[await dispatch('getImportRow', 'PCW?')])
			rootGetters['financial/financialOptionGroups']
				.filter(group => group.name.toLowerCase().includes('housing'))
				.forEach(group => {
					getGroupOptions(group.id).forEach(option => {
						const nameContains = campValue ? (pcwValue ? 'thursday' : 'wednesday') : 'commuter'
						if (!option.name.toLowerCase().includes(nameContains)) {
							return
						}

						financialOptions[option.id] = {
							value    : option.id,
							group    : group.id,
							amount   : getPrice(option, option.amount),
							subtotal : getPrice(option, option.amount),
							price    : option.price ? getPrice(option, option.amount) : null,
							quantity : 1,
							label    : option.name
						}
					})
				})

			rootGetters['financial/financialOptionGroups']
				.forEach(group => {
					getGroupOptions(group.id).forEach(option => {
						if (!pcwValue || !option.name.toLowerCase().includes('political communication workshop')) {
							return
						}

						financialOptions[option.id] = {
							value    : option.id,
							group    : group.id,
							amount   : getPrice(option, option.amount),
							subtotal : getPrice(option, option.amount),
							price    : option.price ? getPrice(option, option.amount) : null,
							quantity : 1,
							label    : option.name
						}
					})
				})
		}

		const getSubtotal = () => {
			let total      = 0

			Object.keys(financialOptions).forEach(id => {
				const item = financialOptions[id]
				if (item.price) {
					let amount = parseFloat(item.price.replace('$', ''))
					if (item.quantity) {
						amount = item.quantity * amount
					}
					total += amount
					return
				}

				const option = rootGetters['financial/financialOption'](id)
				let amount = parseFloat(option.amount)
				if (item.quantity) {
					amount = item.quantity * amount
				}
				total += amount
			})

			return total
		}

		return {
			selected : {
				...financialOptions
			},
			subtotal : getSubtotal(),
			uniqid   : this._vm.$uniqid()
		}
	},
	// This is where we get the payload for the questions attached to the attendee.
	async getAttendeeQuestions ({ rootState, dispatch }, attendee) {
		const questions      = {}
		const allQuestions   = [ ...rootState.questions.questions ]
		const formatQuestion = question => {
			return question.replace('the Attendee', '{{attendee_first_name}}')
		}
		const parseValue = (question, value) => {
			switch (question.type) {
				case 'textarea':
				case 'text':
					return value
				case 'select':
				case 'radio':
					switch (value.toLowerCase()) {
						case 'yes':
						case 'y':
						case '1':
						case 'true':
							return 'Yes'
						case 'no':
						case 'n':
						case '0':
						case 'false':
							return 'No'
						default:
							return value
					}
			}
		}
		for (let index = 0; index < Object.keys(attendee).length; index++) {
			const key      = Object.keys(attendee)[index]
			const header   = await dispatch('getImportHeader', key)
			const question = allQuestions.find(q => formatQuestion(q.label).toLowerCase() === formatQuestion(header).toLowerCase())
			if (!question) {
				continue
			}

			const value = parseValue(question, attendee[key])
			questions[question.id] = {
				id          : question.id,
				value,
				prettyValue : question.options && question.options.length ? (
					question.options.filter(option => option.value && option.value.toLowerCase() === value.toLowerCase()).length
						? question.options.filter(option => option.value && option.value.toLowerCase() === value.toLowerCase())[0].label
						: null
				) : null,
				label : question.label
			}
		}

		questions.uniqid = this._vm.$uniqid()

		return questions
	},
	// This is where we update existing attendees for our import process.
	updateImportedAttendee ({ dispatch }, attendee) {
		Object.keys(attendee.importData).forEach(async key => {
			const header     = await dispatch('getImportHeader', key)
			const importData = attendee.importData
			switch (header) {
				case 'Attendee Email': {
					const validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(importData[key])
					attendee.email   = validEmail ? importData[key] : ''
					break
				}
				case 'Attendee Phone': {
					const validPhone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(importData[key])
					attendee.phone   = validPhone ? importData[key] : ''
					break
				}
				case 'Street Address':
					attendee.address1 = importData[key]
					break
				case 'City':
					attendee.city = importData[key]
					break
				case 'State': {
					const value = importData[key].toLowerCase()
					const state = [ ...this._vm.$constants.US_STATES ]
						.find(s => s.label.toLowerCase() === value || s.value.toLowerCase() === value)
					attendee.state = state ? state.value : ''
					break
				}
				case 'Zip':
					attendee.zip = importData[key]
					break
				case 'Country': {
					const value   = importData[key].toLowerCase()
					const country = [ ...this._vm.$constants.COUNTRIES ]
						.find(c => c.label.toLowerCase() === value || c.value.toLowerCase() === value)
					attendee.country = country ? country.value : 'US'
					break
				}
				case 'Date of Birth':
					attendee.birthdate = this._vm.$moment(importData[key], 'MM/DD/YYYY').format('YYYY-MM-DD')
					break
				case 'Gender': {
					const value     = importData[key].toLowerCase()
					attendee.gender = value.startsWith('f') ? 'female' : 'male'
					break
				}
				default:
					// Dynamic Attendee fields.
					attendee.fields.all.forEach(af => {
						if (header.toLowerCase() === af.label.toLowerCase()) {
							let value = importData[key]
							switch (af.type) {
								case 'email': {
									const validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
									value            = validEmail ? value : ''
									break
								}
								case 'phone': {
									const validPhone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)
									value            = validPhone ? value : ''
									break
								}
								case 'select': {
									const option = af.options
										.find(o => o.value && (o.value.toLowerCase() === value.toLowerCase() || o.label.toLowerCase() === value.toLowerCase()))
									value = option ? option.value : value.toLowerCase()
								}
							}

							if (attendee.fields.values[af.id]) {
								attendee.fields.values[af.id] = value
							}
						}
					})
			}
		})

		return attendee
	},
	getImportHeader ({ state }, column) {
		if (!state.uploadedData.headers) {
			return null
		}

		let header = null
		Object.keys(state.uploadedData.headers).forEach(k => {
			if (column === k) {
				header = state.uploadedData.headers[k]
			}
		})

		return header
	},
	getImportRow ({ state }, header) {
		if (!state.uploadedData.headers) {
			return null
		}

		let row = null
		Object.keys(state.uploadedData.headers).forEach(k => {
			if (header === state.uploadedData.headers[k]) {
				row = k
			}
		})

		return row
	},
	parseFile ({ commit, state, dispatch }, { file, filename }) {
		return this._vm.$api({
			method : 'post',
			url    : `/register/${state.registrationKey}/upload/`,
			attach : {
				name : 'file',
				file,
				filename
			},
			then : response => {
				const url  = { name: 'register-group-map', params: { key: state.registrationKey } }
				commit('setRegistrationKey', response.body.registration.id)
				commit('setAccount', response.body.registration.account_id)
				commit('resetRegistration', response.body.registration.data || {})
				dispatch('save', { url: router.resolve(url).href })
					.then(() => router.push(url))
			}
		})
	},
	addAttendee ({ commit, state, rootState, dispatch }) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			const url  = { name: 'register-events', params: { key: state.registrationKey } }
			let action = 'attendees/add'

			if (rootState.attendees.current.id) {
				action = 'attendees/edit'
			}

			dispatch(action, false, { root: true })
				.then(attendee => {
					commit('addAttendee', attendee)
					dispatch('save', { url: router.resolve(url).href })
						.then(() => router.push(url))
						.then(() => resolve())
						.catch(error => reject(error))
				})
		})
	},
	updateAttendee ({ commit, state, dispatch }) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			const url = { name: 'register-summary', params: { key: state.registrationKey } }

			dispatch('attendees/edit', false, { root: true })
				.then(() => {
					dispatch('save', { url: router.resolve(url).href })
						.then(() => router.push(url))
						.then(() => resolve())
						.catch(error => reject(error))
				})
		})
	},
	removeAttendee ({ commit, dispatch, state }, attendeeId) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			const count = Object.keys(state.attendees).length
			const url   = { name: 'register-summary', params: { key: state.registrationKey } }
			if (1 >= count) {
				url.name = 'register-attendee'
			}

			commit('removeAttendee', attendeeId)
			commit('resetCurrent')
			dispatch('save', { url: router.resolve(url).href })
				.then(() => 1 === count && router.push(url))
				.then(() => resolve())
				.catch(error => reject(error))
		})
	},
	addEvent ({ commit, state, dispatch }, event) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			const url = { name: 'register-questions', params: { key: state.registrationKey } }

			// Add a unique ID.
			event.uniqid = this._vm.$uniqid()

			commit('addEvent', event)
			dispatch('save', { url: router.resolve(url).href })
				.then(() => router.push(url))
				.then(() => resolve())
				.then(() => commit('removePrivateEvent'))
				.catch(error => reject(error))
		})
	},
	auditEvents ({ commit, state }) {
		const { events } = state
		Object.keys(events).forEach(_uniqid => {
			const event = events[_uniqid]
			if (!event.questions || !event.financial) {
				commit('removeEvent', {
					attendeeId : event.attendee,
					eventId    : _uniqid
				})
			}
		})
	},
	removeEvent ({ commit, dispatch, state }, payload) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			const count  = state.attendees[payload.attendeeId].events.length
			const url    = { name: 'register-summary', params: { key: state.registrationKey } }
			let redirect = false

			if (1 >= count && payload.redirect) {
				commit('resetCurrent', { attendee: payload.attendeeId })
				url.name = 'register-events'
				redirect = true
			}

			commit('removeEvent', payload)
			dispatch('save', { url: router.resolve(url).href })
				.then(() => redirect && router.push(url))
				.then(() => resolve())
				.catch(error => reject(error))
		})
	},
	addQuestions ({ commit, state, dispatch }, payload) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			const url = { name: 'register-financial-options', params: { key: state.registrationKey } }

			// Add a unique ID.
			payload.uniqid = this._vm.$uniqid()

			commit('addQuestions', payload)
			dispatch('save', { url: router.resolve(url).href })
				.then(() => router.push(url))
				.then(() => resolve())
				.catch(error => reject(error))
		})
	},
	updateQuestions ({ commit, state, dispatch }, payload) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			const url = { name: 'register-summary', params: { key: state.registrationKey } }

			commit('addQuestions', payload)
			dispatch('save', { url: router.resolve(url).href })
				.then(() => router.push(url))
				.then(() => resolve())
				.catch(error => reject(error))
		})
	},
	addFinancialOptions ({ commit, state, dispatch, getters }, payload) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			// If we have travel options, redirect there, otherwise go to the summary.
			const name = getters.hasTravel ? 'register-travel-options' : 'register-summary'
			const url  = { name, params: { key: state.registrationKey } }

			// Add a unique ID.
			payload.uniqid = this._vm.$uniqid()

			commit('addFinancialOptions', payload)
			dispatch('save', { url: router.resolve(url).href })
				.then(() => {
					// If we have travel options, redirect there, otherwise go to the waitlist/summary.
					const name2 = getters.hasTravel ? 'register-travel-options' : (getters.needsWaitlist ? 'register-waitlist' : 'register-summary')
					const url2  = { name: name2, params: { key: state.registrationKey } }
					router.push(url2)
				})
				.then(() => resolve())
				.catch(error => reject(error))
		})
	},
	updateFinancialOptions ({ commit, state, dispatch }, payload) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			const url = { name: 'register-summary', params: { key: state.registrationKey } }

			commit('addFinancialOptions', payload)
			dispatch('save', { url: router.resolve(url).href })
				.then(() => router.push(url))
				.then(() => resolve())
				.catch(error => reject(error))
		})
	},
	addTravelOptions ({ commit, state, dispatch, getters }, payload) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			// If we have a waitlist, redirect there, otherwise go to the summary.
			const name = getters.needsWaitlist ? 'register-waitlist' : 'register-summary'
			const url  = { name, params: { key: state.registrationKey } }

			// Add a unique ID.
			payload.uniqid = this._vm.$uniqid()

			commit('addTravelOptions', payload)
			dispatch('save', { url: router.resolve(url).href })
				.then(() => router.push(url))
				.then(() => resolve())
				.catch(error => reject(error))
		})
	},
	updateBillingAddress ({ commit, state, dispatch }) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			const url = { name: 'register-payment', params: { key: state.registrationKey } }

			dispatch('account/edit', false, { root: true })
				.then(() => {
					dispatch('save', { url: router.resolve(url).href })
						.then(() => router.push(url))
						.then(() => resolve())
						.catch(error => reject(error))
				})
		})
	},
	save ({ commit, state }, { url, status }) {
		const { registrationKey } = state
		return this._vm.$api({
			method : 'post',
			url    : `/register/${registrationKey}/`,
			send   : {
				...state,
				url,
				status
			},
			then : response => commit('setWaitlistStatus', response.body.registration.data.events)
		})
	},
	saveAndLogout ({ commit, dispatch }, url) {
		commit('loading', true, { root: true })
		return new Promise((resolve, reject) => {
			dispatch('save', { status: 'saved', url })
				.then(() => router.push({ name: 'logout' }))
				.then(() => resolve())
				.catch(error => reject(error))
		})
	},
	getSavedRegistration ({ commit, dispatch }, key) {
		// Since we normally get here from a registration page,
		// let's refresh account and attendees.
		dispatch('account/fetch', null, { root: true })
		dispatch('attendees/fetchAll', null, { root: true })

		return this._vm.$api({
			method : 'get',
			url    : `/registration/${key}/`,
			then   : response => commit('setSavedRegistration', response.body)
		})
	},
	updateSavedRegistration ({ commit }, payload) {
		commit('loading', true, { root: true })
		const { key } = payload

		delete payload.key
		return this._vm.$api({
			method : 'put',
			url    : `/registration/${key}/`,
			send   : payload,
			then   : response => {
				commit(
					'attendees/updateSavedRegistration',
					{ id: payload.id, registration: response.body },
					{ root: true }
				)
				commit('updateSavedRegistration', { id: payload.id, registration: response.body })
			},
			routerRedirect : () => ({ name: 'events-registration-edit', params: { key, id: payload.id } })
		})
	},
	search ({ commit, state }) {
		const { registrationKey } = state
		if (registrationKey) {
			return registrationKey
		}
		return this._vm.$api({
			method : 'get',
			url    : '/register/',
			then   : (response, resolve) => {
				commit('setRegistrationKey', response.body.registration.id)
				commit('setAccount', response.body.registration.account_id)

				if ('saved' === response.body.registration.status) {
					commit('resetRegistration', response.body.registration.data || {})

					if (response.body.registration.url) {
						return router.push(response.body.registration.url)
					}
				}

				if ('complete' === response.body.registration.status) {
					return router.push({ name: 'register-confirmation', params: { key: response.body.registration.id } })
				}

				resolve(response.body.registration.id)
			}
		})
	},
	get ({ commit }, key) {
		return this._vm.$api({
			method : 'get',
			url    : `/register/${key}/`,
			then   : response => {
				commit('setRegistrationKey', response.body.registration.id)
				commit('setAccount', response.body.registration.account_id)
				commit('resetRegistration', response.body.registration.data || {})

				if ('complete' === response.body.registration.status) {
					return router.push({ name: 'register-confirmation', params: { key: response.body.registration.id } })
				}
			}
		})
	},
	fetch ({ commit, dispatch, rootState, state, getters }, { key, location }) {
		return new Promise(resolve => {
			// Clean up for saved registrations.
			commit('setSavedRegistration', [])

			const promises        = []
			if (!state.registrationKey) {
				promises.push(dispatch('get', key))
			}
			if (!rootState.attendees.attendees.length) {
				promises.push(dispatch('attendees/fetchAll', null, { root: true }))
			}
			if (!rootState.events.events.length) {
				promises.push(dispatch('events/fetchAll', null, { root: true }))
			}
			if (!rootState.account.current.id) {
				promises.push(dispatch('account/fetch', null, { root: true }))
			}

			if (!rootState.payment.publishableKey) {
				promises.push(dispatch('payment/fetch', null, { root: true }))
			}

			return Promise.all(promises)
				.then(() => {
					if ('payment' === location) {
						const subPromises = []
						if (!rootState.donations.donations.length) {
							subPromises.push(dispatch('donations/fetchAll', null, { root: true }))
						}

						if (!rootState.discounts.discounts.length) {
							subPromises.push(dispatch('discounts/fetchAll', getters.getEventAttendeeTypes, { root: true })
								.then(() => dispatch('autoApplyDiscounts')))
						} else {
							subPromises.push(dispatch('autoApplyDiscounts'))
						}

						return Promise.all(subPromises)
					}
					if (state.current.event) {
						if (
							'questions' === location &&
							(
								!rootState.questions.questions.length ||
								state.events[state.current.event].id !== rootState.questions.currentEvent ||
								state.current.attendeeType !== rootState.questions.currentAttendeeType
							)
						) {
							return dispatch('questions/fetchAll', {
								event        : state.events[state.current.event].id,
								attendeeType : state.current.attendeeType
							}, { root: true })
						}
						if (
							'financial-options' === location &&
							(
								!rootState.financial.financial.length ||
								state.events[state.current.event].id !== rootState.financial.currentEvent ||
								state.current.attendeeType !== rootState.financial.currentAttendeeType
							)
						) {
							return dispatch('financial/fetchAll', {
								event        : state.events[state.current.event].id,
								attendeeType : state.current.attendeeType
							}, { root: true })
						}
						if (
							'travel-options' === location &&
							(
								!rootState.travel.travel.length ||
								state.events[state.current.event].id !== rootState.travel.currentEvent ||
								state.current.attendeeType !== rootState.travel.currentAttendeeType
							)
						) {
							return dispatch('travel/fetchAll', {
								event        : state.events[state.current.event].id,
								attendeeType : state.current.attendeeType
							}, { root: true })
						}
					} else if (
						'questions' === location ||
						'financial-options' === location ||
						'travel-options' === location
					) {
						router.push({ name: 'register-summary', params: { key: state.registrationKey } })
					}
				})
				.then(() => resolve())
		})
	},
	cancel ({ commit }, key) {
		commit('loading', true, { root: true })

		return this._vm.$api({
			method : 'delete',
			url    : `/register/${key}/`,
			then   : () => {
				commit('account/cancelSaved', key, { root: true })
				commit('cancel')
				commit('resetCurrent')
			}
		})
	},
	autoApplyDiscounts ({ commit, state, rootGetters }) {
		const { events, financial } = state
		const start                 = {}

		Object.keys(events).forEach(eventId => {
			let existingDiscounts = false
			const event           = events[eventId]
			const discounts       = rootGetters['discounts/getAutoApply']
				.filter(discount => this._vm.$discount.validateAttendeeType(discount, event))
				.filter(discount => this._vm.$discount.validateEvent(discount, event))
				.filter(discount => this._vm.$discount.validateDates(discount, event))
				.filter(discount => this._vm.$discount.validateFinancialOptions(discount, event.financial, financial, start))
				.filter(discount => this._vm.$discount.validateRedemptions(discount))

			if (discounts.length) {
				// First look to see if the currently selected event already has discounts.
				Object.keys(state.discounts).forEach(_uniqid => {
					const d = state.discounts[_uniqid]
					if (d.event === eventId && d.attendee === event.attendee) {
						existingDiscounts = _uniqid
					}
				})

				if (existingDiscounts) {
					// If this discount is already set, don't set it again.
					if (!discounts.filter(d => -1 === state.discounts[existingDiscounts].discounts.findIndex(dis => dis.id === d.id)).length) {
						return
					}
				}

				commit('addDiscounts', {
					discounts,
					event    : eventId,
					attendee : event.attendee,
					uniqid   : this._vm.$uniqid()
				})
			}
		})
	},
	applyDiscountCode ({ commit, state, rootGetters, dispatch, getters }, discountCode) {
		return new Promise((resolve, reject) => {
			const { events, financial, discounts } = state

			const applyTo            = {}
			const start              = {}
			const hashedDiscountCode = sha1(discountCode.toLowerCase())

			let error = null

			const index = rootGetters['discounts/get']
				.findIndex(discount => discount.code === hashedDiscountCode)

			// If no discount code was found, reject with an error.
			if (-1 === index) {
				return reject(new Error('Please provide a valid discount code.'))
			}

			// If this discount code has already been applied, reject with an error.
			Object.keys(discounts).forEach(_uniqid => {
				discounts[_uniqid].discounts.forEach(discount => {
					if (discount.code === hashedDiscountCode) {
						error = new Error(`The ${discount.name} has already been applied.`)
					}
				})
			})

			if (error) {
				return reject(error)
			}

			// Validate the discount.
			const discount = rootGetters['discounts/get'][index]
			commit('discounts/addEnteredCode', {
				discountCode,
				index
			}, { root: true })

			Object.keys(events).forEach(eventId => {
				const event = events[eventId]

				// Skip any waitlisted events.
				if (event.waitlist && !event.waitlistOverride) {
					return
				}

				const eventDiscount = [ { ...discount } ]
					.filter(d => this._vm.$discount.validateAttendeeType(d, event))
					.filter(d => this._vm.$discount.validateEvent(d, event))
					.filter(d => this._vm.$discount.validateDates(d, event))
					.filter(d => this._vm.$discount.validateFinancialOptions(d, event.financial, financial, start))
					.filter(d => this._vm.$discount.validateRedemptions(d))

				if (eventDiscount.length) {
					applyTo[eventId] = eventDiscount
				}
			})

			if (!Object.keys(applyTo).length) {
				return reject(new Error(`The ${discount.name} cannot be applied to this registration.`))
			}

			Object.keys(applyTo).forEach(eventId => {
				const discounts = applyTo[eventId]

				if (discounts.length) {
					commit('addDiscounts', {
						discounts,
						event    : eventId,
						attendee : events[eventId].attendee,
						uniqid   : this._vm.$uniqid()
					})
				}
			})

			dispatch('save', {})
				.then(() => {
					if (getters.needsWaitlist) {
						return router.push({ name: 'register-waitlist', params: { key: state.registrationKey }, query: { redirect: 'register-payment' } })
					}

					resolve(discount)
				})
		})
	},
	removeDiscountCode ({ commit, dispatch }, id) {
		commit('removeDiscountCode', id)
		return dispatch('save', {})
	},
	submitRegistration ({ commit, state, rootState }, billing) {
		const payload = { ...state }

		// Set the billing address on the state.
		rootState.payment.paymentData.billing = billing

		// Set the payment on the payload.
		payload.payment   = rootState.payment.paymentData
		payload.amounts   = rootState.payment.paymentAmounts
		payload.donations = rootState.payment.donations
		payload.adminUser = rootState.isAdmin ? this._vm.$storage.cookies.get('_re2_admin_id') : null

		return this._vm.$api({
			method : 'post',
			url    : `/register/${state.registrationKey}/submit/`,
			send   : payload,
			then   : () => {
				commit('cancel')
				commit('resetCurrent')
				router.push({ name: 'register-confirmation' })
			}
		})
	},
	overrideWaitlist ({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			commit('overrideWaitlist')
			const url = { name: 'register-summary', params: { key: state.registrationKey } }
			dispatch('save', { url: router.resolve(url).href })
				.then(() => resolve())
				.catch(error => reject(error))
		})
	}
}