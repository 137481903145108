import { discount as dAmount } from '@/utils/amounts'
import eventDates from '@/utils/eventDates'

export default {
	hasAttendees : state => state.attendees && Object.keys(state.attendees).length,
	allAttendees : state => state.attendees,
	currentEvent : state => state.current.event ? state.events[state.current.event] : {},
	getQuestions : state => {
		const { questions } = state
		let qs = {}
		Object.keys(questions).forEach(id => {
			const q = questions[id]
			if (q.event === state.current.event) {
				qs = q.questions
			}
		})
		return qs
	},
	getFinancial : state => {
		const { financial } = state
		let fos = {}
		Object.keys(financial).forEach(id => {
			const fo = financial[id]
			if (fo.event === state.current.event) {
				fos = fo.options
			}
		})
		return fos
	},
	getTravel : state => {
		const { travel } = state
		let tos = {
			arriving  : {},
			departing : {}
		}
		Object.keys(travel).forEach(id => {
			const to = travel[id]
			if (to.event === state.current.event) {
				tos = to
			}
		})
		return tos
	},
	hasTravel : (state, getters, rootState) => {
		if (!state.current.event || !state.events[state.current.event]) {
			return false
		}

		const event = rootState.events.events.filter(event => event.id === state.events[state.current.event].id)[0]
		return event ? event.travel_options : false
	},
	needsWaitlist : (state) => {
		if (!state.current.event || !state.events[state.current.event]) {
			return false
		}

		const event = state.events[state.current.event]
		return event.waitlist || false
	},
	hasTravelById : (state, getters, rootState) => id => {
		const event = rootState.events.events.filter(event => event.id === id)[0]
		return event ? event.travel_options : false
	},
	getAttendeeEvents : state => events => events.map(eventId => {
		const event = { ...state.events[eventId] }

		// Set the uniqid here in case we need it. (Hint: we do).
		event.uniqid = eventId

		return event
	}),
	getEventQuestions         : state => questionId => state.questions[questionId] ? { ...state.questions[questionId].questions } : {},
	getEventFinancial         : state => financialId => state.financial[financialId] ? { ...state.financial[financialId].options } : {},
	getEventSubtotalDiscounts : state => discountId => {
		return discountId && state.discounts[discountId] ? state.discounts[discountId].discounts
			.filter(discount => 'subtotal' === discount.apply_to) : []
	},
	getEventFinancialDiscounts : state => (discountId, financialOptionId) => {
		return discountId && state.discounts[discountId] ? state.discounts[discountId].discounts
			.filter(discount => 'individual_options' === discount.apply_to && -1 !== discount.financial_options.findIndex(fo => fo.id === financialOptionId)) : []
	},
	getEventFinancialSubtotal : (state, getters) => {
		let subtotal = 0
		Object.keys(state.financial).forEach(financialId => {
			subtotal += getters.getEventSubtotal(financialId)
		})

		return subtotal
	},
	getEventSubtotal : state => financialId => {
		let subtotal = 0
		const financial = state.financial[financialId]
		if (financial) {
			Object.keys(state.financial[financialId].options).forEach(optionId => {
				const financialOption = state.financial[financialId].options[optionId]
				if (financialOption) {
					subtotal += parseFloat(financialOption.subtotal)
				}
			})
		}

		return subtotal
	},
	getEventDiscountAmounts : (state, getters) => (discountId, financialId) => {
		let discountAmount = 0
		const financial = state.financial[financialId]
		if (financial) {
			Object.keys(state.financial[financialId].options).forEach(optionId => {
				const fo          = state.financial[financialId].options[optionId]
				const foDiscounts = getters.getEventFinancialDiscounts(discountId, optionId)
				if (foDiscounts.length) {
					let amount = parseFloat(fo.subtotal)
					foDiscounts.forEach(discount => {
						const amounts   = dAmount(discount, amount)
						discountAmount += amounts.discountAmount
						amount          = amounts.amount
					})
				}
			})
		}

		const subtotal = getters.getEventFinancialSubtotal
		if (subtotal <= discountAmount) {
			return subtotal
		}

		const subtotalDiscounts = getters.getEventSubtotalDiscounts(discountId)
		if (subtotalDiscounts.length) {
			let amount = subtotal - discountAmount
			subtotalDiscounts.forEach(discount => {
				const amounts            = dAmount(discount, amount)
				discountAmount          += amounts.discountAmount
				amount                   = amounts.amount
				discount.discount_amount = amounts.discountAmount
			})
		}

		return discountAmount
	},
	getEventSubtotalWithDiscounts : (state, getters) => (financialId, discountId) => {
		const subtotal = getters.getEventSubtotal(financialId) + getters.getEventDiscountAmounts(discountId, financialId)
		return 0 > subtotal ? 0 : subtotal
	},
	getEventTravel : state => travelId => {
		const travel = { ...state.travel[travelId] }

		// Delete unnecessary items
		delete travel.attendee
		delete travel.event

		return travel
	},
	getEventAttendeeTypes : state => Object.keys(state.events).map(uniqid => state.events[uniqid].attendeeType).filter((attendeeType, index, self) => self.indexOf(attendeeType) === index) || [],
	dueNowEventAmount     : (state, getters, rootState) => id => {
		let dueNow             = 0
		const { attendees }    = state
		const { registration } = rootState.settings
		Object.keys(attendees).forEach(attendeeId => {
			const attendee = attendees[attendeeId]
			if (attendee) {
				const events = getters.getAttendeeEvents(attendee.events)

				events.forEach(event => {
					if (event.uniqid === id) {
						if (eventDates.allowDeposit(event.allowDeposit, event.startDate, registration.depositDeadlineInteger, registration.depositDeadlineUnit)) {
							dueNow += event.depositAmount
						} else {
							dueNow += getters.getEventSubtotalWithDiscounts(event.financial, event.discounts)
						}
					}
				})
			}
		})
		return 0 > dueNow ? 0 : dueNow
	},
	dueNowAmount : (state, getters, rootState) => {
		let total              = 0
		const { attendees }    = state
		const { registration } = rootState.settings
		Object.keys(attendees).forEach(attendeeId => {
			const attendee = attendees[attendeeId]
			if (attendee) {
				const events = getters.getAttendeeEvents(attendee.events)

				events.forEach(event => {
					if (eventDates.allowDeposit(event.allowDeposit, event.startDate, registration.depositDeadlineInteger, registration.depositDeadlineUnit)) {
						total += event.depositAmount
					} else {
						total += getters.getEventSubtotalWithDiscounts(event.financial, event.discounts)
					}
				})
			}
		})

		// total -= getters.accountCredit @TODO: When not adding account credit in
		const due = total > getters.grandTotal ? getters.grandTotal : total
		return 0 > due ? 0 : due
	},
	depositAmounts : (state, getters, rootState) => {
		let total              = 0
		const { attendees }    = state
		const { registration } = rootState.settings
		Object.keys(attendees).forEach(attendeeId => {
			const attendee = attendees[attendeeId]
			if (attendee) {
				const events = getters.getAttendeeEvents(attendee.events)

				events.forEach(event => {
					if (eventDates.allowDeposit(event.allowDeposit, event.startDate, registration.depositDeadlineInteger, registration.depositDeadlineUnit)) {
						total += event.depositAmount
					}
				})
			}
		})
		return 0 > total ? 0 : total
	},
	subtotal : (state, getters) => {
		let total = 0
		const { attendees } = state
		Object.keys(attendees).forEach(attendeeId => {
			const attendee = attendees[attendeeId]
			if (attendee) {
				const events = getters.getAttendeeEvents(attendee.events)

				Object.keys(events).forEach(eventId => {
					const event = events[eventId]

					// Skip waitlisted events.
					if (event.waitlist && !event.waitlistOverride) {
						return
					}

					total += getters.getEventSubtotalWithDiscounts(event.financial, event.discounts)
				})
			}
		})
		return 0 > total ? 0 : total
	},
	accountCredit : (state, getters, rootState) => {
		let amount = rootState.account.current.amounts.account_credit
		if (getters.subtotal < amount) {
			amount = getters.subtotal
		}

		amount -= getters.depositAmounts

		return 0 > amount ? 0 : amount
	},
	grandTotal : (state, getters) => {
		const total = getters.subtotal - getters.accountCredit
		// const total = getters.subtotal @TODO: When not adding account credit in
		return 0 > total ? 0 : total
	}
}