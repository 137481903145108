import { format, unformat, setCursor, event, setMin, setMax } from './utils'
import assign from './assign'
import defaults from './options'

export default (el, binding) => {
	if (!binding.value) return
	const opt = assign(defaults, binding.value)

	// v-money used on a component that's not a input
	if ('INPUT' !== el.tagName.toLocaleUpperCase()) {
		const els = el.getElementsByTagName('input')
		if (1 !== els.length) {
			// throw new Error("v-money requires 1 input, found " + els.length)
		} else {
			el = els[0]
		}
	}

	el.onkeydown = (e) => {
		const backspacePressed = 8 === e.which || 46 === e.which
		const atEndPosition    = 0 === (el.value.length - el.selectionEnd)
		if (opt.allowBlank && backspacePressed && atEndPosition && (0 === unformat(el.value, 0))) {
			el.value = ''
			el.dispatchEvent(event('change')) // v-model.lazy
		}
	}

	el.oninput = () => {
		let positionFromEnd = el.value.length - el.selectionEnd
		el.value = format(el.value, opt)
		positionFromEnd = Math.max(positionFromEnd, opt.suffix.length) // right
		positionFromEnd = el.value.length - positionFromEnd
		positionFromEnd = Math.max(positionFromEnd, opt.prefix.length + 1) // left
		setCursor(el, positionFromEnd)
		el.dispatchEvent(event('change')) // v-model.lazy
	}

	el.onfocus = () => {
		setCursor(el, el.value.length - opt.suffix.length)
	}

	el.onblur = () => {
		if (opt.allowBlank && '' === el.value) {
			return
		}

		el.value = format(setMin(setMax(unformat(el.value, opt.precision), opt), opt), opt)
		el.dispatchEvent(event('change')) // v-model.lazy
	}

	el.oninput()
	el.dispatchEvent(event('input')) // force format after initialization
}