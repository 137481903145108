import { CREATE, CURRENT } from './defaults'
import * as m from '@/admin/store/commonMutations'

const {
	setPayload,
	setPagination,
	...mutations
} = m

export default {
	...mutations,
	resetNew (state) {
		setPayload(this._vm, state, 'newModel', { ...CREATE })
	},
	resetCurrent (state) {
		setPayload(this._vm, state, 'current', { ...CURRENT })
	},
	setPagination (state, payload) {
		setPagination(this._vm, state, payload)
	},
	setMediaFiles (state, files) {
		state.files = files
	},
	addMediaFiles (state, newFiles) {
		const { files } = state
		newFiles.forEach(file => files.unshift(file))
		this._vm.$set(state, 'files', files)
	},
	removeMediaFiles (state, fileIds) {
		this._vm.$set(state, 'files', state.files.filter(file => !fileIds.includes(file.id)))
	}
}