import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/financial-options',
		name      : 'admin-financial-options',
		component : loadView('financial-options/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Financial Options'
		}
	},
	{
		path      : '/financial-options/new',
		name      : 'admin-financial-option-new',
		component : loadView('financial-options/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Financial Option'
		}
	},
	{
		path      : '/financial-options/archives',
		name      : 'admin-financial-options-archives',
		component : loadView('financial-options/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ]
		}
	},
	{
		path      : '/financial-options/:id',
		name      : 'admin-financial-option-edit',
		component : loadView('financial-options/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Financial Option'
		}
	},
	{
		path      : '/financial-option-groups',
		name      : 'admin-financial-option-groups',
		component : loadView('financial-option-groups/List'),
		meta      : {
			middleware : [ Auth ],
			label      : 'Financial Option Groups'
		}
	},
	{
		path      : '/financial-option-groups/new',
		name      : 'admin-financial-option-group-new',
		component : loadView('financial-option-groups/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Financial Option Group'
		}
	},
	{
		path      : '/financial-option-groups/archives',
		name      : 'admin-financial-option-groups-archives',
		component : loadView('financial-option-groups/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ]
		}
	},
	{
		path      : '/financial-option-groups/:id',
		name      : 'admin-financial-option-group-edit',
		component : loadView('financial-option-groups/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Financial Option Group'
		}
	}
]