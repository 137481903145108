export default {
	name : state => {
		const { current } = state
		const firstName = current.first_name || ''
		const lastName  = current.last_name || ''
		const name      = firstName + ' ' + lastName
		return name.trim()
	},
	hasAdditionalFields : state => Object.keys(state.current.fields.values).length,
	savedRegistration   : state => state.current.savedRegistrations.length ? state.current.savedRegistrations[0] : {}
}