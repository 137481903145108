import Money from './component'
import VMoney from './directive'
import options from './options'
import MoneyFilter from './filter'

export {
	Money,
	VMoney,
	options
}

export default (Vue, globalOptions) => {
	if (globalOptions) {
		Object.keys(globalOptions).map(function (key) {
			options[key] = globalOptions[key]
		})
	}
	Vue.directive('money', VMoney)
	Vue.component('money', Money)
	Vue.filter('currency', MoneyFilter)
}