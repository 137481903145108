export default {
	setSearchResults (state, payload) {
		state.results = payload || {}
	},
	setSearching (state, payload) {
		state.searching = payload
	},
	setShowSearchPane (state, payload) {
		state.showSearchPane = payload
	},
	setSearchTerm (state, payload) {
		state.searchTerm = payload
	},
	setDebouncedSearchTerm (state, payload) {
		state.debouncedSearchTerm = payload
	}
}