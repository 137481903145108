import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import paths from './paths'
import store from '@/admin/store'

Vue.use(Router)

const router = new Router({
	mode   : 'history',
	base   : '/admin/', // In case we change sub-directories -> 'dev' === process.env.VUE_APP_ENV ? '/admin/' : '/events/admin/',
	routes : paths,
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		if (to.hash) {
			return { selector: to.hash }
		}
		return { x: 0, y: 0 }
	}
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
const nextFactory = (context, middleware, index) => {
	const subsequentMiddleware = middleware[index]
	// If no subsequent Middleware exists,
	// the default `next()` callback is returned.
	if (!subsequentMiddleware) {
		return context.next
	}

	return (...parameters) => {
		// Run the default Vue Router `next()` callback first.
		context.next(...parameters)
		// Then run the subsequent Middleware with a new
		// `nextMiddleware()` callback.
		const nextMiddleware = nextFactory(context, middleware, index + 1)
		subsequentMiddleware({ ...context, next: nextMiddleware })
	}
}

router.beforeEach(async (to, from, next) => {
	// Set loading to true on each route change.
	store.commit('loading', true)

	// Make sure we load our auth token from the admin side.
	if (router.app && router.app.$auth.get(true)) {
		router.app.$http.set('Authorization', `Bearer ${router.app.$auth.get(true).replace(process.env.VUE_APP_ADMIN, '')}`)

		if (!store.state.adminLoaded) {
			await router.app.$http
				.get(`${router.app.$constants.BASE_API_URL}/admin/`)
				.then(response => {
					store.commit('setAdminUser', response.body)
				})
				.catch(error => {
					const message = error.response ? error.response.body.message : error
					throw new Error(message)
				})
		}
	}

	// If a route with a title was found, set the document (page) title to that value.
	document.title = to.meta.label ? to.meta.label + ' | ' + process.env.VUE_APP_PLATFORM_NAME : process.env.VUE_APP_PLATFORM_NAME

	if (to.meta.middleware) {
		const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [ to.meta.middleware ]

		const context = {
			from,
			next,
			router,
			to
		}
		const nextMiddleware = nextFactory(context, middleware, 1)

		return middleware[0]({ ...context, next: nextMiddleware })
	}

	return next()
})

Vue.use(Meta)

export default router