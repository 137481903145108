<template>
	<div
		v-if="current.amounts.account_credit"
		class="row"
	>
		<div class="col-md-12">
			<component
				:is="$store.state.isAdmin && $store.state.inAdmin ? 'core-card' : 'aside'"
				type="default"
				class="text-white"
			>
				Your account currently has a credit of <strong>{{ current.amounts.account_credit | currency }}.</strong><br />
				<span
					v-if="getTotals.dueNowAmount < current.amounts.account_credit"
				>
					<strong>{{ getTotals.dueNowAmount | currency }}</strong> of it has automatically been applied to your pending transaction.
				</span>
				<span
					v-if="getTotals.dueNowAmount >= current.amounts.account_credit"
				>
					This will automatically be applied to your pending transaction.
				</span>
			</component>
		</div>
	</div>
</template>

<script>
export default {
	props : {
		current : {
			type     : Object,
			required : true
		},
		getTotals : {
			type     : Object,
			required : true
		}
	}
}
</script>