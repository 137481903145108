import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/discounts',
		name      : 'admin-discounts',
		component : loadView('discounts/List'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Discounts'
		}
	},
	{
		path      : '/discounts/new',
		name      : 'admin-discount-new',
		component : loadView('discounts/New'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'New Discount'
		}
	},
	{
		path      : '/discounts/archives',
		name      : 'admin-discounts-archives',
		component : loadView('discounts/Archives'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Discount Archives'
		}
	},
	{
		path      : '/discounts/:id',
		name      : 'admin-discount-edit',
		component : loadView('discounts/Edit'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Edit Discount'
		}
	}
]