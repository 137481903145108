export default {
	get                        : state => id => state.events.filter(r => r.id === id)[0] || {},
	currentEvent               : state => id => state.registration.events.find(e => e.id === id),
	registrationEvents         : state => state.registration.events,
	registrationTypes          : state => state.registration.types,
	attendeeTypesWithQuestions : state => {
		const attendeeTypes = {}
		Object.keys(state.current.attendee_type_questions).forEach(typeId => {
			if (state.current.attendee_type_questions[typeId].length) {
				attendeeTypes[typeId] = state.current.attendee_type_questions[typeId]
			}
		})
		return attendeeTypes
	},
	attendeeTypesWithTravelOptions : state => {
		const attendeeTypes = {}
		Object.keys(state.current.attendee_type_travel_options).forEach(typeId => {
			if (state.current.attendee_type_travel_options[typeId].length) {
				attendeeTypes[typeId] = state.current.attendee_type_travel_options[typeId]
			}
		})
		return attendeeTypes
	},
	attendeeTypesWithFinancialOptions : state => {
		const attendeeTypes = {}
		Object.keys(state.current.attendee_type_financial_options).forEach(typeId => {
			if (state.current.attendee_type_financial_options[typeId].length) {
				attendeeTypes[typeId] = state.current.attendee_type_financial_options[typeId]
			}
		})
		return attendeeTypes
	}
}