import { CREATE, CURRENT } from './defaults'
import { state } from '@/admin/store/commonState'

export default {
	...state,
	newModel       : { ...CREATE },
	current        : { ...CURRENT },
	showReportPane : false,
	loading        : true,
	showFooter     : false,
	reportResults  : {}
}