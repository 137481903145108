export default {
	attendees : [],
	current   : {
		id         : null,
		first_name : null,
		last_name  : null,
		email      : null,
		phone      : null,
		address1   : null,
		address2   : null,
		city       : null,
		state      : null,
		zip        : null,
		country    : 'US',
		gender     : null,
		birthdate  : null,
		fields     : {
			all    : [],
			values : []
		},
		created : null,
		updated : null
	},
	fields : [],
	types  : []
}