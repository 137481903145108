import moment from 'moment'

const validateAttendeeType = (discount, event) => {
	return -1 !== discount.attendee_types.indexOf(event.attendeeType)
}

const validateEvent = (discount, event) => {
	return !discount.events.length || -1 !== discount.events.indexOf(event.id)
}

const validateDates = (discount, event) => {
	if ('none' === discount.effective_dates) {
		return true
	}

	if ('exact' === discount.effective_dates) {
		return validateExactDates(discount)
	}

	return validateEventDates(discount, event)
}

const validateExactDates = discount => {
	const now      = moment()
	const fromDate = discount.from_date ? moment(discount.from_date, 'YYYY-MM-DD HH:mm:ss') : null
	const toDate   = discount.to_date   ? moment(discount.to_date, 'YYYY-MM-DD HH:mm:ss')   : null

	if (fromDate && fromDate.isAfter(now)) {
		return false
	}

	if (toDate && toDate.isBefore(now)) {
		return false
	}

	return true
}

const validateEventDates = (discount, event) => {
	const now       = moment()
	const startDate = event.startDate ? moment(event.startDate, 'YYYY-MM-DD HH:mm:ss') : null
	const endDate   = event.endDate   ? moment(event.endDate, 'YYYY-MM-DD HH:mm:ss')   : null

	const endDateDiff = endDate.diff(now, discount.from_unit)
	if (discount.from_integer && 0 !== discount.from_integer && endDateDiff < discount.from_integer) {
		return false
	}

	const startDateDiff = startDate.diff(now, discount.from_unit)
	if (discount.to_integer && 0 !== discount.to_integer && startDateDiff < discount.to_integer) {
		return false
	}

	return true
}

const validateFinancialOptions = (discount, eventFinancialId, financialOptions, start) => {
	const eventFinancial = financialOptions[eventFinancialId]
	return 'individual_options' !== discount.apply_to || (eventFinancial && discount.financial_options
		.filter(financialOption => eventFinancial.options[financialOption.id])
		.filter(financialOption => {
			if (2 > financialOption.start) {
				return true
			}

			if (!start[discount.id]) {
				start[discount.id] = 1
				return false
			}

			start[discount.id] += 1

			if (start[discount.id] >= financialOption.start) {
				return true
			}

			return false
		}).length)
}

const validateRedemptions = (discount) => {
	if (null === discount.max_redemptions || 0 === discount.max_redemptions) {
		return true
	}

	if (discount.max_redemptions <= discount.redemptions) {
		return false
	}
	return true
}

export default {
	validateAttendeeType,
	validateDates,
	validateEvent,
	validateFinancialOptions,
	validateRedemptions
}