import * as a from '@/admin/store/commonActions'

const data = {
	route     : 'form-field/account',
	routeAll  : 'form-fields/account',
	routeSlug : 'account-form-field',
	label     : 'Account Form Field',
	class     : 'AccountFormField',
	slug      : 'accountFormField'
}

export default {
	fastFetchAll (context) {
		return a.fastFetchAll(this._vm, context, data)
	},
	fetchLogs (context, payload) {
		return a.fetchLogs(this._vm, context, data, payload)
	},
	fetchAll (context, payload) {
		return a.fetchAll(this._vm, context, data, payload)
	},
	fetchAllArchives (context, payload) {
		return a.fetchAllArchives(this._vm, context, data, payload)
	},
	fetch (context, payload) {
		return a.fetch(this._vm, context, data, payload)
	},
	createModel (context) {
		return a.createModel(this._vm, context, data)
			.then(() => context.commit('accounts/resetCurrent', null, { root: true }))
	},
	updateModel (context) {
		return a.updateModel(this._vm, context, data)
			.then(() => context.commit('accounts/resetCurrent', null, { root: true }))
	},
	duplicateModel (context, payload) {
		return a.duplicateModel(this._vm, context, data, payload)
			.then(() => context.commit('accounts/resetCurrent', null, { root: true }))
	},
	restoreModel (context, payload) {
		return a.restoreModel(this._vm, context, data, payload)
			.then(() => context.commit('accounts/resetCurrent', null, { root: true }))
	},
	archiveModel (context, payload) {
		return a.archiveModel(this._vm, context, data, payload)
			.then(() => context.commit('accounts/resetCurrent', null, { root: true }))
	},
	archiveModels (context, payload) {
		return a.archiveModels(this._vm, context, data, payload)
			.then(() => context.commit('accounts/resetCurrent', null, { root: true }))
	},
	restoreModels (context, payload) {
		return a.restoreModels(this._vm, context, data, payload)
			.then(() => context.commit('accounts/resetCurrent', null, { root: true }))
	},
	deleteModel (context, payload) {
		return a.deleteModel(this._vm, context, data, payload)
			.then(() => context.commit('accounts/resetCurrent', null, { root: true }))
	}
}