export default {
	setSettings (state, payload) {
		Object.keys(payload).forEach(key => {
			const settings = payload[key]
			Object.keys(settings).forEach(k => {
				const _k = this._vm.$lo.camelCase(k)
				this._vm.$set(state[key], _k, payload[key][k])
			})
		})
	},
	settingsLoaded (state, payload) {
		state.settingsLoaded = payload
	}
}