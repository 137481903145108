import {
	Auth,
	Allowed
} from '@/admin/middleware'

import { loadView } from './load'

export default [
	{
		path      : '/calendar',
		name      : 'admin-calendar',
		component : loadView('calendar/Calendar'),
		meta      : {
			middleware : [ Auth, Allowed ],
			label      : 'Calendar'
		}
	}
]