import * as a from '@/admin/store/commonActions'

const data = {
	route     : 'account',
	routeAll  : 'accounts',
	routeSlug : 'account',
	label     : 'Account',
	class     : 'Account',
	slug      : 'account'
}

export default {
	fetchLogs (context, payload) {
		return a.fetchLogs(this._vm, context, data, payload)
	},
	fetchAll (context, payload) {
		return a.fetchAll(this._vm, context, data, payload)
	},
	fetch (context, payload) {
		return a.fetch(this._vm, context, data, payload)
	},
	fetchEmails ({ commit }, accountId) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/account/${accountId}/emails/`,
			then   : response => commit('setEmails', response.body)
		})
	},
	fetchDonations ({ commit }, accountId) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/account/${accountId}/donations/`,
			then   : response => commit('setDonations', response.body)
		})
	},
	search ({ commit }, searchTerm) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/account/search/?term=${searchTerm}`,
			then   : response => commit('setAccountSearch', response.body)
		})
	},
	fastFetchAllStateCoordinators ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/admin/accounts/state-coordinators/',
			then   : response => commit('setAllStateCoordinators', response.body)
		})
	},
	fetchFormFields ({ commit }) {
		return this._vm.$api({
			method : 'get',
			url    : '/admin/account/form-fields/',
			then   : response => commit('setFormFields', response.body)
		})
	},
	fetchAttendees ({ commit }, accountId) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/account/${accountId}/attendees/`,
			then   : response => commit('setCurrentAttendees', response.body)
		})
	},
	fetchRegistrations ({ commit }, accountId) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/account/${accountId}/registrations/`,
			then   : response => commit('setCurrentRegistrations', response.body)
		})
	},
	fetchCredit ({ commit }, accountId) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/account/${accountId}/credit/`,
			then   : response => commit('setCurrentCredit', response.body)
		})
	},
	addCredit ({ commit, dispatch }, payload) {
		return this._vm.$api({
			method : 'post',
			url    : `/admin/account/${payload.accountId}/credit/`,
			send   : { ...payload.edit },
			then   : response => {
				commit('addCurrentCredit', response.body)
				dispatch('fetchLogs', payload.accountId)
			},
			notification : {
				type    : 'success',
				message : 'Account Credit successfully added!',
				icon    : 'fas fa-bell'
			}
		})
	},
	editCredit ({ commit, dispatch }, payload) {
		return this._vm.$api({
			method : 'put',
			url    : `/admin/account/${payload.accountId}/credit/`,
			send   : { ...payload.edit },
			then   : response => {
				commit('editCurrentCredit', response.body)
				dispatch('fetchLogs', payload.accountId)
			},
			notification : {
				type    : 'success',
				message : 'Account Credit successfully updated!',
				icon    : 'fas fa-bell'
			}
		})
	},
	removeCredit ({ commit, dispatch }, { accountId, selectedCredit }) {
		return this._vm.$api({
			method : 'delete',
			url    : `/admin/account/${accountId}/credit/`,
			send   : selectedCredit,
			then   : () => {
				commit('removeCurrentCredit', selectedCredit)
				dispatch('fetchLogs', accountId)
			},
			notification : {
				type    : 'success',
				message : 'Account Credit successfully deleted!',
				icon    : 'fas fa-bell'
			}
		})
	},
	fetchCreditCards ({ commit }, accountId) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/account/${accountId}/credit-cards/`,
			then   : response => commit('setCurrentCreditCards', response.body)
		})
	},
	removeCreditCard ({ commit, dispatch }, { accountId, creditCardId }) {
		return this._vm.$api({
			method : 'delete',
			url    : `/admin/account/${accountId}/credit-cards/${creditCardId}/`,
			then   : () => {
				commit('removeCreditCard', creditCardId)
				dispatch('fetchLogs', accountId)
			},
			notification : {
				type    : 'success',
				message : 'Credit Card successfully deleted!',
				icon    : 'fas fa-bell'
			}
		})
	},
	getUserToken ({ rootState }, { accountId, url }) {
		return this._vm.$api({
			method : 'get',
			url    : `/admin/user/${accountId}/`,
			then   : response => {
				this._vm.$auth.set(response.body.token)
				this._vm.$storage.cookies.set('_re2_admin', `/admin${rootState.route.fullPath}`)
				this._vm.$storage.cookies.set('_re2_admin_id', rootState.adminUser.id)
				window.location = this._vm.$url.redirect(`/${url}`)
			}
		})
	},
	createModel (context) {
		return a.createModel(this._vm, context, data)
	},
	updateModel (context) {
		return a.updateModel(this._vm, context, data)
			.then(response => {
				if (context.state.current.id === context.rootState.adminUser.id) {
					context.commit('setAdminUser', response.body, { root: true })
				}
			})
	},
	updateCurrentAdditional ({ commit, state }, payload) {
		return new Promise(resolve => {
			const { current } = state
			current.fields.values = {
				...current.fields.values,
				...payload
			}
			commit('setCurrent', {
				...current
			})

			resolve()
		})
	},
	updateNewAccountAdditional ({ commit, state }, payload) {
		return new Promise(resolve => {
			const { newModel } = state
			newModel.fields.values = {
				...newModel.fields.values,
				...payload
			}
			commit('setNewModel', {
				...newModel
			})

			resolve()
		})
	},
	sendPasswordReset ({ state, rootState, dispatch }) {
		return this._vm.$api({
			method : 'post',
			url    : '/account/password-reset/',
			send   : {
				email     : state.current.email,
				adminUser : rootState.adminUser.id
			},
			then         : () => dispatch('fetchLogs', state.current.id),
			notification : {
				type    : 'success',
				message : 'Password Reset email successfully sent!',
				icon    : 'fas fa-bell'
			}
		})
	},
	sendBalanceDue ({ state, rootState, dispatch }) {
		return this._vm.$api({
			method : 'post',
			url    : `/admin/account/${state.current.id}/email/balance-due`,
			send   : {
				email     : state.current.email,
				adminUser : rootState.adminUser.id
			},
			then         : () => dispatch('fetchLogs', state.current.id),
			notification : {
				type    : 'success',
				message : 'Balance Due email successfully sent!',
				icon    : 'fas fa-bell'
			}
		})
	},
	restrictAccount ({ dispatch, commit }) {
		commit('toggleRestricted', true)
		return dispatch('updateModel')
	},
	unRestrictAccount ({ dispatch, commit }) {
		commit('toggleRestricted', false)
		return dispatch('updateModel')
	}
}