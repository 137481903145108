import { CREATE, CURRENT } from './defaults'
import * as m from '@/admin/store/commonMutations'

const {
	setPayload,
	setPagination,
	setCurrent,
	...mutations
} = m

delete mutations.setAllModels

export default {
	...mutations,
	resetNew (state) {
		setPayload(this._vm, state, 'newModel', { ...CREATE })
	},
	resetCurrent (state) {
		setPayload(this._vm, state, 'current', { ...CURRENT })
	},
	setPagination (state, payload) {
		setPagination(this._vm, state, payload)
	},
	addAll (state, payload) {
		state.financial   = payload.financial   || []
		state.event       = payload.event       || []
		state.eventGroups = payload.eventGroups || []
		state.groups      = payload.groups      || []
	},
	setCurrent (state, payload) {
		if (payload.amount) {
			payload.amount = this._vm.$options.filters.currency(payload.amount)
		}
		if (!payload.description) {
			payload.description = ''
		}

		setCurrent(state, payload)
	},
	setAllModels (state, allModels) {
		state.allModels                = allModels.options
		state.allFinancialOptionGroups = allModels.groups
	}
}