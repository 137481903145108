export default {
	login (context, payload) {
		return this._vm.$api({
			method : 'post',
			send   : payload,
			url    : '/admin/login/',
			then   : response => {
				this._vm.$auth.set(response.body.token, payload.remember)
				this._vm.$http.set('Authorization', `Bearer ${response.body.token}`)
			},
			routerRedirect : () => ({ name: 'home' })
		})
	}
}